import React from 'react'
import { Box, BoxProps } from '@walltowall/calico'
import { useStyles } from 'react-treat'

import { useSiteSettings } from '../hooks/useSiteSettings'
import { PageTemplateEnhancerProps } from '../templates/page'

import { StyledHTMLContent } from '../components/StyledHTMLContent'
import { BoundedBox } from '../components/BoundedBox'
import { Divider } from '../components/Divider'
import { Heading } from '../components/Heading'
import { Text } from '../components/Text'
import {
  MobileTableCell,
  MobileTableHeading,
  DesktopTableHeading,
  DesktopTableCell,
} from './PageLayoutDepositRateSchedule'

import * as styleRefs from './PageLayoutDepositRateSchedule.treat'

export type PageLayoutCoreTableProps = PageTemplateEnhancerProps

const HTML = `<h3>Important Dates</h3>`
const ITEMS = [
  {
    label: 'Branches',
    july7:
      '<p>Open<br /><span class="small"><em>Mon – Thurs Hours</em><span></p>',
    july8: '<p>Closed</p>',
    july9: '<p>Closed</p>',
    july10: '<p>Open</p>',
    july11: '<p>Open</p>',
  },
  {
    label: 'Online Banking',
    july7: '<p>Unavailable from 10am</p>',
    july8: '<p>Unavailable</p>',
    july9: '<p>Unavailable</p>',
    july10: '<p>Available from 7am</p>',
    july11: '<p>Available</p>',
  },
  {
    label: 'Mobile Banking',
    july7: '<p>Existing app unavailable from 10am</p>',
    july8: '<p>Unavailable</p>',
    july9: '<p>Unavailable</p>',
    july10:
      '<p>New mobile app available <br /><br /><span class="small"><em>(Search "Hawaii National Bank")</em><span></p>',
    july11: '<p>Available</p>',
  },
  {
    label: 'ATMs',
    july7: '<p>Unavailable</p>',
    july8: '<p>Unavailable</p>',
    july9: '<p>Unavailable</p>',
    july10: '<p>Unavailable</p>',
    july11:
      '<p>Oahu ATMs Available <span class="small"><br /><br /><em>(no ATM deposits)</em><span></p>',
  },
  {
    label: 'Credit & Debit Cards',
    july7: '<p>Available with offline limits</p>',
    july8: '<p>Available with offline limits</p>',
    july9: '<p>Available with offline limits</p>',
    july10: '<p>Available with offline limits</p>',
    july11: '<p>Available</p>',
  },
  {
    label: 'Digital Banking Support',
    july7: '<p>Business hours 8am-5pm</p>',
    july8: '<p>Closed</p>',
    july9: '<p>Closed</p>',
    july10: '<p>Business hours 8am-5pm</p>',
    july11: '<p>Business hours 8am-5pm</p>',
  },
]

const Mobile = (props: BoxProps) => {
  const styles = useStyles(styleRefs)

  return (
    <Box {...props} styles={{ position: 'relative', ...props.styles }}>
      <Text
        component="p"
        variant="sans-12"
        styles={{ fontWeight: 'semibold', color: 'fuschia30', marginBottom: 4 }}
      >
        Swipe to see more dates
      </Text>
      <Box
        styles={{
          display: 'flex',
          flexDirection: 'column',
          overflow: 'auto',
          scrollSnapType: 'xMandatory',
          marginBottom: -6,
          position: 'relative',
        }}
      >
        <Box styles={{ display: 'flex' }}>
          <MobileTableHeading />
          <MobileTableHeading>July 7</MobileTableHeading>
          <MobileTableHeading>July 8</MobileTableHeading>
          <MobileTableHeading>July 9</MobileTableHeading>
          <MobileTableHeading>July 10</MobileTableHeading>
          <MobileTableHeading>July 11</MobileTableHeading>
        </Box>

        {ITEMS.map((item) => (
          <React.Fragment key={item.label}>
            <Divider
              color="beige80"
              styles={{
                height: '2px',
                marginBottom: 4,
                position: 'sticky',
                left: 0,
                zIndex: 1,
              }}
            />

            <Heading
              level={6}
              component="span"
              variant="serifCapsA"
              styles={{
                display: 'block',
                color: 'fuschia30',
                marginBottom: 4,
                position: 'sticky',
                left: 0,
                zIndex: 1,
              }}
            >
              {item.label}
            </Heading>

            <Box styles={{ marginBottom: 6 }}>
              <Box styles={{ display: 'flex' }}>
                <MobileTableCell />
                <MobileTableCell childrenHTML={item.july7} />
                <MobileTableCell childrenHTML={item.july8} />
                <MobileTableCell childrenHTML={item.july9} />
                <MobileTableCell childrenHTML={item.july10} />
                <MobileTableCell childrenHTML={item.july11} />
                <MobileTableCell />
              </Box>
            </Box>
          </React.Fragment>
        ))}
      </Box>

      <Box
        className={styles.mobileGradientOverlay}
        styles={{
          position: 'absolute',
          top: 0,
          right: 0,
          bottom: 0,
          width: '4/12',
          pointerEvents: 'none',
        }}
      />
    </Box>
  )
}

const Desktop = (props: BoxProps) => {
  return (
    <Box
      component="table"
      {...props}
      styles={{
        borderSpacing: '2px',
        borderCollapse: 'separate',
        ...props.styles,
      }}
    >
      <Box component="thead">
                <tr>

        <DesktopTableHeading>
          <br />
          <br />
        </DesktopTableHeading>
        <DesktopTableHeading>July 7</DesktopTableHeading>
        <DesktopTableHeading>July 8</DesktopTableHeading>
        <DesktopTableHeading>July 9</DesktopTableHeading>
        <DesktopTableHeading>July 10</DesktopTableHeading>
        <DesktopTableHeading>July 11</DesktopTableHeading>
                </tr>
      </Box>
      <Box
        component="tbody"
        // @ts-expect-error - valign is not a valid BoxProps prop
        valign="top"
        styles={{ color: 'brown20' }}
      >
        {ITEMS.map((item) => (
          <Box key={item.label} component="tr">
            <DesktopTableCell
              width="20%"
              // @ts-expect-error - align is not a valid BoxProps prop
              align="left"
            >
              <Heading
                level={6}
                component="span"
                variant="serifCapsA"
                styles={{ display: 'block', color: 'fuschia30' }}
              >
                {item.label}
              </Heading>
            </DesktopTableCell>
            <DesktopTableCell
              width="16%"
              variant="sans-16"
              childrenHTML={item.july7}
            />
            <DesktopTableCell
              width="16%"
              variant="sans-16"
              childrenHTML={item.july8}
            />
            <DesktopTableCell
              width="16%"
              variant="sans-16"
              childrenHTML={item.july9}
            />
            <DesktopTableCell
              width="16%"
              variant="sans-16"
              childrenHTML={item.july10}
            />
            <DesktopTableCell
              width="16%"
              variant="sans-16"
              childrenHTML={item.july11}
            />
          </Box>
        ))}
      </Box>
    </Box>
  )
}

export const PageLayoutCoreTable = ({
  nextSharesBg,
  id,
}: PageLayoutCoreTableProps) => {
  const settings = useSiteSettings()

  return (
    <BoundedBox
      id={id}
      component="section"
      innerMaxWidth="medium"
      nextSharesBg={nextSharesBg}
      styles={{
        backgroundColor: 'white',
        maxWidth: 'xlarge',
        marginRight: 'auto',
        marginLeft: 'auto',
        overflow: 'hidden',
      }}
    >
      <StyledHTMLContent html={HTML} styles={{ marginBottom: [6, 7, 8] }} />
      <Mobile styles={{ display: ['block', 'none'], paddingBottom: 2 }} />
      <Desktop styles={{ display: ['none', 'table'] }} />
    </BoundedBox>
  )
}

export const mapDataToContext = () => ({
  bg: 'white',
})

export default PageLayoutCoreTable
