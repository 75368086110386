import * as React from 'react'
import { graphql } from 'gatsby'
import GatsbyImage from 'gatsby-image/withIEPolyfill'
import { Box } from '@walltowall/calico'
import { getRichText } from '@walltowall/helpers'

import { PageLayoutButtonCardsWithImageFragment } from '../graphqlTypes'
import { MapDataToPropsArgs } from '../types'
import { PageTemplateEnhancerProps } from '../templates/page'

import { ButtonLink } from '../components/ButtonLink'
import { HTMLContent } from '../components/HTMLContent'
import { BoundedBox } from '../components/BoundedBox'

import { useModal } from '../hooks/useModal'
import { FluidObject } from 'gatsby-image'
import { GatsbyImageContainer } from '../components/GatsbyImageContainer'

export type PageLayoutButtonCardsWithImageProps = ReturnType<
  typeof mapDataToProps
> &
  PageTemplateEnhancerProps

export const PageLayoutButtonCardsWithImage = ({
  children,
  nextSharesBg,
  textHTML,
  id,
}: PageLayoutButtonCardsWithImageProps) => (
  <BoundedBox
    id={id}
    component="section"
    nextSharesBg={nextSharesBg}
    styles={{
      backgroundColor: 'white',
      maxWidth: 'xlarge',
      marginRight: 'auto',
      marginLeft: 'auto',
    }}
  >
    {textHTML && (
      <HTMLContent
        html={textHTML}
        styles={{
          textAlign: 'center',
          marginBottom: [16, 8, 8, 4],
          color: 'beige40',
        }}
      />
    )}
    <Box
      styles={{
        display: 'flex',
        flexDirection: ['column', 'row'],
        flexWrap: 'wrap',
        alignItems: 'end',
        marginTop: [-16, -6, 0],
      }}
    >
      {children}
    </Box>
  </BoundedBox>
)

export type PageLayoutButtonCardsCardProps = {
  buttonHref?: string
  buttonTarget?: string
  buttonText?: string
  imageFluid?: FluidObject
  imageAlt?: string
}

PageLayoutButtonCardsWithImage.Card = ({
  buttonHref,
  buttonTarget,
  buttonText = 'Learn more',
  imageFluid,
  imageAlt = '',
}: PageLayoutButtonCardsCardProps) => {
  const { openModal } = useModal()

  const isLostCardBtn = buttonHref?.startsWith('https://#reportModal')

  return (
    <Box
      styles={{
        display: 'flex',
        flexDirection: 'column',
        alignItems: 'center',
        width: ['full', '4/12'],
        paddingTop: [8, 0, 6],
        paddingBottom: [0, 0, 6],
        paddingLeft: [0, 4],
        paddingRight: [0, 4],
        marginTop: [8, 6, 0],
      }}
    >
      {imageFluid && (
        <GatsbyImageContainer
          styles={{ width: 'full', marginBottom: 6 }}
          style={{ maxHeight: '60px', maxWidth: '200px' }}
        >
          <GatsbyImage
            fluid={imageFluid}
            style={{ maxHeight: '60px', maxWidth: '200px' }}
            objectFit="contain"
            alt={imageAlt}
          />
        </GatsbyImageContainer>
      )}
      {buttonHref && (
        <ButtonLink
          variant="red"
          href={buttonHref}
          target={buttonTarget}
          linkProps={{ skipExternalCheck: isLostCardBtn }}
          onClick={() => {
            if (isLostCardBtn) {
              openModal('LOST_CARD')
            }
          }}
        >
          {buttonText}
        </ButtonLink>
      )}
    </Box>
  )
}

export const mapDataToProps = ({
  data,
}: MapDataToPropsArgs<PageLayoutButtonCardsWithImageFragment>) => ({
  textHTML: getRichText(data.primary?.text),
  children: data.items?.map((item, idx) => (
    <PageLayoutButtonCardsWithImage.Card
      key={idx}
      imageFluid={item?.image?.fluid as FluidObject}
      imageAlt={item?.image?.alt}
      buttonHref={item?.button_link?.url}
      buttonTarget={item?.button_link?.target}
      buttonText={item?.button_text}
    />
  )) as React.ReactNode,
})

export const mapDataToContext = () => ({
  bg: 'white',
})

export const query = graphql`
  fragment PageLayoutButtonCardsWithImage on PrismicPageLayoutButtonCardsWithImage {
    primary {
      text {
        text
        html
      }
    }
    items {
      image {
        alt
        fluid(maxWidth: 400) {
          ...GatsbyPrismicImageFluid
        }
      }
      button_link {
        url
        target
      }
      button_text
    }
  }
`

export default PageLayoutButtonCardsWithImage
