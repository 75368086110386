import * as React from 'react'
import { graphql } from 'gatsby'
import { useStyles } from 'react-treat'
import GatsbyImage from 'gatsby-image/withIEPolyfill'
import { FluidObject } from 'gatsby-image'
import { Box } from '@walltowall/calico'
import ConditionalWrap from 'conditional-wrap'
import { getRichText } from '@walltowall/helpers'

import { PageLayoutDestinationBoxesFragment } from '../graphqlTypes'
import { MapDataToPropsArgs } from '../types'
import { PageTemplateEnhancerProps } from '../templates/page'

import { BoundedBox } from '../components/BoundedBox'
import { HTMLContent } from '../components/HTMLContent'
import { Heading } from '../components/Heading'
import { Link } from '../components/Link'
import { Icon } from '../components/Icon'
import { GatsbyImageFaded } from '../components/GatsbyImageFaded'
import { GatsbyImageContainer } from '../components/GatsbyImageContainer'
import { ButtonLink } from '../components/ButtonLink'

import * as styleRefs from './PageLayoutDestinationBoxes.treat'

export type PageLayoutDestinationBoxesProps = Partial<
  ReturnType<typeof mapDataToProps>
> &
  PageTemplateEnhancerProps

export const PageLayoutDestinationBoxes = ({
  textHTML,
  children,
  nextSharesBg,
  id,
}: PageLayoutDestinationBoxesProps) => (
  <BoundedBox
    id={id}
    component="section"
    nextSharesBg={nextSharesBg}
    styles={{
      backgroundColor: 'white',
      color: 'brown20',
      marginRight: 'auto',
      marginLeft: 'auto',
      maxWidth: 'xlarge',
      paddingBottom: [0, 10, 16],
    }}
  >
    {textHTML && (
      <HTMLContent
        html={textHTML}
        componentOverrides={{
          h2: (Comp) => (props) => (
            <Comp
              level={2}
              variant="sansC"
              {...props}
              styles={{
                color: 'beige40',
                marginBottom: [5, 6],
              }}
            />
          ),
          p: (Comp) => (props) => <Comp variant="sans-18-20" {...props} />,
        }}
        styles={{
          marginBottom: [10, 14, 18],
          textAlign: 'center',
        }}
      />
    )}
    <Box
      styles={{
        display: 'flex',
        flexDirection: ['column', 'row'],
        marginRight: [0, -6, -8],
      }}
    >
      {children}
    </Box>
  </BoundedBox>
)

export type PageLayoutDestinationBoxesItemProps = {
  primaryImageFluid?: FluidObject
  primaryImageAlt?: string
  primaryTextHTML?: string
  primaryLinkHref?: string
  primaryLinkTarget?: string
  secondaryImageFluid?: FluidObject
  secondaryImageAlt?: string
  secondaryTextHTML?: string
  secondaryLinkHref?: string
  secondaryLinkTarget?: string
  secondaryButtonText?: string
}

PageLayoutDestinationBoxes.Section = ({
  primaryImageFluid,
  primaryImageAlt,
  primaryTextHTML,
  primaryLinkHref,
  primaryLinkTarget,
  secondaryImageFluid,
  secondaryImageAlt,
  secondaryTextHTML,
  secondaryLinkHref,
  secondaryLinkTarget,
  secondaryButtonText = 'Learn more',
}: PageLayoutDestinationBoxesItemProps) => {
  const styles = useStyles(styleRefs)

  const hasPrimary = primaryTextHTML || primaryLinkHref
  const hasSecondary = secondaryTextHTML || secondaryLinkHref

  return (
    <Box
      styles={{
        display: 'flex',
        flexDirection: 'column',
        flex: [null, 'equal0'],
        marginRight: [-4, 6, 8],
        marginLeft: [-4, 0],
      }}
    >
      {hasPrimary && (
        <ConditionalWrap
          condition={Boolean(primaryLinkHref)}
          wrap={(children) => (
            <Link
              href={primaryLinkHref!}
              target={primaryLinkTarget}
              className={styles.primaryLink}
            >
              {children}
            </Link>
          )}
        >
          <Box
            className={styles.primaryMinHeight}
            styles={{
              display: 'flex',
              flexDirection: 'column',
              alignItems: 'center',
              justifyContent: 'center',
              backgroundColor: 'beige40',
              color: 'white',
              padding: [4, 6, 8],
              position: 'relative',
            }}
          >
            {primaryImageFluid && (
              <GatsbyImageContainer
                variant="cover"
                styles={{
                  position: 'absolute',
                  pointerEvents: 'none',
                  overflow: 'hidden',
                }}
              >
                <GatsbyImage
                  fluid={primaryImageFluid}
                  alt={primaryImageAlt}
                  className={styles.primaryImage}
                />
              </GatsbyImageContainer>
            )}
            {primaryTextHTML && (
              <Box styles={{ display: 'flex', alignItems: 'center' }}>
                <HTMLContent
                  html={primaryTextHTML}
                  componentOverrides={{
                    h1: () => (props) => (
                      <Heading as="h2" variant="sansC" {...props} />
                    ),
                  }}
                  styles={{
                    position: 'relative',
                    marginRight: [4, 6, 8],
                  }}
                />
                <Icon
                  name="chevronRightLarge"
                  className={styles.chevronIcon}
                  styles={{ flexShrink: 0 }}
                />
              </Box>
            )}
          </Box>
        </ConditionalWrap>
      )}
      {hasSecondary && (
        <Box
          styles={{
            backgroundColor: 'beige80',
            color: 'brown20',
            display: 'flex',
            flexDirection: 'column',
            flexGrow: 1,
            marginTop: hasPrimary ? [0, 6, 8] : undefined,
            paddingBottom: [8, 6, 8],
            paddingTop: [24, 6, 8],
            paddingRight: [4, 6, 8],
            paddingLeft: [4, 6, 8],
            position: 'relative',
          }}
        >
          {secondaryImageFluid && (
            <GatsbyImageContainer
              variant="cover"
              styles={{
                position: 'absolute',
                pointerEvents: 'none',
              }}
            >
              <GatsbyImageFaded
                fluid={secondaryImageFluid}
                alt={secondaryImageAlt}
                variant="beige80Left"
                styles={{ height: 'full' }}
                // gradientPercentages={[25, 75]}
                // gradientPercentagesMobile={[0, 75]}
                // fadeToMobile="bottom"
                // fadeTo="left"
              />
            </GatsbyImageContainer>
          )}
          <Box
            styles={{
              display: 'flex',
              flexDirection: 'column',
              alignItems: 'start',
              position: 'relative',
            }}
          >
            {secondaryTextHTML && (
              <HTMLContent
                html={secondaryTextHTML}
                componentOverrides={{
                  h2: () => (props) => (
                    <Heading
                      variant="sansE"
                      {...props}
                      styles={{ marginBottom: 4 }}
                    />
                  ),
                }}
                styles={{
                  marginBottom: 5,
                  width: '9/12',
                  flexGrow: 1,
                }}
              />
            )}
            {secondaryLinkHref && (
              <Box styles={{ marginBottom: -0.5 }}>
                <ButtonLink
                  href={secondaryLinkHref}
                  target={secondaryLinkTarget}
                  variant="red"
                >
                  {secondaryButtonText}
                </ButtonLink>
              </Box>
            )}
          </Box>
        </Box>
      )}
    </Box>
  )
}

export const mapDataToProps = ({
  data,
}: MapDataToPropsArgs<PageLayoutDestinationBoxesFragment>) => ({
  textHTML: getRichText(data.primary?.text),
  children: data.items?.map((item) => (
    <PageLayoutDestinationBoxes.Section
      key={item?.primary_link?.url}
      primaryImageFluid={item?.primary_image?.fluid as FluidObject}
      primaryImageAlt={item?.primary_image?.alt}
      primaryTextHTML={getRichText(item!.primary_body)}
      primaryLinkHref={item?.primary_link?.url}
      primaryLinkTarget={item?.primary_link?.target}
      secondaryImageFluid={item?.secondary_image?.fluid as FluidObject}
      secondaryImageAlt={item?.secondary_image?.alt}
      secondaryTextHTML={getRichText(item!.secondary_body)}
      secondaryLinkHref={item?.secondary_button_link?.url}
      secondaryLinkTarget={item?.secondary_button_link?.target}
      secondaryButtonText={item?.secondary_button_text}
    />
  )) as React.ReactNode,
})

export const mapDataToContext = () => ({
  bg: 'white',
})

export const query = graphql`
  fragment PageLayoutDestinationBoxes on PrismicPageLayoutDestinationBoxes {
    primary {
      text {
        text
        html
      }
    }
    items {
      primary_image {
        alt
        fluid(maxWidth: 700) {
          ...GatsbyPrismicImageFluid
        }
      }
      primary_body {
        text
        html
      }
      primary_link {
        url
        target
      }
      secondary_image {
        alt
        fluid(maxWidth: 700) {
          ...GatsbyPrismicImageFluid
        }
      }
      secondary_body {
        text
        html
      }
      secondary_button_text
      secondary_button_link {
        url
        target
      }
    }
  }
`

export default PageLayoutDestinationBoxes
