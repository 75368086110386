import * as React from 'react'
import { graphql } from 'gatsby'
import { Box } from '@walltowall/calico'
import { getRichText } from '@walltowall/helpers'
import { boolean } from 'boolean'

import { PageLayoutRichTextFragment } from '../graphqlTypes'
import { MapDataToPropsArgs, PickPartial } from '../types'
import { PageTemplateEnhancerProps } from '../templates/page'
import { useUtilStyles } from '../hooks/useUtilStyles'

import { StyledHTMLContent } from '../components/StyledHTMLContent'
import { BoundedBox } from '../components/BoundedBox'
import { Divider } from '../components/Divider'

export type PageLayoutRichTextProps = PickPartial<
  ReturnType<typeof mapDataToProps>,
  'children'
> &
  PageTemplateEnhancerProps

export const PageLayoutRichText = ({
  introHTML,
  children,
  nextSharesBg,
  id,
}: PageLayoutRichTextProps) => {
  const { lastNoMargin } = useUtilStyles()

  return (
    <BoundedBox
      id={id}
      component="section"
      innerMaxWidth="medium"
      nextSharesBg={nextSharesBg}
      styles={{
        backgroundColor: 'white',
        color: 'brown20',
        maxWidth: 'xlarge',
        marginRight: 'auto',
        marginLeft: 'auto',
      }}
    >
      {introHTML && (
        <StyledHTMLContent
          html={introHTML}
          className={lastNoMargin}
          styles={{
            textAlign: ['left', 'center'],
            marginBottom: [7, 9, 11],
          }}
        />
      )}
      {React.Children.count(children) > 0 && (
        <Box
          styles={{
            display: ['block', 'flex'],
            flexDirection: 'row',
            justifyContent: 'center',
          }}
        >
          {children}
        </Box>
      )}
    </BoundedBox>
  )
}

export type PageLayoutRichTextItemProps = {
  textHTML?: string
  centerOnDesktop: boolean
}

PageLayoutRichText.Item = ({
  textHTML,
  centerOnDesktop,
}: PageLayoutRichTextItemProps) => {
  const { hideIfFirstChild } = useUtilStyles()

  return (
    <>
      <Divider
        color="beige80"
        className={hideIfFirstChild}
        styles={{
          marginTop: [6, 0],
          marginBottom: [6, 0],
          marginLeft: [0, 7, 8],
          marginRight: [0, 7, 8],
          width: ['auto', '1px'],
          height: ['1px', 'auto'],
          flexShrink: 0,
        }}
      />
      <Box
        styles={{
          maxWidth: 'small',
          width: 'full',
          textAlign: ['left', centerOnDesktop ? 'center' : 'left'],
        }}
      >
        <StyledHTMLContent html={textHTML} />
      </Box>
    </>
  )
}

export const mapDataToProps = ({
  data,
}: MapDataToPropsArgs<PageLayoutRichTextFragment>) => ({
  introHTML: getRichText(data.primary?.intro),
  children: data.items
    ?.filter?.((item) => item?.text?.text)
    ?.map?.((item) => (
      <PageLayoutRichText.Item
        key={item?.text?.text}
        textHTML={getRichText(item?.text)}
        centerOnDesktop={boolean(data.primary?.center_on_desktop)}
      />
    )) as React.ReactNode,
})

export const mapDataToContext = () => ({
  bg: 'white',
})

export const query = graphql`
  fragment PageLayoutRichText on PrismicPageLayoutRichText {
    primary {
      intro {
        text
        html
      }
      center_on_desktop
      full_width
    }
    items {
      text {
        text
        html
      }
    }
  }
`

export default PageLayoutRichText
