import * as React from 'react'
import { useStaticQuery, graphql } from 'gatsby'
import { boolean } from 'boolean'
import { isBrowser } from '@walltowall/helpers'
import { point } from '@turf/helpers'
import distance from '@turf/distance'
import querystring from 'querystring'

import {
  PageLayoutLocationsSearchLocationsQuery,
  PageLayoutLocationsSearchFragment,
} from '../graphqlTypes'
import { Unpacked, MapDataToPropsArgs } from '../types'
import { PageTemplateEnhancerProps } from '../templates/page'

import { SearchBar } from './PageLayoutLocationsSearch/SearchBar'
import { SearchResults } from './PageLayoutLocationsSearch/SearchResults'
import { Closures } from './PageLayoutLocationsSearch/Closures'
import { Map } from './PageLayoutLocationsSearch/Map'

export type HNBLocation = Unpacked<
  PageLayoutLocationsSearchLocationsQuery['allPrismicLocation']['nodes']
>

/**
 * Maximum distance in miles from search center point to location to include in
 * the search results.
 */
const MAX_SEARCH_DISTANCE = 5

/**
 * Subset of a GeoJSON Feature only including properties needed by this
 * component.
 */
interface Feature {
  type: 'Feature'
  id: string
  center: [number, number]
}

/**
 * Subset of a GeoJSON Feature only including properties needed by this
 * component.
 */
interface FeatureCollection {
  type: 'FeatureCollection'
  features: Feature[]
}

/**
 * Returns a GeoJSON FeatureCollection with search results for a given query.
 * Uses the Mapbox Geocoding API.
 */
const geocode = async (query: string): Promise<FeatureCollection> => {
  const encodedQuery = encodeURIComponent(query)
  const url = `https://api.mapbox.com/geocoding/v5/mapbox.places/${encodedQuery}.json?access_token=${process.env.GATSBY_MAPBOX_ACCESS_TOKEN}`

  const res = await fetch(url)
  const json = await res.json()

  return json
}

const filterLocationsByQuery = async (
  query: string,
  locations: HNBLocation[],
) => {
  if (!query) return locations

  const featureCollection = await geocode(query)
  const queryPoint = featureCollection.features[0]?.center

  if (!queryPoint) return locations

  return locations.filter((loc) => {
    const geopoint = loc.data?.geopoint

    // If the location doesn't have a geopoint, exclude it.
    if (!geopoint?.longitude || !geopoint?.latitude) return false

    const locPoint = point([geopoint.longitude, geopoint.latitude])
    const locDistance = distance(queryPoint, locPoint, { units: 'miles' })

    return locDistance <= MAX_SEARCH_DISTANCE
  })
}

export type PageLayoutLocationsSearchProps = Partial<
  ReturnType<typeof mapDataToProps>
> &
  PageTemplateEnhancerProps

export const PageLayoutLocationsSearch = ({
  showMap = false,
  showResults = false,
  showClosures = false,
  id,
}: PageLayoutLocationsSearchProps) => {
  const searchResultsRef = React.useRef<HTMLDivElement>(null)

  const queryResult = useStaticQuery<
    PageLayoutLocationsSearchLocationsQuery
  >(graphql`
    query PageLayoutLocationsSearchLocations {
      allPrismicLocation {
        nodes {
          id
          data {
            title {
              text
            }
            island
            address {
              html
              text
            }
            hours {
              day
              open_time
              close_time
            }
            special_note {
              html
              text
            }
            phone_number
            geopoint {
              longitude
              latitude
            }
          }
        }
      }
    }
  `)
  const allLocations = queryResult.allPrismicLocation.nodes

  const [locations, setLocations] = React.useState(allLocations)
  const query = isBrowser
    ? (querystring.decode(location.search.slice(1)).query as string)
    : ''

  React.useEffect(() => {
    const asyncEffect = async () => {
      const newLocations = await filterLocationsByQuery(query, allLocations)
      setLocations(newLocations)
    }

    asyncEffect()
  }, [query, allLocations])

  return (
    <section id={id}>
      {showMap && (
        <Map searchResultsRef={searchResultsRef} locations={locations} />
      )}
      <SearchBar />
      {showResults && (
        <SearchResults ref={searchResultsRef} locations={locations} />
      )}
      {showClosures && <Closures />}
    </section>
  )
}

export const mapDataToProps = ({
  data,
}: MapDataToPropsArgs<PageLayoutLocationsSearchFragment>) => ({
  showMap: boolean(data.primary?.show_map),
  showResults: boolean(data.primary?.show_results),
  showClosures: boolean(data.primary?.show_closures),
})

export const mapDataToContext = () => ({
  bg: Symbol(),
})

export const query = graphql`
  fragment PageLayoutLocationsSearch on PrismicPageLayoutLocationsSearch {
    primary {
      show_map
      show_results
      show_closures
    }
  }
`

export default PageLayoutLocationsSearch
