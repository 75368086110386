import { graphql, useStaticQuery } from 'gatsby'

import { UseNavigationQuery } from '../graphqlTypes'

export const useNavigation = () => {
  const queryData = useStaticQuery<UseNavigationQuery>(graphql`
    query UseNavigation {
      prismicNavigation {
        data {
          primary {
            ... on PrismicNavigationPrimaryNavItem {
              id
              primary {
                name
                link {
                  url
                }
              }
              items {
                name
                description {
                  text
                }
                link {
                  url
                }
              }
            }
          }
          secondary {
            ... on PrismicNavigationSecondaryNavItem {
              id
              primary {
                name
                link {
                  url
                }
              }
            }
          }
          footer {
            ... on PrismicNavigationFooterNavItem {
              id
              primary {
                name
                link {
                  url
                }
              }
            }
          }
        }
      }
    }
  `)

  return {
    primary: queryData.prismicNavigation?.data?.primary,
    secondary: queryData.prismicNavigation?.data?.secondary,
    footer: queryData.prismicNavigation?.data?.footer,
  }
}
