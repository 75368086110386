import React from 'react'

export const SecurityBeacon = () => {
  return (
    <img
      src="https://d21y75miwcfqoq.cloudfront.net/cd2a7330"
      alt=""
      style={{ position: 'absolute', left: 0, top: 0 }}
      //@ts-ignore
      referrerPolicy="no-referrer-when-downgrade"
    />
  )
}
