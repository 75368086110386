import { graphql } from 'gatsby'

import { MapDataToContextArgs } from '../types'
import { PageLayoutAnchorFragment } from '../graphqlTypes'

export const PageLayoutAnchor = () => null

export const mapDataToContext = ({
  NextComp,
  ...rest
}: MapDataToContextArgs<PageLayoutAnchorFragment>) =>
  // @ts-ignore mapDataToContext is assigned in PageLayout.ts
  NextComp?.mapDataToContext?.({ NextComp, ...rest })

export const fragment = graphql`
  fragment PageLayoutAnchor on PrismicPageLayoutAnchor {
    primary {
      id
    }
  }
`

export default PageLayoutAnchor
