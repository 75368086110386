import * as React from 'react'
import { Box } from '@walltowall/calico'
import { undefIfEmpty } from '@walltowall/helpers'

import { ButtonLink } from './ButtonLink'
import { Divider } from './Divider'
import { HTMLContent } from './HTMLContent'
import { Heading } from './Heading'
import { Text } from './Text'
import { Stack } from './Stack'
import { Inline } from './Inline'

export type LocationCardProps = {
  title?: string
  addressHTML?: string
  phoneNumber?: string
  hours?: { day?: string; openTime?: string; closeTime?: string }[]
  specialNoteHTML?: string
}

export const LocationCard = ({
  title,
  addressHTML,
  phoneNumber,
  hours,
  specialNoteHTML,
}: LocationCardProps) => {
  const url = new URL('https://maps.google.com')
  url.searchParams.set('q', `Hawaii National Bank ${title}`)

  const directionsHref = url.toString()

  return (
    <Box styles={{ backgroundColor: 'beige100', color: 'brown20' }}>
      {title && (
        <Box styles={{ backgroundColor: 'beige80', padding: 5 }}>
          <Heading level={4} variant="sansE">
            {title}
          </Heading>
        </Box>
      )}
      <Box styles={{ padding: 5, paddingBottom: [8, 10] }}>
        <Stack space={[6, 8]}>
          {addressHTML && <HTMLContent html={addressHTML} />}
          {phoneNumber && (
            <Stack space={3}>
              <Heading
                level={5}
                variant="sansCapsC"
                styles={{ color: 'fuschia30' }}
              >
                Phone
              </Heading>
              <Text>{phoneNumber}</Text>
            </Stack>
          )}
          {undefIfEmpty(hours) && (
            <Stack space={3}>
              <Heading
                level={5}
                variant="sansCapsC"
                styles={{ color: 'fuschia30' }}
              >
                Hours
              </Heading>
              <Box component="table" styles={{ marginBottom: [-3, -4] }}>
                <tbody>
                  {hours?.map((row) => (
                    <tr key={row?.day}>
                      <Box
                        component="td"
                        styles={{
                          paddingRight: [6, 9, 12],
                          paddingBottom: [3, 4],
                        }}
                      >
                        <Text styles={{ textTransform: 'capitalize' }}>
                          {row?.day}
                        </Text>
                      </Box>
                      <Box component="td" styles={{ paddingBottom: [3, 4] }}>
                        <Text>
                          {row?.openTime} &ndash; {row?.closeTime}
                        </Text>
                      </Box>
                    </tr>
                  ))}
                </tbody>
              </Box>
            </Stack>
          )}
          {specialNoteHTML && <Divider color="beige80" />}
          {specialNoteHTML && <HTMLContent html={specialNoteHTML} />}
          {(directionsHref || phoneNumber) && (
            <Inline space={4} align="center">
              {directionsHref && (
                <ButtonLink variant="redSmall" href={directionsHref}>
                  Directions
                </ButtonLink>
              )}
              {phoneNumber && (
                <ButtonLink variant="redSmall" href={`tel:${phoneNumber}`}>
                  Call
                </ButtonLink>
              )}
            </Inline>
          )}
        </Stack>
      </Box>
    </Box>
  )
}
