import * as React from 'react'
import { graphql } from 'gatsby'
import { FluidObject } from 'gatsby-image'
import GatsbyImage from 'gatsby-image/withIEPolyfill'
import { Box, BoxProps } from '@walltowall/calico'
import { AspectRatio } from '@walltowall/siamese'
import ConditionalWrap from 'conditional-wrap'
import { boolean } from 'boolean'

import { PageLayoutVideoFragment } from '../graphqlTypes'
import { MapDataToPropsArgs } from '../types'
import { PageTemplateEnhancerProps } from '../templates/page'

import { GatsbyImageContainer } from '../components/GatsbyImageContainer'

type VideoProps = {
  aspectRatio?: number
  posterFluid?: FluidObject
  posterAlt?: string
} & BoxProps

const Video = ({
  aspectRatio,
  src,
  autoPlay,
  loop,
  muted,
  posterFluid,
  posterAlt,
  ...props
}: VideoProps) => {
  const [isLoaded, setIsLoaded] = React.useState(false)
  const onPlay = () => setIsLoaded(true)

  return (
    <Box
      {...props}
      styles={{
        position: 'relative',
        ...props.styles,
      }}
    >
      <ConditionalWrap
        condition={Boolean(aspectRatio)}
        wrap={(children) => (
          <AspectRatio x={aspectRatio!} y={1}>
            {children}
          </AspectRatio>
        )}
      >
        <>
          <Box
            component="video"
            src={src}
            autoPlay={autoPlay}
            loop={loop}
            muted={muted}
            playsInline={true}
            onPlay={onPlay}
            styles={{ display: 'block' }}
          />
          {posterFluid && (
            <GatsbyImageContainer
              styles={{
                position: 'absolute',
                top: 0,
                bottom: 0,
                left: 0,
                right: 0,
                pointerEvents: 'none',
                opacity: isLoaded ? 0 : 100,
                transitionDuration: 'normal',
                transitionProperty: 'opacity',
                transitionTimingFunction: 'easeOut',
              }}
            >
              <GatsbyImage fluid={posterFluid} alt={posterAlt} />
            </GatsbyImageContainer>
          )}
        </>
      </ConditionalWrap>
    </Box>
  )
}

export type PageLayoutVideoProps = ReturnType<typeof mapDataToProps> &
  PageTemplateEnhancerProps

export const PageLayoutVideo = ({
  mobileVideoURL,
  mobileVideoAspectRatio,
  mobilePosterFluid: mobilePosterImageFluid,
  mobilePosterAlt: mobilePosterImageAlt,
  videoURL,
  videoAspectRatio,
  posterFluid: posterImageFluid,
  posterAlt: posterImageAlt,
  autoPlay,
  loop,
  muted,
  id,
}: PageLayoutVideoProps) => (
  <Box
    id={id}
    component="section"
    styles={{
      backgroundColor: 'brown20',
      maxWidth: 'xlarge',
      marginRight: 'auto',
      marginLeft: 'auto',
      overflow: 'hidden',
      position: 'relative',
    }}
  >
    {mobileVideoURL ? (
      <>
        <Video
          aspectRatio={mobileVideoAspectRatio}
          autoPlay={autoPlay}
          loop={loop}
          muted={muted}
          src={mobileVideoURL}
          posterFluid={mobilePosterImageFluid}
          posterAlt={mobilePosterImageAlt}
          styles={{ display: ['block', 'none'] }}
        />
        <Video
          aspectRatio={videoAspectRatio}
          autoPlay={autoPlay}
          loop={loop}
          muted={muted}
          src={videoURL}
          posterFluid={posterImageFluid}
          posterAlt={posterImageAlt}
          styles={{ display: ['none', 'block'] }}
        />
      </>
    ) : (
      <Video
        aspectRatio={videoAspectRatio}
        autoPlay={autoPlay}
        loop={loop}
        muted={muted}
        src={videoURL}
        posterFluid={posterImageFluid}
        posterAlt={posterImageAlt}
      />
    )}
  </Box>
)

export const mapDataToProps = ({
  data,
}: MapDataToPropsArgs<PageLayoutVideoFragment>) => ({
  mobileVideoURL: data.primary?.mobile_video_url,
  mobileVideoAspectRatio:
    data.primary?._ON_BUILD_ONLY_mobile_video_ffprobe?.aspectRatio,
  mobilePosterFluid: data.primary?.mobile_poster?.fluid,
  mobilePosterAlt: data.primary?.mobile_poster?.alt,
  videoURL: data.primary?.video_url,
  videoAspectRatio: data.primary?._ON_BUILD_ONLY_video_ffprobe?.aspectRatio,
  posterFluid: data.primary?.poster?.fluid,
  posterAlt: data.primary?.poster?.alt,
  autoPlay: boolean(data.primary?.auto_play),
  loop: boolean(data.primary?.loop),
  muted: boolean(data.primary?.mute_audio),
})

export const mapDataToContext = () => ({
  bg: Symbol(),
})

export const query = graphql`
  fragment PageLayoutVideo on PrismicPageLayoutVideo {
    primary {
      video_url
      _ON_BUILD_ONLY_video_ffprobe {
        aspectRatio
      }
      poster {
        alt
        fluid(maxWidth: 1000) {
          ...GatsbyPrismicImageFluid
        }
      }
      mobile_video_url
      _ON_BUILD_ONLY_mobile_video_ffprobe {
        aspectRatio
      }
      mobile_poster {
        alt
        fluid(maxWidth: 480) {
          ...GatsbyPrismicImageFluid
        }
      }
      loop
      mute_audio
      auto_play
    }
  }
`

export default PageLayoutVideo
