import * as React from 'react'
import { useStyles } from 'react-treat'
import { Box, BoxProps } from '@walltowall/calico'

import { Icon } from './Icon'
import clsx from 'clsx'

import * as styleRefs from './ExpandCircle.treat'

type ExpandButtonProps = {
  isExpanded: boolean
} & BoxProps

export const ExpandCircle = ({ isExpanded, ...props }: ExpandButtonProps) => {
  const styles = useStyles(styleRefs)

  return (
    <Box
      className={styles.expandIconCircle}
      {...props}
      styles={{
        alignItems: 'center',
        backgroundColor: 'maroon30',
        borderRadius: '50%',
        display: 'flex',
        height: '1.5rem',
        justifyContent: 'center',
        width: '1.5rem',
        ...props.styles,
      }}
    >
      <Icon
        name="chevronDown"
        className={clsx(isExpanded && styles.expandIconRotated)}
        styles={{
          width: '6/12',
          color: 'white',
          transitionProperty: 'transform',
          transitionDuration: 'normal',
          transitionTimingFunction: 'easeOut',
        }}
      />
    </Box>
  )
}

ExpandCircle.focusCaptureClassName = styleRefs.focusCapture
