import * as React from 'react'

export const OutlineWarrior = (
  props: React.ComponentPropsWithoutRef<'svg'>,
) => {
  return (
    <svg viewBox="0 0 3076.908 3831.177" {...props}>
      <use href="/images/sprites.svg#outline-warrior" />
    </svg>
  )
}
