import React from 'react'
import { FieldText, FieldTextProps } from './FieldText'

type InputTextProps = FieldTextProps

export const FieldTextarea = (props: InputTextProps) => (
  <FieldText
    component="textarea"
    {...props}
    styles={{ height: '6rem', ...props.styles }}
  />
)
