import * as React from 'react'
import { useStyles } from 'react-treat'
import { Box } from '@walltowall/calico'

import { PageLayoutDestinationBarProps } from '../PageLayoutDestinationBar'

import { BoundedBox } from '../../components/BoundedBox'
import { HTMLContent } from '../../components/HTMLContent'
import { ButtonLink } from '../../components/ButtonLink'

import * as styleRefs from './BasicTextHeavy.treat'

type BasicTextHeavyProps = {
  side: 'left' | 'right'
} & PageLayoutDestinationBarProps

export const BasicTextHeavy = ({
  bodyHTML,
  buttonText,
  buttonHref,
  buttonTarget,
  side,
  nextSharesBg,
  id,
}: BasicTextHeavyProps) => {
  const styles = useStyles(styleRefs)

  return (
    <BoundedBox
      id={id}
      component="section"
      nextSharesBg={nextSharesBg}
      innerMaxWidth="medium"
      className={styles.diamondBackground[side]}
      styles={{
        backgroundColor: 'white',
        color: 'brown20',
        marginRight: 'auto',
        marginLeft: 'auto',
        maxWidth: 'xlarge',
        position: 'relative',
      }}
    >
      <Box
        styles={{
          display: 'flex',
          alignItems: ['start', side === 'left' ? 'start' : 'end'],
          flexDirection: 'column',
          justifyContent: 'center',
          position: 'relative',
          minHeight: ['none', '22rem'],
        }}
      >
        <Box
          styles={{
            display: 'flex',
            flexDirection: 'column',
            alignItems: 'start',
            width: ['full', '6/12'],
          }}
        >
          {bodyHTML && (
            <HTMLContent
              html={bodyHTML}
              styles={{ maxWidth: 'small', width: 'full' }}
              componentOverrides={{
                h2: (Comp) => (props) => (
                  <Comp
                    level={2}
                    variant="sansC"
                    {...props}
                    styles={{
                      color: 'beige40',
                      marginTop: 0,
                      marginBottom: [6, 8],
                    }}
                  />
                ),
                h3: (Comp) => (props) => (
                  <Comp
                    level={3}
                    variant="sansCapsA"
                    {...props}
                    styles={{
                      marginTop: 0,
                      marginBottom: 4,
                    }}
                  />
                ),
                h4: (Comp) => (props) => (
                  <Comp
                    level={4}
                    {...props}
                    styles={{
                      color: 'fuschia30',
                      marginTop: 0,
                      marginBottom: 4,
                    }}
                  />
                ),
                p: (Comp) => (props) => (
                  <Comp
                    {...props}
                    styles={{
                      marginTop: 0,
                      marginBottom: [6, 8],
                    }}
                  />
                ),
              }}
            />
          )}
          {buttonHref && (
            <Box styles={{ marginTop: [6, 8] }}>
              <ButtonLink variant="red" href={buttonHref} target={buttonTarget}>
                {buttonText}
              </ButtonLink>
            </Box>
          )}
        </Box>
      </Box>
    </BoundedBox>
  )
}
