import * as React from 'react'
import { navigate, graphql, useStaticQuery } from 'gatsby'
import { useStyles } from 'react-treat'
import VisuallyHidden from '@reach/visually-hidden'
import { Box } from '@walltowall/calico'
import { Formik, Form as FormikForm, Field as FormikField } from 'formik'
import { useLunr } from 'react-lunr'
import querystring from 'querystring'

import { MapDataToPropsArgs, PagesSearchResult } from '../types'
import { LocalSearchPagesQuery } from '../graphqlTypes'
import { PageTemplateEnhancerProps } from '../templates/page'

import { BoundedBox } from '../components/BoundedBox'
import { Heading } from '../components/Heading'
import { Text } from '../components/Text'
import { Anchor } from '../components/Anchor'
import { ButtonIcon } from '../components/ButtonIcon'
import { Icon } from '../components/Icon'

import * as styleRefs from './PageLayoutSearchResults.treat'

export type PageLayoutSearchResultsProps = Partial<
  ReturnType<typeof mapDataToProps>
> &
  PageTemplateEnhancerProps

export const PageLayoutSearchResults = ({
  query: rawQuery,
  nextSharesBg,
  id,
}: PageLayoutSearchResultsProps) => {
  const styles = useStyles(styleRefs)

  const [index, setIndex] = React.useState<string>()
  const [store, setStore] = React.useState<Record<string, PagesSearchResult>>()
  const isReady = index && store

  const queryData = useStaticQuery<LocalSearchPagesQuery>(graphql`
    query LocalSearchPages {
      localSearchPages {
        publicIndexURL
        publicStoreURL
      }
    }
  `)

  React.useEffect(() => {
    const asyncFn = async () => {
      const [index, store] = await Promise.all([
        fetch(queryData.localSearchPages?.publicIndexURL!).then((data) =>
          data.json(),
        ),
        fetch(queryData.localSearchPages?.publicStoreURL!).then((data) =>
          data.json(),
        ),
      ])

      setIndex(index)
      setStore(store)
    }

    asyncFn()
  }, [queryData])

  const query = String(rawQuery ?? '').trim()
  const results = useLunr(
    // `replace` removes Lunr special characters used for fuzzy searching
    query.replace(/(\*|\~)/g, ''),
    index,
    store,
  ) as PagesSearchResult[]

  const onSearchSubmit = (values: { query: string }) => {
    const path = values.query
      ? `/search/?${querystring.encode({ query: values.query })}`
      : '/search/'
    navigate(path)
  }

  return (
    <Box id={id} component="section">
      <BoundedBox
        innerMaxWidth="large"
        className={styles.shadow}
        variant="extraNarrow"
        styles={{
          backgroundColor: 'beige100',
          position: 'relative',
          zIndex: 1,
        }}
      >
        <Box
          className={styles.diamondBackground}
          styles={{
            position: 'absolute',
            top: 0,
            left: 0,
            right: 0,
            bottom: 0,
            opacity: 15,
            pointerEvents: 'none',
          }}
        />
        <Box styles={{ display: 'flex', justifyContent: 'center' }}>
          <Formik initialValues={{ query }} onSubmit={onSearchSubmit}>
            <FormikForm className={styles.formContent}>
              <Box styles={{ display: 'flex', position: 'relative' }}>
                <VisuallyHidden>
                  <label htmlFor="location-query">Search</label>
                </VisuallyHidden>
                <FormikField
                  as={(props: object) => (
                    <Box
                      component="input"
                      id="location-query"
                      placeholder="Search&hellip;"
                      className={styles.input}
                      styles={{
                        borderStyle: 'solid',
                        borderWidth: '1px',
                        borderRightWidth: 'none',
                        borderColor: 'beige80',
                        backgroundColor: 'white',
                        color: 'brown20',
                        flexGrow: 1,
                        paddingRight: 5,
                        paddingLeft: 5,
                        paddingTop: 3,
                        paddingBottom: 3,
                        fontFamily: 'sans',
                      }}
                      {...props}
                    />
                  )}
                  name="query"
                />
                <ButtonIcon variant="red" attachDirection="left">
                  <VisuallyHidden>Submit</VisuallyHidden>
                  <Box className={styles.icon}>
                    <Icon name="search" />
                  </Box>
                </ButtonIcon>
              </Box>
            </FormikForm>
          </Formik>
        </Box>
      </BoundedBox>
      <BoundedBox
        nextSharesBg={nextSharesBg}
        innerMaxWidth="small"
        styles={{
          backgroundColor: 'white',
          color: 'brown20',
          marginRight: 'auto',
          marginLeft: 'auto',
          maxWidth: 'xlarge',
        }}
      >
        {isReady && results.length > 0 ? (
          <Box>
            <Heading
              level={2}
              variant="sansC"
              styles={{ color: 'beige40', marginBottom: [5, 6] }}
            >
              Search Results
            </Heading>
            <Text component="p" styles={{ marginBottom: [8, 12] }}>
              Showing {results.length} result{results.length === 1 ? '' : 's'}{' '}
              for{' '}
              <Box component="strong" styles={{ fontWeight: 'semibold' }}>
                {query}
              </Box>
            </Text>
            <Box
              as="ul"
              styles={{
                borderWidth: 'none',
                borderTopWidth: '1px',
                borderStyle: 'solid',
                borderColor: 'beige80',
              }}
            >
              {results.map((result) => (
                <Box
                  key={result.url}
                  as="li"
                  styles={{
                    borderWidth: 'none',
                    borderBottomWidth: '1px',
                    borderStyle: 'solid',
                    borderColor: 'beige80',
                    paddingTop: [6, 8],
                    paddingBottom: [6, 8],
                  }}
                >
                  <Text variant="serif-14" styles={{ marginBottom: 4 }}>
                    <Anchor href={result.url}>{result.title}</Anchor>
                  </Text>
                  <Text>{result.description}</Text>
                </Box>
              ))}
            </Box>
          </Box>
        ) : query ? (
          <Box
            styles={{
              minHeight: '16rem',
              display: 'flex',
              alignItems: 'center',
              justifyContent: 'center',
              flexDirection: 'column',
            }}
          >
            <Heading
              level={2}
              variant="sansD"
              styles={{
                color: 'beige40',
                textAlign: 'center',
                marginBottom: [6, 8],
              }}
            >
              Sorry, no results matched your search terms.
            </Heading>
            <Text styles={{ textAlign: 'center' }}>
              Try a different query or{' '}
              <Anchor href="/">go to the homepage</Anchor>.
            </Text>
          </Box>
        ) : (
          <Box
            styles={{
              minHeight: '16rem',
              display: 'flex',
              alignItems: 'center',
              justifyContent: 'center',
              flexDirection: 'column',
            }}
          >
            <Heading
              level={2}
              variant="sansD"
              styles={{
                color: 'beige40',
                textAlign: 'center',
                marginBottom: [6, 8],
              }}
            >
              Enter a search term above.
            </Heading>
            <Text styles={{ textAlign: 'center' }}>
              Results will appear here.
            </Text>
          </Box>
        )}
      </BoundedBox>
    </Box>
  )
}

export const mapDataToProps = ({
  meta,
}: MapDataToPropsArgs<undefined, typeof mapDataToContext>) => ({
  query: meta?.location
    ? String(
        querystring.decode(meta?.location.search.replace(/^\?/, '')).query ??
          '',
      ) || undefined
    : undefined,
})

export const mapDataToContext = () => ({
  bg: 'white',
})

export default PageLayoutSearchResults
