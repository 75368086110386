import * as React from 'react'
import { graphql } from 'gatsby'
import { useStyles } from 'react-treat'
import { Box } from '@walltowall/calico'
import { getRichText } from '@walltowall/helpers'
import { camelCase } from 'camel-case'
import { FluidObject } from 'gatsby-image'
import { useKeenSlider } from 'keen-slider/react'
import GatsbyImage from 'gatsby-image/withIEPolyfill'
import clsx from 'clsx'

import { PageLayoutPromotionsFragment } from '../graphqlTypes'
import { MapDataToPropsArgs } from '../types'
import { PageTemplateEnhancerProps } from '../templates/page'
import { useInterval } from '../hooks/useInterval'

import { ButtonLink } from '../components/ButtonLink'
import { HTMLContent } from '../components/HTMLContent'
import { BoundedBox } from '../components/BoundedBox'
import { GatsbyImageContainer } from '../components/GatsbyImageContainer'
import { Inline } from '../components/Inline'
import { ButtonIcon } from '../components/ButtonIcon'

import * as styleRefs from './PageLayoutPromotions.treat'

import 'keen-slider/keen-slider.min.css'

const SLIDE_DURATION = 6000 // ms

export type PageLayoutPromotionsProps = ReturnType<typeof mapDataToProps> &
  PageTemplateEnhancerProps

export const PageLayoutPromotions = ({
  children,
  id,
}: PageLayoutPromotionsProps) => {
  const styles = useStyles(styleRefs)

  const childrenCount = React.Children.count(children)
  const shouldShowControls = React.Children.count(children) >= 2

  const [currentSlideIndex, setCurrentSlideIndex] = React.useState(0)
  const [sliderRef, slider] = useKeenSlider({
    loop: true,
    slideChanged: (slide) =>
      setCurrentSlideIndex(slide.details().relativeSlide),
    controls: shouldShowControls,
  })

  const restartInterval = useInterval({
    callback: () => slider.next(),
    interval: SLIDE_DURATION,
    enabled: shouldShowControls,
  })

  return (
    <Box
      id={id}
      component="section"
      styles={{
        backgroundColor: 'beige100',
        maxWidth: 'xlarge',
        marginRight: 'auto',
        marginLeft: 'auto',
        position: 'relative',
      }}
    >
      <Box ref={sliderRef} className="keen-slider">
        {children}
      </Box>

      {shouldShowControls && (
        <>
          <ButtonIcon
            variant="whiteSmall"
            iconName="chevronRight"
            iconProps={{ styles: { width: '0.75rem' } }}
            className={styles.positionYArrowButton}
            onClick={() => {
              restartInterval()
              slider.next()
            }}
            styles={{
              visibility: ['hidden', 'hidden', 'visible'],
              position: 'absolute',
              right: '1.25rem',
            }}
          />
          <ButtonIcon
            variant="whiteSmall"
            iconName="chevronLeft"
            iconProps={{ styles: { width: '0.75rem' } }}
            className={styles.positionYArrowButton}
            onClick={() => {
              restartInterval()
              slider.prev()
            }}
            styles={{
              visibility: ['hidden', 'hidden', 'visible'],
              position: 'absolute',
              left: '1.25rem',
            }}
          />
          <Box
            styles={{
              borderRadius: '10em',
              backgroundColor: 'beige40',
              paddingTop: 2,
              paddingBottom: 2,
              paddingLeft: 2.5,
              paddingRight: 2.5,
              position: 'absolute',
              bottom: '1.25rem',
              right: '1.25rem',
            }}
          >
            <Inline space={2}>
              {Array(childrenCount)
                .fill(undefined)
                .map((_, i) => (
                  <Box
                    key={i}
                    component="button"
                    onClick={() => {
                      restartInterval()
                      slider.moveToSlide(i)
                    }}
                    styles={{
                      display: 'block',
                      backgroundColor: 'white',
                      borderRadius: '10em',
                      cursor: 'pointer',
                      width: '0.5rem',
                      height: '0.5rem',
                      opacity: currentSlideIndex === i ? 100 : 25,
                      transitionDuration: 'normal',
                      transitionProperty: 'opacity',
                      transitionTimingFunction: 'easeInOut',
                    }}
                    focusStyles={{
                      opacity: 100,
                    }}
                    hoverStyles={{
                      opacity: 100,
                    }}
                  />
                ))}
            </Inline>
          </Box>
        </>
      )}
    </Box>
  )
}

const variants = {
  maroon: {
    backgroundColor: 'brown20',
    color: 'white',
    buttonVariant: 'white',
    backgroundClassName: 'diamondDarkBackground',
  },
  beige: {
    backgroundColor: 'beige100',
    color: 'brown20',
    buttonVariant: 'red',
    backgroundClassName: 'fadedDiamondBackground',
  },
} as const

export type PageLayoutPromotionsItemProps = {
  variant?: keyof typeof variants
  textHTML?: string
  buttonHref?: string
  buttonTarget?: string
  buttonText?: string
  imageFluid?: FluidObject
  imageAlt?: string
}

PageLayoutPromotions.Item = ({
  textHTML,
  buttonHref,
  buttonTarget,
  buttonText = 'Learn more',
  imageFluid,
  imageAlt,
  variant: variantName = 'beige',
}: PageLayoutPromotionsItemProps) => {
  const styles = useStyles(styleRefs)

  const variant = variants[variantName]
  const backgroundClassName =
    variant.backgroundClassName && styles[variant.backgroundClassName]

  return (
    <BoundedBox
      innerMaxWidth="medium"
      variant="narrow"
      className={clsx('keen-slider__slide', backgroundClassName)}
      styles={{
        backgroundColor: variant.backgroundColor,
        color: variant.color,
        marginRight: 'auto',
        marginLeft: 'auto',
        maxWidth: 'xlarge',
        position: 'relative',
      }}
    >
      <Box
        styles={{
          display: 'flex',
          alignItems: 'center',
          position: 'relative',
          height: 'full',
        }}
      >
        <GatsbyImageContainer
          styles={{
            display: ['none', 'block'],
            alignSelf: 'stretch',
            marginRight: [4, 10],
            marginTop: [-10, -10, -13],
            marginBottom: [-10, -10, -13],
            width: '6/12',
          }}
        >
          {imageFluid && <GatsbyImage fluid={imageFluid} alt={imageAlt} />}
        </GatsbyImageContainer>
        <Box
          styles={{
            display: 'flex',
            flexDirection: 'column',
            alignItems: 'start',
            width: ['full', '6/12'],
          }}
        >
          {textHTML && (
            <HTMLContent
              html={textHTML}
              componentOverrides={{
                h2: (Comp) => (props) => (
                  <Comp
                    level={2}
                    variant="sansCapsD"
                    {...props}
                    styles={{
                      marginTop: 0,
                      marginBottom: [6, 8],
                    }}
                  />
                ),
                h4: (Comp) => (props) => <Comp variant="sansE" {...props} />,
                p: (Comp) => (props) => (
                  <Comp
                    {...props}
                    styles={{
                      marginTop: 0,
                      marginBottom: [6, 8],
                    }}
                  />
                ),
              }}
              styles={{ maxWidth: 'small' }}
            />
          )}
          {buttonHref && (
            <Box styles={{ marginTop: [6, 8] }}>
              <ButtonLink
                variant={variant.buttonVariant}
                href={buttonHref}
                target={buttonTarget}
              >
                {buttonText}
              </ButtonLink>
            </Box>
          )}
        </Box>
      </Box>
    </BoundedBox>
  )
}

export const mapDataToProps = ({
  data,
}: MapDataToPropsArgs<PageLayoutPromotionsFragment>) => ({
  children: data.items?.map((item) => (
    <PageLayoutPromotions.Item
      key={item?.text?.text}
      textHTML={getRichText(item?.text)}
      buttonHref={item?.button_link?.url}
      buttonTarget={item?.button_link?.target}
      buttonText={item?.button_text}
      variant={
        item?.background_variant
          ? (camelCase(item.background_variant) as keyof typeof variants)
          : undefined
      }
      imageFluid={item?.image?.fluid}
      imageAlt={item?.image?.alt}
    />
  )) as React.ReactNode,
})

export const mapDataToContext = () => ({
  bg: Symbol(),
})

export const query = graphql`
  fragment PageLayoutPromotions on PrismicPageLayoutPromotions {
    items {
      background_variant
      text {
        text
        html
      }
      button_text
      button_link {
        url
        target
      }
      image {
        alt
        fluid(maxWidth: 800) {
          ...GatsbyPrismicImageFluid
        }
      }
    }
  }
`

export default PageLayoutPromotions
