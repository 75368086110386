import React from 'react'
import { BoxProps, Box } from '@walltowall/calico'
import { Marker, MarkerProps } from 'react-map-gl'

import { Icon } from '../../components/Icon'

import * as styleRefs from './MapMarker.treat'

type MapMarkerProps = MarkerProps &
  BoxProps & {
    viewportZoom: number
  }

export const MapMarker = ({
  longitude,
  latitude,
  offsetLeft,
  offsetTop,
  viewportZoom,
  ...props
}: MapMarkerProps) => {
  return (
    <Marker
      latitude={latitude}
      longitude={longitude}
      offsetLeft={offsetLeft}
      offsetTop={offsetTop}
    >
      <Box
        {...props}
        styles={{
          position: 'relative',
          width: '1px',
          height: '1px',
          ...props.styles,
        }}
      >
        <Icon
          name="marker"
          className={styleRefs.markerPosition}
          styles={{
            color: 'maroon40',
            width: viewportZoom > 10 ? '1.75rem' : '1rem',
            transitionDuration: 'normal',
            position: 'absolute',
          }}
        />
      </Box>
    </Marker>
  )
}
