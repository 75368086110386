import * as React from 'react'
import { Box, useBoxStyles, usePseudoBoxStyles } from '@walltowall/calico'
import clsx from 'clsx'

import { useStaticNotification } from '../../hooks/useStaticNotification'

import { BoundedBox } from '../../components/BoundedBox'
import { HTMLContent } from '../../components/HTMLContent'
import { Icon } from '../../components/Icon'
import { Text } from '../../components/Text'
import { Link } from '../../components/Link'

export const StaticNotification = () => {
  const staticNotification = useStaticNotification()
  const staticNotificationLinkClassNames = clsx(
    useBoxStyles({
      textTransform: 'uppercase',
      fontWeight: 'semibold',
      letterSpacing: 'xs',
      fontSize: '0.85em',
      marginLeft: 2,
    }),
    usePseudoBoxStyles({ textDecoration: 'underline' }, 'focus'),
    usePseudoBoxStyles({ textDecoration: 'underline' }, 'hover'),
  )

  return (
    <BoundedBox
      innerMaxWidth="medium"
      styles={{
        paddingTop: 4,
        paddingBottom: 4,
        backgroundColor: 'gold70',
        color: 'brown20',
        position: 'relative',
        zIndex: 4,
      }}
    >
      <Box styles={{ display: 'flex', alignItems: ['start', 'center'] }}>
        <Icon
          name="exclaim"
          styles={{
            width: '1.25rem',
            marginRight: 4,
            marginTop: [-1, 0],
            flexShrink: 0,
          }}
        />
        <Text component="div" styles={{ textAlign: ['left', 'center'] }}>
          <HTMLContent
            html={staticNotification.textHTML}
            componentOverrides={{
              p: () => (props) => <Box component="span" {...props} />,
            }}
            styles={{ display: 'inline', flexGrow: 1 }}
          />
          {staticNotification.linkHref && (
            <Link
              href={staticNotification.linkHref}
              className={staticNotificationLinkClassNames}
            >
              <Box styles={{ display: 'inlineFlex', alignItems: 'center' }}>
                {staticNotification.linkText}
                <Icon
                  name="chevronRight"
                  styles={{ width: '0.5rem', marginLeft: 1 }}
                />
              </Box>
            </Link>
          )}
        </Text>
      </Box>
    </BoundedBox>
  )
}
