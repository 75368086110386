import React from 'react'
import { useStyles } from 'react-treat'
import { useField } from 'formik'
import { Box, BoxProps } from '@walltowall/calico'

import { PickRequired } from '../types'

import * as styleRefs from './FieldCheckbox.treat'

export type FieldCheckboxProps = PickRequired<BoxProps, 'name'>

export const FieldCheckbox = ({ name, ...props }: FieldCheckboxProps) => {
  const styles = useStyles(styleRefs)

  const [field, { error, touched }] = useField({
    name,
    type: 'checkbox',
  })
  const hasError = touched && error

  return (
    <Box
      component="input"
      type="checkbox"
      className={styles.input}
      {...field}
      {...props}
      styles={{
        display: 'block',
        width: '1rem',
        height: '1rem',
        backgroundColor: hasError ? 'maroon95' : 'white',
        borderColor: hasError ? 'maroon80' : 'beige80',
        borderStyle: 'solid',
        borderWidth: '1px',
        transitionDuration: 'normal',
        transitionProperty: 'borderColor',
        transitionTimingFunction: 'easeOut',
        ...props.styles,
      }}
      focusStyles={{
        borderColor: 'beige40',
      }}
    />
  )
}
