import * as React from 'react'
import { graphql } from 'gatsby'
import { useStyles } from 'react-treat'
import { FluidObject } from 'gatsby-image'
import { Box } from '@walltowall/calico'
import { getRichText } from '@walltowall/helpers'
import clsx from 'clsx'

import { PageLayoutSplitPanelsFragment } from '../graphqlTypes'
import { MapDataToPropsArgs, MapDataToContextArgs } from '../types'
import { PageTemplateEnhancerProps } from '../templates/page'
import { useUtilStyles } from '../hooks/useUtilStyles'

import { HTMLContent } from '../components/HTMLContent'
import { ButtonLink } from '../components/ButtonLink'
import { BoundedBox } from '../components/BoundedBox'
import { Heading } from '../components/Heading'
import { GatsbyImageFaded } from '../components/GatsbyImageFaded'
import { GatsbyImageContainer } from '../components/GatsbyImageContainer'
import { Divider } from '../components/Divider'

import * as styleRefs from './PageLayoutSplitPanels.treat'

export type PageLayoutSplitPanelsProps = ReturnType<typeof mapDataToProps> &
  PageTemplateEnhancerProps

export const PageLayoutSplitPanels = ({
  hasImages,
  children,
  id,
}: PageLayoutSplitPanelsProps) => {
  const styles = useStyles(styleRefs)

  return (
    <Box
      id={id}
      component="section"
      className={styles.minHeight}
      styles={{
        display: ['block', 'flex'],
        flexDirection: 'row',
        backgroundColor: hasImages ? 'white' : 'beige100',
        color: 'brown20',
        marginLeft: 'auto',
        marginRight: 'auto',
        maxWidth: 'xlarge',
      }}
    >
      {children}
    </Box>
  )
}

export type PageLayoutSplitPanelsPanelProps = {
  buttonText?: string
  buttonHref?: string
  buttonTarget?: string
  imageAlt?: string
  imageFluid?: FluidObject
  isFirst?: boolean
  textHTML?: string
}

const Panel = ({
  buttonText = 'Learn more',
  buttonHref,
  buttonTarget,
  imageAlt,
  imageFluid,
  isFirst = false,
  textHTML,
}: PageLayoutSplitPanelsPanelProps) => {
  const styles = useStyles(styleRefs)
  const { hideIfFirstChild } = useUtilStyles()

  return (
    <>
      <Divider
        color="beige80"
        className={hideIfFirstChild}
        styles={{
          width: ['auto', '1px'],
          height: ['1px', 'auto'],
          alignSelf: 'stretch',
          marginTop: [0, 10, 12],
          marginBottom: [0, 10, 12],
          marginRight: [4, 0],
          marginLeft: [4, 0],
          position: 'relative',
          display: [imageFluid ? 'none' : 'block', 'block'],
        }}
      />
      <BoundedBox
        styles={{
          flexGrow: 1,
          flexShrink: 1,
          flexBasis: 0,
          position: 'relative',
        }}
      >
        <Box
          className={clsx(imageFluid && styles.panelWithImageSizing)}
          styles={{
            display: 'flex',
            flexDirection: 'column',
            height: 'full',
            justifyContent: 'center',
          }}
        >
          {imageFluid && (
            <GatsbyImageContainer
              variant="cover"
              styles={{
                pointerEvents: 'none',
                position: 'absolute',
              }}
            >
              <GatsbyImageFaded
                variant={isFirst ? 'whiteRight' : 'whiteLeft'}
                fluid={imageFluid}
                alt={imageAlt}
                className={styles.image}
              />
            </GatsbyImageContainer>
          )}
          <Box
            styles={{
              display: 'flex',
              flexDirection: 'column',
              alignItems: ['start', isFirst ? 'end' : 'start'],
              textAlign: ['left', isFirst ? 'right' : 'left'],
              position: 'relative',
            }}
          >
            {textHTML && (
              <HTMLContent
                html={textHTML}
                componentOverrides={{
                  h2: () => (props) => (
                    <Heading
                      level={2}
                      variant="sansC"
                      {...props}
                      styles={{
                        color: 'beige40',
                        marginBottom: [6, 8],
                      }}
                    />
                  ),
                  p: (Comp) => (props) => (
                    <Comp variant="sans-18-20" {...props} />
                  ),
                }}
                styles={{
                  width: ['full', '8/12'],
                }}
              />
            )}
            {buttonHref && (
              <Box styles={{ marginTop: [6, 8] }}>
                <ButtonLink
                  href={buttonHref}
                  target={buttonTarget}
                  variant="red"
                >
                  {buttonText}
                </ButtonLink>
              </Box>
            )}
          </Box>
        </Box>
      </BoundedBox>
    </>
  )
}
PageLayoutSplitPanels.Panel = Panel

export const mapDataToProps = ({
  data,
}: MapDataToPropsArgs<PageLayoutSplitPanelsFragment>) => ({
  hasImages: data.items?.some((item) => item?.image?.fluid),
  children: data.items?.map((item, i) => (
    <PageLayoutSplitPanels.Panel
      key={item?.text?.text}
      buttonText={item?.button_text}
      buttonHref={item?.button_link?.url}
      buttonTarget={item?.button_link?.target}
      imageAlt={item?.image?.alt}
      imageFluid={item?.image?.fluid as FluidObject}
      isFirst={i === 0}
      textHTML={getRichText(item?.text)}
    />
  )) as React.ReactNode,
})

export const mapDataToContext = ({
  data,
}: MapDataToContextArgs<PageLayoutSplitPanelsFragment>) => ({
  bg: data.items?.some((item) => item?.image?.fluid) ? Symbol() : 'beige100',
})

export const query = graphql`
  fragment PageLayoutSplitPanels on PrismicPageLayoutSplitPanels {
    items {
      image {
        alt
        fluid(maxWidth: 800) {
          ...GatsbyPrismicImageFluid
        }
      }
      text {
        text
        html
      }
      button_text
      button_link {
        url
        target
      }
    }
  }
`

export default PageLayoutSplitPanels
