import * as React from 'react'
import { Box, BoxProps } from '@walltowall/calico'

const variants = {
  div: {
    component: 'div',
    childComponent: 'div',
  },
  list: {
    component: 'ul',
    childComponent: 'li',
  },
  'ordered-list': {
    component: 'ol',
    childComponent: 'li',
  },
} as const

export type StackProps = {
  variant?: keyof typeof variants
  children: React.ReactNode
  space: NonNullable<NonNullable<BoxProps['styles']>['padding']>
  align?: NonNullable<BoxProps['styles']>['alignItems']
  alignY?: NonNullable<BoxProps['styles']>['justifyContent']
} & BoxProps

export const Stack = ({
  children,
  variant = 'div',
  space,
  align,
  alignY,
  ...props
}: StackProps) => {
  const negativeSpace = (Array.isArray(space)
    ? space.map((val) => -val)
    : -space) as typeof space

  const { component, childComponent } = variants[variant]

  return (
    <Box
      component={component}
      {...props}
      styles={{
        display: 'flex',
        flexDirection: 'column',
        alignItems: align,
        justifyContent: alignY,
        marginTop: negativeSpace,
        ...props.styles,
      }}
    >
      {React.Children.map(
        children,
        (child) =>
          child && (
            <Box
              component={childComponent}
              styles={{ paddingTop: space, maxWidth: 'full' }}
            >
              {child}
            </Box>
          ),
      )}
    </Box>
  )
}
