import * as React from 'react'

import { HTMLContent, HTMLContentProps } from './HTMLContent'

export const StyledHTMLContent = (props: HTMLContentProps) => (
  <HTMLContent
    componentOverrides={{
      h2: (Comp) => (props) => (
        <Comp styles={{ color: 'beige40' }} {...props} />
      ),
      h3: (Comp) => (props) => (
        <Comp styles={{ color: 'beige40' }} {...props} />
      ),
      h4: (Comp) => (props) => (
        <Comp styles={{ color: 'fuschia30' }} {...props} />
      ),
      h6: (Comp) => (props) => (
        <Comp styles={{ color: 'beige40' }} {...props} />
      ),
    }}
    {...props}
    styles={{ color: 'brown20', ...props.styles }}
  />
)
