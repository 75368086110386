import * as React from 'react'
import { graphql, navigate } from 'gatsby'
import { Box, BoxProps } from '@walltowall/calico'
import { Formik, Form as FormikForm, useField } from 'formik'
import { getRichText, postNetlifyForm } from '@walltowall/helpers'
import Recaptcha from 'react-google-recaptcha'
import * as nope from 'nope-validator'
import { ShapeErrors } from 'nope-validator/lib/umd/types'
import VisuallyHidden from '@reach/visually-hidden'
import ConditionalWrap from 'conditional-wrap'

import { PageLayoutContactFormFragment } from '../graphqlTypes'
import { MapDataToPropsArgs } from '../types'
import { PageTemplateEnhancerProps } from '../templates/page'

import { Heading } from '../components/Heading'
import { BoundedBox } from '../components/BoundedBox'
import { Text } from '../components/Text'
import { Button } from '../components/Button'
import { HTMLContent } from '../components/HTMLContent'
import { FieldText } from '../components/FieldText'
import { Columns } from '../components/Columns'
import { FieldTextarea } from '../components/FieldTextarea'
import { FieldCheckbox } from '../components/FieldCheckbox'
import { FieldRadio } from '../components/FieldRadio'
import { Inline } from '../components/Inline'

const NETLIFY_FORM_NAME = 'Contact Us'

const initialValues = {
  firstName: '',
  lastName: '',
  phoneNumber: '',
  email: '',
  contactPreference: 'phone',
  currentCustomer: '',
  message: '',
  'g-recaptcha-response': '',
}

const validator = nope.object().shape({
  firstName: nope.string().required('First name is required'),
  lastName: nope.string().required('Last name is required'),
  phoneNumber: nope.string().required('Phone number is required'),
  email: nope.string().email().required('Must be a valid email'),
  contactPreference: nope.string().required('Must select a contact preference'),
  currentCustomer: nope.boolean(),
  message: nope.string().required('Message is required'),
  'g-recaptcha-response': nope
    .string()
    .required('Please complete the reCAPTCHA field'),
})

type FormikRecaptchaProps = {
  name?: string
  siteKey: string
}

const FormikRecaptcha = ({
  name = 'g-recaptcha-response',
  siteKey,
}: FormikRecaptchaProps) => {
  const [, , { setValue }] = useField(name)
  const onChange = (response: string) => setValue(response)

  return <Recaptcha sitekey={siteKey} onChange={onChange} />
}

type FieldTextProps = {
  label: string
  displayLabel?: boolean
  isRequired?: boolean
  name: string
  children: React.ReactNode
  labelDirection?: NonNullable<BoxProps['styles']>['flexDirection']
} & BoxProps

const Field = ({
  label,
  name,
  children,
  displayLabel = true,
  isRequired = false,
  labelDirection = 'column',
  ...props
}: FieldTextProps) => {
  const [, { error, touched }] = useField(name)
  const hasError = touched && error

  return (
    <Box
      component="label"
      {...props}
      styles={{ display: 'block', ...props.styles }}
    >
      <Box
        styles={{
          display: 'flex',
          flexDirection: labelDirection,
          alignItems: labelDirection === 'column' ? undefined : 'center',
        }}
      >
        <ConditionalWrap
          condition={!displayLabel}
          wrap={(children) => <VisuallyHidden>{children}</VisuallyHidden>}
        >
          <Text
            component="span"
            styles={{
              display: 'block',
              fontWeight: 'medium',
              marginBottom: labelDirection === 'column' ? [2, 3] : undefined,
              marginRight: labelDirection === 'row' ? [3, 4] : undefined,
              color: hasError ? 'maroon40' : 'brown20',
            }}
          >
            {label}
            {isRequired && (
              <Box
                component="span"
                styles={{ color: 'beige40', marginLeft: 1 }}
              >
                (required)
              </Box>
            )}
          </Text>
        </ConditionalWrap>
        <Box
          styles={{
            marginLeft: labelDirection === 'column' ? -1 : undefined,
            marginRight: -1,
          }}
        >
          {children}
        </Box>
      </Box>
      {hasError && (
        <Text styles={{ marginTop: [2, 3], color: 'maroon40' }}>{error}</Text>
      )}
    </Box>
  )
}

const FieldGroup = ({
  label,
  name,
  children,
  displayLabel = true,
  isRequired = false,
  labelDirection = 'column',
  ...props
}: FieldTextProps) => {
  const [, { error, touched }] = useField(name)
  const hasError = touched && error

  return (
    <Box {...props}>
      <Box
        styles={{
          display: 'flex',
          flexDirection: labelDirection,
          alignItems: labelDirection === 'column' ? undefined : 'center',
        }}
      >
        <ConditionalWrap
          condition={!displayLabel}
          wrap={(children) => <VisuallyHidden>{children}</VisuallyHidden>}
        >
          <Text
            component="span"
            id={name}
            styles={{
              display: 'block',
              fontWeight: 'medium',
              marginBottom: labelDirection === 'column' ? [2, 3] : undefined,
              marginRight: labelDirection === 'row' ? [3, 4] : undefined,
              color: hasError ? 'maroon40' : 'brown20',
            }}
          >
            {label}
            {isRequired && (
              <Box
                component="span"
                styles={{ color: 'beige40', marginLeft: 1 }}
              >
                (required)
              </Box>
            )}
          </Text>
        </ConditionalWrap>
        <Box
          role="group"
          aria-labelledby={name}
          styles={{
            marginLeft: labelDirection === 'column' ? -1 : undefined,
            marginRight: -1,
          }}
        >
          {children}
        </Box>
      </Box>
      {hasError && (
        <Text styles={{ marginTop: [2, 3], color: 'maroon40' }}>{error}</Text>
      )}
    </Box>
  )
}

export type PageLayoutContactFormProps = ReturnType<typeof mapDataToProps> &
  PageTemplateEnhancerProps

export const PageLayoutContactForm = ({
  heading,
  disclaimerHTML,
  nextSharesBg,
  id,
}: PageLayoutContactFormProps) => {
  const onSubmit = async (values: typeof initialValues) => {
    const res = await postNetlifyForm(NETLIFY_FORM_NAME, values)
    navigate(res ? '/thank-you/' : '/error/')
  }

  return (
    <BoundedBox
      id={id}
      component="section"
      nextSharesBg={nextSharesBg}
      innerMaxWidth="small"
      styles={{
        backgroundColor: 'white',
        color: 'brown20',
        maxWidth: 'xlarge',
        marginRight: 'auto',
        marginLeft: 'auto',
        display: 'flex',
        flexDirection: ['column', 'row'],
      }}
    >
      {heading && (
        <Heading
          level={2}
          variant="sansC"
          styles={{
            color: 'beige40',
            textAlign: ['left', 'center'],
            marginBottom: [8, 12],
          }}
        >
          {heading}
        </Heading>
      )}
      <Formik
        initialValues={initialValues}
        validate={(values) => validator.validate(values) as ShapeErrors}
        validateOnChange={false}
        validateOnBlur={true}
        onSubmit={onSubmit}
      >
        <FormikForm
          name={NETLIFY_FORM_NAME}
          data-netlify={true}
          data-netlify-recaptcha={true}
        >
          <Columns
            count={[1, 2]}
            space={[5, 6]}
            styles={{ marginBottom: [5, 6] }}
          >
            <Field name="firstName" label="First Name" isRequired={true}>
              <FieldText name="firstName" placeholder="First name" />
            </Field>
            <Field name="lastName" label="Last Name" isRequired={true}>
              <FieldText name="lastName" placeholder="Last name" />
            </Field>
            <Field name="phoneNumber" label="Phone Number" isRequired={true}>
              <FieldText name="phoneNumber" placeholder="Phone number" />
            </Field>
            <Field name="email" label="Email address" isRequired={true}>
              <FieldText name="email" placeholder="Email address" />
            </Field>
          </Columns>
          <Field
            name="currentCustomer"
            label="Current HNB Customer?"
            labelDirection="row"
            styles={{ marginBottom: [5, 6] }}
          >
            <FieldCheckbox name="currentCustomer" />
          </Field>
          <FieldGroup
            name="contactPreference"
            label="Preferred Contact Method"
            labelDirection="row"
            styles={{ marginBottom: [5, 6] }}
          >
            <Inline space={3} spaceY={2}>
              <FieldRadio
                name="contactPreference"
                value="phone"
                label="Phone"
              />
              <FieldRadio
                name="contactPreference"
                value="email"
                label="Email"
              />
            </Inline>
          </FieldGroup>
          <Field
            name="message"
            label="Message"
            isRequired={true}
            styles={{ marginBottom: [5, 6] }}
          >
            <FieldTextarea name="message" placeholder="Your message" />
          </Field>
          <Field
            name="g-recaptcha-response"
            label="reCAPTCHA"
            displayLabel={false}
            isRequired={true}
            styles={{ marginBottom: [8, 10] }}
          >
            <FormikRecaptcha siteKey={process.env.GATSBY_SITE_RECAPTCHA_KEY!} />
          </Field>
          <Box
            styles={{
              display: 'flex',
              flexDirection: ['column', 'row'],
              alignItems: ['start', 'center'],
            }}
          >
            {disclaimerHTML && (
              <HTMLContent
                html={disclaimerHTML}
                styles={{
                  marginBottom: [6, 0],
                  marginRight: [0, 6],
                  flexGrow: 1,
                }}
              />
            )}
            <Button variant="red" type="submit">
              Send
            </Button>
          </Box>
        </FormikForm>
      </Formik>
    </BoundedBox>
  )
}

export const mapDataToProps = ({
  data,
}: MapDataToPropsArgs<PageLayoutContactFormFragment>) => ({
  heading: data.primary?.heading?.text,
  disclaimerHTML: getRichText(data.primary?.disclaimer),
})

export const mapDataToContext = () => ({
  bg: 'white',
})

export const query = graphql`
  fragment PageLayoutContactForm on PrismicPageLayoutContactForm {
    primary {
      heading {
        text
      }
      disclaimer {
        text
        html
      }
    }
  }
`

export default PageLayoutContactForm
