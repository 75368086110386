import * as React from 'react'
import { Box } from '@walltowall/calico'
import GatsbyImage from 'gatsby-image/withIEPolyfill'

import { useFadedDiamondBackground } from '../../hooks/useFadedDiamondBackground'
import { PageLayoutDestinationBarProps } from '../PageLayoutDestinationBar'

import { BoundedBox } from '../../components/BoundedBox'
import { HTMLContent } from '../../components/HTMLContent'
import { ButtonLink } from '../../components/ButtonLink'

type SpecializedCardsProps = {
  side: 'left' | 'right'
} & PageLayoutDestinationBarProps

export const SpecializedCards = ({
  bodyHTML,
  buttonText,
  buttonHref,
  buttonTarget,
  imageFluid,
  imageAlt,
  side,
  nextSharesBg,
  id,
}: SpecializedCardsProps) => {
  const backgroundClassName = useFadedDiamondBackground('beige100', side)

  return (
    <BoundedBox
      id={id}
      component="section"
      nextSharesBg={nextSharesBg}
      innerMaxWidth="medium"
      className={backgroundClassName}
      styles={{
        backgroundColor: 'beige100',
        color: 'brown20',
        marginRight: 'auto',
        marginLeft: 'auto',
        maxWidth: 'xlarge',
        position: 'relative',
      }}
    >
      <Box
        styles={{
          display: 'flex',
          alignItems: ['stretch', 'center'],
          flexDirection: [
            'columnReverse',
            side === 'right' ? 'row' : 'rowReverse',
          ],
          position: 'relative',
        }}
      >
        <Box
          styles={{
            display: 'flex',
            flexDirection: 'column',
            justifyContent: 'end',
            marginRight: side === 'right' ? ['auto', 10, 16] : ['auto', 0],
            marginLeft: side === 'left' ? ['auto', 10, 16] : ['auto', 0],
            marginTop: [0, -20, -25],
            marginBottom: [-10, -15, -20],
            maxWidth: ['19rem', 'none'],
            width: ['full', '6/12'],
          }}
        >
          {imageFluid && <GatsbyImage fluid={imageFluid} alt={imageAlt} />}
        </Box>
        <Box
          styles={{
            display: 'flex',
            flexDirection: 'column',
            alignItems: 'start',
            width: ['full', '6/12'],
          }}
        >
          {bodyHTML && (
            <HTMLContent
              html={bodyHTML}
              componentOverrides={{
                h2: (Comp) => (props) => (
                  <Comp
                    level={2}
                    variant="sansC"
                    {...props}
                    styles={{
                      color: 'beige40',
                      marginTop: 0,
                      marginBottom: [6, 8],
                    }}
                  />
                ),
                h3: (Comp) => (props) => (
                  <Comp
                    level={3}
                    variant="sansCapsA"
                    {...props}
                    styles={{
                      marginTop: 0,
                      marginBottom: 4,
                    }}
                  />
                ),
                h4: (Comp) => (props) => (
                  <Comp
                    level={4}
                    {...props}
                    styles={{
                      color: 'fuschia30',
                      marginTop: 0,
                      marginBottom: 4,
                    }}
                  />
                ),
                p: (Comp) => (props) => (
                  <Comp
                    {...props}
                    styles={{
                      marginTop: 0,
                      marginBottom: [6, 8],
                    }}
                  />
                ),
              }}
              styles={{ maxWidth: 'small' }}
            />
          )}
          {buttonHref && (
            <Box styles={{ marginTop: [6, 8] }}>
              <ButtonLink variant="red" href={buttonHref} target={buttonTarget}>
                {buttonText}
              </ButtonLink>
            </Box>
          )}
        </Box>
      </Box>
    </BoundedBox>
  )
}
