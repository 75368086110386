import { graphql, useStaticQuery } from 'gatsby'
import { boolean } from 'boolean'

import { UseTemporaryNotificationQuery } from '../graphqlTypes'
import { getRichText } from '@walltowall/helpers'

export const useTemporaryNotification = () => {
  const queryData = useStaticQuery<UseTemporaryNotificationQuery>(graphql`
    query UseTemporaryNotification {
      prismicNotification {
        data {
          active
          text {
            text
            html
          }
        }
      }
    }
  `)

  return {
    isActive: boolean(queryData.prismicNotification?.data?.active),
    textHTML: getRichText(queryData.prismicNotification?.data?.text),
  }
}
