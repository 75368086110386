import { useState, useEffect, useRef, useCallback } from 'react'

const UP = -1
const STATIC = 0
const DOWN = 1
const IS_BROWSER = typeof window !== 'undefined'

const determineDirection = (current: number, previous: number) => {
  if (current === previous) return STATIC

  return current > previous ? DOWN : UP
}

/**
 * React hook that returns an object representing the current scroll direction
 * with a customizable threshold.
 *
 * @param threshold The amount of px to travel before determining a direction.
 * Defaults to 0.
 */
export const useScrollDirection = (threshold = 0) => {
  const prevY = useRef(IS_BROWSER ? window.scrollY : 0)
  const currY = useRef(IS_BROWSER ? window.scrollY : 0)
  const [direction, setDirection] = useState(STATIC)

  const handleScroll = useCallback(() => {
    if (Math.abs(window.scrollY - currY.current) < threshold) return

    prevY.current = currY.current
    currY.current = window.scrollY

    const scrollDirection = determineDirection(currY.current, prevY.current)

    if (scrollDirection !== STATIC && scrollDirection !== direction)
      setDirection(scrollDirection)
  }, [direction, threshold])

  useEffect(() => {
    window.addEventListener('scroll', handleScroll, { passive: true })

    return () => window.removeEventListener('scroll', handleScroll)
  }, [handleScroll])

  return {
    direction,
    isScrollingDown: direction === DOWN,
    isScrollingUp: direction === UP,
  }
}
