import * as React from 'react'
import { graphql } from 'gatsby'
import { Box } from '@walltowall/calico'

import { PageLayoutAppStoreBadgesFragment } from '../graphqlTypes'
import { MapDataToPropsArgs } from '../types'
import { PageTemplateEnhancerProps } from '../templates/page'
import { BoundedBox } from '../components/BoundedBox'
import { Link } from '../components/Link'

const DEFAULT_GOOGLE_PLAY =
  'https://play.google.com/store/apps/details?id=bank.hawaiinational.grip&pli=1'
const DEFUALT_APPLE_APP_STORE =
  'https://apps.apple.com/us/app/hawaii-national-bank/id6448705958'

export type PageLayoutAppStoreBadges = ReturnType<typeof mapDataToProps> &
  PageTemplateEnhancerProps

export const PageLayoutAppStoreBadges = ({
  nextSharesBg,
  id,
  googlePlayHref,
  appleAppStoreHref,
}: PageLayoutAppStoreBadges) => (
  <BoundedBox
    id={id}
    component="section"
    nextSharesBg={nextSharesBg}
    variant="extraNarrow"
    styles={{
      backgroundColor: 'white',
      color: 'brown20',
      maxWidth: 'xlarge',
      marginRight: 'auto',
      marginLeft: 'auto',
    }}
  >
    <Box
      styles={{
        display: 'flex',
        maxWidth: 'small',
        marginRight: 'auto',
        marginLeft: 'auto',
        width: 'full',
        flexWrap: 'wrap',
      }}
      style={{ gap: '1rem' }}
    >
      <Box component={Link} href={googlePlayHref} styles={{ flexShrink: 0 }}>
        <Box
          component="img"
          src="/images/google-play.png"
          alt="Get it on Google Play"
          width={336}
          height={100}
          loading="lazy"
          styles={{ width: 'auto', height: '3rem' }}
        />
      </Box>

      <Box component={Link} href={appleAppStoreHref} styles={{ flexShrink: 0 }}>
        <Box
          component="img"
          src="/images/apple-app-store.png"
          alt="Download on the app store"
          width={361}
          height={100}
          loading="lazy"
          styles={{ width: 'auto', height: '3rem' }}
        />
      </Box>
    </Box>
  </BoundedBox>
)

export const mapDataToProps = ({
  data,
}: MapDataToPropsArgs<PageLayoutAppStoreBadgesFragment>) => ({
  googlePlayHref: data.primary?.google_play_url?.url ?? DEFAULT_GOOGLE_PLAY,
  appleAppStoreHref:
    data.primary?.apple_app_store_url?.url ?? DEFUALT_APPLE_APP_STORE,
})

export const mapDataToContext = () => ({
  bg: 'white',
})

export const query = graphql`
  fragment PageLayoutAppStoreBadges on PrismicPageLayoutAppStoreBadges {
    primary {
      google_play_url {
        url
      }
      apple_app_store_url {
        url
      }
    }
  }
`

export default PageLayoutAppStoreBadges
