import { graphql, useStaticQuery } from 'gatsby'
import { boolean } from 'boolean'

import { UseStaticNotificationQuery } from '../graphqlTypes'
import { getRichText } from '@walltowall/helpers'

export const useStaticNotification = () => {
  const queryData = useStaticQuery<UseStaticNotificationQuery>(graphql`
    query UseStaticNotification {
      prismicNotification {
        data {
          static_active
          static_text {
            text
            html
          }
          static_link {
            url
            target
          }
          static_link_text {
            text
          }
        }
      }
    }
  `)

  return {
    isActive: boolean(queryData.prismicNotification?.data?.static_active),
    textHTML: getRichText(queryData.prismicNotification?.data?.static_text),
    linkHref: queryData.prismicNotification?.data?.static_link?.url,
    linkTarget: queryData.prismicNotification?.data?.static_link?.target,
    linkText: queryData.prismicNotification?.data?.static_link_text?.text,
  }
}
