import * as React from 'react'
import { graphql } from 'gatsby'
import { Box } from '@walltowall/calico'
import GatsbyImage from 'gatsby-image/withIEPolyfill'
import { FluidObject } from 'gatsby-image'

import { PageLayoutImageGalleryFragment } from '../graphqlTypes'
import { MapDataToPropsArgs } from '../types'
import { PageTemplateEnhancerProps } from '../templates/page'

import { GatsbyImageContainer } from '../components/GatsbyImageContainer'

export type PageLayoutImageGalleryProps = ReturnType<typeof mapDataToProps> &
  PageTemplateEnhancerProps

export const PageLayoutImageGallery = ({
  children,
  id,
}: PageLayoutImageGalleryProps) => (
  <Box
    id={id}
    component="section"
    styles={{
      backgroundColor: 'white',
      maxWidth: 'xlarge',
      marginRight: 'auto',
      marginLeft: 'auto',
      display: 'flex',
      flexDirection: ['column', 'row'],
    }}
  >
    {children}
  </Box>
)

export type PageLayoutImageGalleryCardProps = {
  fluid: FluidObject
  alt?: string
}

PageLayoutImageGallery.Image = ({
  fluid,
  alt,
}: PageLayoutImageGalleryCardProps) => (
  <GatsbyImageContainer
    styles={{
      flexGrow: 1,
      flexShrink: 1,
      flexBasis: 0,
    }}
  >
    {fluid && <GatsbyImage fluid={fluid} alt={alt} />}
  </GatsbyImageContainer>
)

export const mapDataToProps = ({
  data,
}: MapDataToPropsArgs<PageLayoutImageGalleryFragment>) => ({
  children: data.items?.map((item) => (
    <PageLayoutImageGallery.Image
      key={item?.image?.fluid?.src}
      fluid={item?.image?.fluid as FluidObject}
      alt={item?.image?.alt}
    />
  )) as React.ReactNode,
})

export const mapDataToContext = () => ({
  bg: Symbol(),
})

export const query = graphql`
  fragment PageLayoutImageGallery on PrismicPageLayoutImageGallery {
    items {
      image {
        alt
        fluid(maxWidth: 750) {
          ...GatsbyPrismicImageFluid
        }
      }
    }
  }
`

export default PageLayoutImageGallery
