import * as React from 'react'
import { Box } from '@walltowall/calico'
import { SkipNavContent } from '@reach/skip-nav'

import { PageTemplateEnhancerProps } from '../templates/page'
import { MapDataToPropsArgs, PickPartial } from '../types'

import { StaticNotification } from './PageLayoutHeader/StaticNotification'
import { Mobile } from './PageLayoutHeader/Mobile'
import { Desktop } from './PageLayoutHeader/Desktop'
import { SignInBar } from './PageLayoutHeader/SignInBar'
import { TemporaryNotification } from './PageLayoutHeader/TemporaryNotification'
import { useStaticNotification } from '../hooks/useStaticNotification'
import { useTemporaryNotification } from '../hooks/useTemporaryNotification'

export type PageLayoutHeaderProps = PickPartial<
  ReturnType<typeof mapDataToProps>,
  'withSignInBar'
> &
  PageTemplateEnhancerProps

const PageLayoutHeader = ({
  withSignInBar = false,
  withTemporaryNotification = false,
  id,
}: PageLayoutHeaderProps) => {
  const [mobileNavIsOpen, toggleMobileNavIsOpen] = React.useReducer(
    (state) => !state,
    false,
  )
  const staticNotification = useStaticNotification()
  const temporaryNotification = useTemporaryNotification()

  return (
    <>
      <Box
        id={id}
        component="header"
        styles={{
          position: ['static', 'static', 'sticky'],
          // We use .5px here because of flickering sub-pixel gap in Chromium browsers.
          top: '-0.5px',
          left: 0,
          right: 0,
          zIndex: 4,
        }}
      >
        {staticNotification.isActive && <StaticNotification />}
        <Mobile
          isOpen={mobileNavIsOpen}
          toggleIsOpen={toggleMobileNavIsOpen}
          styles={{ display: ['block', 'block', 'none'] }}
        />
        <Desktop
          withSignInLink={!withSignInBar}
          styles={{ display: ['none', 'none', 'block'] }}
        />
      </Box>
      {withSignInBar && <SignInBar showMobileSignInButton={!mobileNavIsOpen} />}
      {withTemporaryNotification && temporaryNotification.isActive && (
        <TemporaryNotification />
      )}
      <SkipNavContent />
    </>
  )
}

export const mapDataToProps = ({ meta }: MapDataToPropsArgs) => {
  const isHomepage =
    meta?.location?.pathname && meta?.location?.pathname === '/'

  return {
    withSignInBar: isHomepage,
    withTemporaryNotification: isHomepage,
  }
}

export const mapDataToContext = () => ({
  bg: Symbol(),
})

export default PageLayoutHeader
