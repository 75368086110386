import * as React from 'react'
import { graphql } from 'gatsby'
import { Box } from '@walltowall/calico'
import { getRichText } from '@walltowall/helpers'
import Expand from 'react-expand-animated'

import { PageLayoutRichTextExpandableFragment } from '../graphqlTypes'
import { MapDataToPropsArgs } from '../types'
import { PageTemplateEnhancerProps } from '../templates/page'
import { useUtilStyles } from '../hooks/useUtilStyles'

import { BoundedBox } from '../components/BoundedBox'
import { HTMLContent } from '../components/HTMLContent'
import { StyledHTMLContent } from '../components/StyledHTMLContent'
import { Text } from '../components/Text'
import { ExpandCircle } from '../components/ExpandCircle'
import { Divider } from '../components/Divider'

export type PageLayoutRichTextExpandableProps = Partial<
  ReturnType<typeof mapDataToProps>
> &
  PageTemplateEnhancerProps

export const PageLayoutRichTextExpandable = ({
  introHTML,
  expandableTextHTML,
  withTopDivider,
  children,
  nextSharesBg,
  id,
}: PageLayoutRichTextExpandableProps) => {
  const [isExpanded, toggleExpanded] = React.useReducer(
    (state) => !state,
    false,
  )

  const childrenCount = React.Children.count(children)

  return (
    <BoundedBox
      id={id}
      component="section"
      nextSharesBg={nextSharesBg}
      innerMaxWidth="small"
      styles={{
        backgroundColor: 'white',
        maxWidth: 'xlarge',
        marginLeft: 'auto',
        marginRight: 'auto',
        color: 'brown20',
      }}
    >
      {withTopDivider && (
        <Divider
          color="beige80"
          styles={{ marginBottom: [8, 11, 15], marginTop: [-2, -4, -5] }}
        />
      )}
      {introHTML && <StyledHTMLContent html={introHTML} />}
      {childrenCount > 0 && (
        <Box
          styles={{
            display: ['block', 'flex'],
            flexDirection: 'row',
            marginTop: [8, 9, 10],
          }}
        >
          {React.Children.map(
            children,
            (child, i) =>
              React.isValidElement(child) &&
              React.cloneElement(child, {
                key: i,
                index: i,
                totalCount: React.Children.count(children),
              }),
          )}
        </Box>
      )}
      {expandableTextHTML && (
        <>
          <Expand open={isExpanded}>
            <Box styles={{ marginTop: [8, 9, 10] }}>
              <StyledHTMLContent html={expandableTextHTML} />
            </Box>
          </Expand>
          <Box
            component="button"
            className={ExpandCircle.focusCaptureClassName}
            onClick={toggleExpanded}
            styles={{
              display: 'inlineFlex',
              alignItems: 'center',
              color: 'fuschia30',
              marginTop: [7, 8],
              transitionProperty: 'color',
              transitionDuration: 'normal',
              transitionTimingFunction: 'easeOut',
            }}
            hoverStyles={{ color: 'maroon10' }}
            focusStyles={{ color: 'maroon10' }}
          >
            <ExpandCircle
              isExpanded={isExpanded}
              styles={{ marginRight: 2.5 }}
            />
            <Text>{isExpanded ? 'Less' : 'More'} Details</Text>
          </Box>
        </>
      )}
    </BoundedBox>
  )
}

const itemVariants = {
  default: {
    flexBasis: '4/12',
  },
  wide: {
    flexBasis: '8/12',
  },
} as const

export type PageLayoutRichTextExpandableItemProps = {
  index?: number
  totalCount?: number
  textHTML?: string
  footnoteHTML?: string
}

PageLayoutRichTextExpandable.Item = ({
  index,
  totalCount,
  textHTML,
  footnoteHTML,
}: PageLayoutRichTextExpandableItemProps) => {
  const { lastNoMargin } = useUtilStyles()

  const variantName: keyof typeof itemVariants =
    totalCount === 2 && index === 1 ? 'wide' : 'default'
  const variant = itemVariants[variantName]

  return (
    <Box
      className={lastNoMargin}
      styles={{
        flexGrow: 1,
        flexShrink: 1,
        flexBasis: variant.flexBasis,
        marginRight: [0, 8],
        marginBottom: [7, 0],
      }}
    >
      {textHTML && <StyledHTMLContent html={textHTML} />}
      {footnoteHTML && (
        <HTMLContent
          html={footnoteHTML}
          componentOverrides={{
            p: (Comp) => (props) => <Comp variant="sans-12" {...props} />,
          }}
          styles={{ marginTop: [4, 5] }}
        />
      )}
    </Box>
  )
}

export const mapDataToProps = ({
  data,
  previousType,
}: MapDataToPropsArgs<PageLayoutRichTextExpandableFragment>) => ({
  introHTML: getRichText(data.primary?.intro),
  expandableTextHTML: getRichText(data.primary?.text),
  withTopDivider: previousType === 'PageLayoutRichTextExpandable',
  children: data.items
    ?.filter?.((item) => item?.highlight_text?.text)
    ?.map((item) => (
      <PageLayoutRichTextExpandable.Item
        key={item?.highlight_text?.text}
        textHTML={getRichText(item?.highlight_text)}
        footnoteHTML={getRichText(item?.highlight_footnote)}
      />
    )) as React.ReactNode,
})

export const mapDataToContext = () => ({
  bg: 'white',
})

export const query = graphql`
  fragment PageLayoutRichTextExpandable on PrismicPageLayoutRichTextExpandable {
    primary {
      intro {
        text
        html
      }
      text {
        text
        html
      }
    }
    items {
      highlight_text {
        text
        html
      }
      highlight_footnote {
        text
        html
      }
    }
  }
`

export default PageLayoutRichTextExpandable
