import React from 'react'
import clsx from 'clsx'
import { useStyles } from 'react-treat'
import {
  Box,
  usePseudoBoxStyles,
  useBoxStyles,
  BoxProps,
} from '@walltowall/calico'
import VisuallyHidden from '@reach/visually-hidden'

import { useSiteSettings } from '../hooks/useSiteSettings'
import { useNavigation } from '../hooks/useNavigation'
import { useModal } from '../hooks/useModal'
import { useUtilStyles } from '../hooks/useUtilStyles'

import { BoundedBox } from '../components/BoundedBox'
import { Icon, IconProps } from '../components/Icon'
import { Inline } from '../components/Inline'
import { Link, LinkProps } from '../components/Link'
import { SVG } from '../components/SVG'
import { Text } from '../components/Text'

import { ReactComponent as AssetLogoTextSVG } from '../assets/logo-text.svg'
import { ReactComponent as AssetEqualHousingLenderSVG } from '../assets/logo-equal-housing-lender.svg'

import * as styleRefs from './PageLayoutFooter.treat'
import { PageTemplateEnhancerProps } from '../templates/page'

type FooterLinkProps = {
  color?: NonNullable<BoxProps['styles']>['color']
  focusColor?: NonNullable<BoxProps['styles']>['color']
} & LinkProps

const FooterLink = ({
  className,
  color,
  focusColor = 'white',
  ...props
}: FooterLinkProps) => {
  const classNames = clsx(
    useBoxStyles({
      color,
      transitionProperty: 'color',
      transitionDuration: 'normal',
      transitionTimingFunction: 'easeOut',
    }),
    usePseudoBoxStyles({ color: focusColor }, 'hover'),
    usePseudoBoxStyles({ color: focusColor }, 'focus'),
    className,
  )

  return <Link className={classNames} {...props} />
}

type IconLinkProps = {
  href: LinkProps['href']
  iconName: IconProps['name']
  label: string
}

const IconLink = ({ href, iconName, label }: IconLinkProps) => (
  <FooterLink href={href} focusColor="gray70">
    <VisuallyHidden>{label}</VisuallyHidden>
    <Icon name={iconName} styles={{ width: '1.5rem' }} />
  </FooterLink>
)

type PageLayoutFooterProps = PageTemplateEnhancerProps

export const PageLayoutFooter = ({ id }: PageLayoutFooterProps) => {
  const { openModal } = useModal()
  const styles = useStyles(styleRefs)

  const siteSettings = useSiteSettings()
  const navigation = useNavigation()

  const { hideOnPrint } = useUtilStyles()

  return (
    <footer id={id} className={hideOnPrint}>
      <BoundedBox
        className={styles.warriorBackround}
        variant="narrow"
        styles={{
          backgroundColor: 'brown10',
          backgroundSize: 'cover',
          backgroundPositionX: 'center',
          backgroundPositionY: 'bottom',
          color: 'gray70',
        }}
      >
        <Box
          styles={{
            display: 'flex',
            flexDirection: 'column',
            alignItems: ['start', 'center'],
          }}
        >
          <Box
            className={styles.logo}
            styles={{
              marginBottom: [8, 10],
              color: 'white',
            }}
          >
            <Link href="/">
              <VisuallyHidden>{siteSettings.siteName}</VisuallyHidden>
              <SVG x={586.27} y={30} svg={AssetLogoTextSVG} />
            </Link>
          </Box>

          <Inline
            variant="list"
            space={4}
            align={['start', 'center']}
            className={styles.navMaxWidth}
            styles={{ marginBottom: [8, 10] }}
          >
            {navigation.footer?.map?.(
              (item) =>
                item?.primary?.link?.url && (
                  <FooterLink
                    key={item?.primary?.name}
                    href={item?.primary?.link?.url}
                  >
                    <Text component="span" styles={{ display: 'block' }}>
                      {item?.primary?.name}
                    </Text>
                  </FooterLink>
                ),
            )}
            <Box
              component="button"
              styles={{ display: 'block' }}
              onClick={() => openModal('LOST_CARD')}
            >
              <Text component="span" styles={{ display: 'block' }}>
                Report Lost Card
              </Text>
            </Box>
          </Inline>

          <Inline
            variant="list"
            align="center"
            space={4}
            styles={{ color: 'beige40' }}
          >
            <IconLink
              href={`https://facebook.com/${siteSettings?.facebookHandle}`}
              iconName="facebookSquare"
              label="Facebook"
            />
            <IconLink
              href={`https://instagram.com/${siteSettings?.instagramHandle}`}
              iconName="instagramSquare"
              label="Instagram"
            />
            <IconLink
              href={`https://linkedin.com/company/${siteSettings?.linkedinHandle}`}
              iconName="linkedinSquare"
              label="LinkedIn"
            />
            <IconLink
              href={`https://twitter.com/${siteSettings?.twitterHandle}`}
              iconName="twitterSquare"
              label="Twitter"
            />
          </Inline>
        </Box>
      </BoundedBox>
      <BoundedBox
        variant="extraNarrow"
        innerMaxWidth="large"
        styles={{
          backgroundColor: 'brown0',
          color: 'gray70',
        }}
      >
        <Box
          styles={{
            display: 'flex',
            flexDirection: ['column', 'row'],
            alignItems: ['start', 'center'],
            justifyContent: 'spaceBetween',
          }}
        >
          <Box
            styles={{
              display: 'flex',
              alignItems: 'center',
              marginBottom: [5, 0],
            }}
          >
            <Box
              styles={{
                marginRight: 4,
                flexShrink: 0,
                width: '2rem',
              }}
            >
              <SVG svg={AssetEqualHousingLenderSVG} x={29.86} y={30.67} />
            </Box>
            <Text variant="sans-12" styles={{ flexGrow: 1 }}>
              Equal Opportunity Lender
              <br />
              Member FDIC/Federal Reserve System
            </Text>
          </Box>

          <Text variant="sans-12" styles={{ textAlign: ['left', 'right'] }}>
            {siteSettings.siteCopyright}
            <br />
            Website designed and developed by{' '}
            <FooterLink href="https://walltowall.com" color="white">
              Wall-to-Wall Studios
            </FooterLink>
            .
          </Text>
        </Box>
      </BoundedBox>
    </footer>
  )
}

export const mapDataToContext = () => ({
  bg: 'brown0',
})

export default PageLayoutFooter
