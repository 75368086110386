import * as React from 'react'
import { BoxProps, Box } from '@walltowall/calico'
import { useStyles } from 'react-treat'
import clsx from 'clsx'

import * as styleRefs from './GradientOverlay.treat'

export type GradientOverlayProps = {
  variant: keyof typeof styleRefs.gradients
} & BoxProps

export const GradientOverlay = ({
  variant,
  className,
  ...props
}: GradientOverlayProps) => {
  const styles = useStyles(styleRefs)

  return (
    <Box className={clsx(className, styles.gradients[variant])} {...props} />
  )
}
