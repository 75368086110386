import React from 'react'
import { useStyles } from 'react-treat'
import { Box, BoxProps } from '@walltowall/calico'
import clsx from 'clsx'

import * as styleRefs from './ButtonIcon.treat'
import { Icon, IconProps } from './Icon'

const variants = {
  red: {
    backgroundColor: undefined,
    color: 'white',
    padding: 4,
    textVariant: 'sansCond-16-17',
    size: '3rem',
    variant: 'red',
  },
  white: {
    backgroundColor: 'white',
    color: 'fuschia30',
    padding: 4,
    textVariant: 'sansCond-16-17',
    size: '3rem',
    variant: 'white',
  },
  whiteSmall: {
    backgroundColor: 'white',
    color: 'fuschia30',
    padding: 3,
    textVariant: 'sansCond-16-17',
    size: '2rem',
    variant: 'white',
  },
} as const

export type ButtonIconProps = {
  variant: keyof typeof variants
  attachDirection?: 'left' | 'right'
  iconName?: IconProps['name']
  iconProps?: Partial<IconProps>
} & BoxProps

export const ButtonIcon = ({
  variant: variantName,
  attachDirection,
  iconName,
  iconProps,
  children,
  className,
  ...props
}: ButtonIconProps) => {
  const styles = useStyles(styleRefs)

  const variant = variants[variantName]

  const classNames = clsx(
    className,
    styles.variants[variant.variant],
    attachDirection !== undefined && styles.attachDirections[attachDirection],
  )

  return (
    <Box
      component="button"
      className={classNames}
      {...props}
      styles={{
        backgroundColor: variant.backgroundColor,
        color: variant.color,
        padding: variant.padding,
        overflow: 'hidden',
        borderRadius: '10em',
        width: variant.size,
        height: variant.size,
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center',
        ...props.styles,
      }}
    >
      {children ??
        (iconName && (
          <Icon
            name={iconName}
            {...iconProps}
            styles={{ width: '1rem', ...iconProps?.styles }}
          />
        ))}
    </Box>
  )
}
