import React from 'react'
import { Box } from '@walltowall/calico'
import { useStyles } from 'react-treat'

import { PageTemplateEnhancerProps } from '../templates/page'
import { BoundedBox } from '../components/BoundedBox'
import { GradientOverlay } from '../components/GradientOverlay'
import { Heading } from '../components/Heading'
import { Text } from '../components/Text'
import { Anchor } from '../components/Anchor'
import { ButtonLink } from '../components/ButtonLink'

import asset4040BgUrl from '../assets/404-bg.jpg'
import * as styleRefs from './PageLayoutLargeMessage.treat'

export type PageLayoutLargeMessageProps = PageTemplateEnhancerProps

export const PageLayoutLargeMessage = ({}: PageLayoutLargeMessageProps) => {
  const styles = useStyles(styleRefs)

  return (
    <BoundedBox
      component="section"
      className={styles.container}
      innerMaxWidth="large"
      styles={{
        display: 'flex',
        flexDirection: 'column',
        justifyContent: 'center',
        maxWidth: 'xlarge',
        marginRight: 'auto',
        marginLeft: 'auto',
        position: 'relative',
      }}
    >
      <Box
        styles={{
          position: ['relative', 'absolute'],
          marginLeft: [-4, 0],
          marginRight: [-4, 0],
          marginTop: [-10, 0],
          marginBottom: [10, 0],
          top: [null, 0],
          left: [null, 0],
          right: [null, 0],
          bottom: [null, 0],
          pointerEvents: 'none',
        }}
      >
        <Box
          component="img"
          className={styles.image}
          src={asset4040BgUrl}
          loading="lazy"
          style={{ objectFit: 'cover' }}
          styles={{
            width: 'full',
            height: 'full',
          }}
        />
        <GradientOverlay
          variant="whiteRight"
          styles={{
            position: 'absolute',
            top: 0,
            left: 0,
            bottom: 0,
            right: 0,
          }}
        />
      </Box>

      <Box
        styles={{
          position: 'relative',
          textAlign: ['center', 'right'],
          display: 'flex',
          flexDirection: 'column',
          alignItems: ['center', 'end'],
          maxWidth: 'small',
          marginLeft: 'auto',
        }}
      >
        <Heading
          variant="sansB"
          level={3}
          styles={{ marginBottom: 8, color: 'beige40' }}
        >
          Sorry. We can't find the page you're looking for.
        </Heading>
        <Text variant="sans-19-22" styles={{ marginBottom: 8 }}>
          To get back on track, start <Anchor href="/">here</Anchor>.
        </Text>
        <ButtonLink href="/" variant="red">
          Go To Homepage
        </ButtonLink>
      </Box>
    </BoundedBox>
  )
}

export default PageLayoutLargeMessage
