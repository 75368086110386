import * as React from 'react'
import { Box } from '@walltowall/calico'

import { PageLayoutDestinationBarProps } from '../PageLayoutDestinationBar'

import { BoundedBox } from '../../components/BoundedBox'
import { HTMLContent } from '../../components/HTMLContent'
import { ButtonLink } from '../../components/ButtonLink'
import GatsbyImage from 'gatsby-image/withIEPolyfill'

type SpecializedSeedCardsProps = PageLayoutDestinationBarProps

export const SpecializedSeedCards = ({
  bodyHTML,
  buttonText,
  buttonHref,
  buttonTarget,
  imageFluid,
  imageAlt,
  nextSharesBg,
  id,
}: SpecializedSeedCardsProps) => (
  <BoundedBox
    id={id}
    component="section"
    nextSharesBg={nextSharesBg}
    innerMaxWidth="none"
    variant="narrow"
    styles={{
      backgroundColor: 'white',
      color: 'brown20',
      marginRight: 'auto',
      marginLeft: 'auto',
      maxWidth: 'xlarge',
      position: 'relative',
    }}
  >
    <Box
      styles={{
        display: 'flex',
        alignItems: ['stretch', 'center'],
        flexDirection: ['column', 'row'],
      }}
    >
      <Box
        styles={{
          display: 'flex',
          justifyContent: 'end',
          width: ['full', '6/12'],
          marginRight: [0, 10, 16],
          marginBottom: [6, 0],
        }}
      >
        <Box
          styles={{
            maxWidth: ['none', '30rem'],
            width: 'full',
            display: 'flex',
            flexDirection: 'column',
            alignItems: 'start',
          }}
        >
          {bodyHTML && (
            <HTMLContent
              html={bodyHTML}
              componentOverrides={{
                h2: (Comp) => (props) => (
                  <Comp
                    level={2}
                    variant="sansC"
                    {...props}
                    styles={{
                      color: 'beige40',
                      marginTop: 0,
                      marginBottom: [6, 8],
                    }}
                  />
                ),
                h3: (Comp) => (props) => (
                  <Comp
                    level={3}
                    variant="sansCapsA"
                    {...props}
                    styles={{
                      marginTop: 0,
                      marginBottom: 4,
                    }}
                  />
                ),
                h4: (Comp) => (props) => (
                  <Comp
                    level={4}
                    {...props}
                    styles={{
                      color: 'fuschia30',
                      marginTop: 0,
                      marginBottom: 4,
                    }}
                  />
                ),
                p: (Comp) => (props) => (
                  <Comp
                    {...props}
                    styles={{
                      marginTop: 0,
                      marginBottom: [6, 8],
                    }}
                  />
                ),
              }}
              styles={{ maxWidth: ['none', '30rem'] }}
            />
          )}
          {buttonHref && (
            <Box styles={{ marginTop: [6, 8] }}>
              <ButtonLink variant="red" href={buttonHref} target={buttonTarget}>
                {buttonText}
              </ButtonLink>
            </Box>
          )}
        </Box>
      </Box>
      <Box
        styles={{
          width: ['auto', '6/12'],
          flexGrow: 1,
          marginTop: [0, -10, -13],
          marginBottom: [-10, -10, -13],
        }}
      >
        {imageFluid && <GatsbyImage fluid={imageFluid} alt={imageAlt} />}
      </Box>
    </Box>
  </BoundedBox>
)
