import * as React from 'react'
import { useStyles } from 'react-treat'
import { Box } from '@walltowall/calico'

import { PageLayoutDestinationBarProps } from '../PageLayoutDestinationBar'

import { BoundedBox } from '../../components/BoundedBox'
import { HTMLContent } from '../../components/HTMLContent'
import { ButtonLink } from '../../components/ButtonLink'

import * as styleRefs from './Basic.treat'

type BasicProps = {
  side: 'left' | 'right'
} & PageLayoutDestinationBarProps

export const Basic = ({
  bodyHTML,
  buttonText,
  buttonHref,
  buttonTarget,
  side,
  nextSharesBg,
  id,
}: BasicProps) => {
  const styles = useStyles(styleRefs)

  return (
    <BoundedBox
      id={id}
      component="section"
      nextSharesBg={nextSharesBg}
      className={styles.diamondBackground[side]}
      styles={{
        backgroundColor: 'white',
        color: 'brown20',
        marginRight: 'auto',
        marginLeft: 'auto',
        maxWidth: 'xlarge',
        position: 'relative',
      }}
    >
      <Box
        styles={{
          display: 'flex',
          alignItems: ['start', 'center'],
          flexDirection: 'column',
          justifyContent: 'center',
          position: 'relative',
          minHeight: ['none', '22rem'],
        }}
      >
        {bodyHTML && (
          <HTMLContent
            html={bodyHTML}
            styles={{
              textAlign: ['left', 'center'],
              maxWidth: 'small',
              width: 'full',
            }}
            componentOverrides={{
              h2: (Comp) => (props) => (
                <Comp
                  level={2}
                  variant="sansC"
                  {...props}
                  styles={{
                    color: 'beige40',
                    marginTop: 0,
                    marginBottom: [6, 8],
                  }}
                />
              ),
              h3: (Comp) => (props) => (
                <Comp
                  level={3}
                  variant="sansCapsA"
                  {...props}
                  styles={{
                    marginTop: 0,
                    marginBottom: 4,
                  }}
                />
              ),
              h4: (Comp) => (props) => (
                <Comp
                  level={4}
                  {...props}
                  styles={{
                    color: 'fuschia30',
                    marginTop: 0,
                    marginBottom: 4,
                  }}
                />
              ),
              p: (Comp) => (props) => (
                <Comp
                  {...props}
                  styles={{
                    marginTop: 0,
                    marginBottom: [6, 8],
                  }}
                />
              ),
            }}
          />
        )}
        {buttonHref && (
          <Box styles={{ marginTop: [6, 8] }}>
            <ButtonLink variant="red" href={buttonHref} target={buttonTarget}>
              {buttonText}
            </ButtonLink>
          </Box>
        )}
      </Box>
    </BoundedBox>
  )
}
