import * as React from 'react'
import { Box } from '@walltowall/calico'

import { PageLayoutDestinationBarProps } from '../PageLayoutDestinationBar'

import { BoundedBox } from '../../components/BoundedBox'
import { HTMLContent } from '../../components/HTMLContent'
import { ButtonLink } from '../../components/ButtonLink'
import { GatsbyImageContainer } from '../../components/GatsbyImageContainer'
import { GatsbyImageFaded } from '../../components/GatsbyImageFaded'

type ImageProps = {
  side: 'left' | 'right'
} & PageLayoutDestinationBarProps

export const Image = ({
  bodyHTML,
  buttonText,
  buttonHref,
  buttonTarget,
  imageFluid,
  imageAlt,
  side,
  nextSharesBg,
  id,
}: ImageProps) => (
  <BoundedBox
    id={id}
    component="section"
    nextSharesBg={nextSharesBg}
    innerMaxWidth="medium"
    styles={{
      backgroundColor: 'beige100',
      color: 'brown20',
      marginRight: 'auto',
      marginLeft: 'auto',
      maxWidth: 'xlarge',
      position: 'relative',
    }}
  >
    {imageFluid && (
      <GatsbyImageContainer
        styles={{
          position: 'absolute',
          top: 0,
          left: side === 'left' ? 0 : undefined,
          right: side === 'right' ? 0 : undefined,
          bottom: ['auto', 0],
          height: ['19rem', 'auto'],
          width: ['full', '9/12'],
        }}
      >
        <GatsbyImageFaded
          variant={side === 'left' ? 'beige100Right' : 'beige100Left'}
          fluid={imageFluid}
          alt={imageAlt}
          styles={{ height: 'full' }}
        />
      </GatsbyImageContainer>
    )}
    <Box
      styles={{
        display: 'flex',
        alignItems: ['start', side === 'left' ? 'end' : 'start'],
        flexDirection: 'column',
        justifyContent: 'center',
        position: 'relative',
        minHeight: ['none', '16rem', '22rem'],
      }}
    >
      <Box
        styles={{
          display: 'flex',
          flexDirection: 'column',
          alignItems: 'start',
          marginTop: ['15rem', 0],
          width: ['full', '6/12'],
        }}
      >
        {bodyHTML && (
          <HTMLContent
            html={bodyHTML}
            styles={{ maxWidth: 'small', width: 'full' }}
            componentOverrides={{
              h2: (Comp) => (props) => (
                <Comp
                  level={2}
                  variant="sansC"
                  {...props}
                  styles={{
                    color: 'beige40',
                    marginTop: 0,
                    marginBottom: [6, 8],
                  }}
                />
              ),
              h3: (Comp) => (props) => (
                <Comp
                  level={3}
                  variant="sansCapsA"
                  {...props}
                  styles={{
                    marginTop: 0,
                    marginBottom: 4,
                  }}
                />
              ),
              h4: (Comp) => (props) => (
                <Comp
                  level={4}
                  {...props}
                  styles={{
                    color: 'fuschia30',
                    marginTop: 0,
                    marginBottom: 4,
                  }}
                />
              ),
              p: (Comp) => (props) => (
                <Comp
                  {...props}
                  styles={{
                    marginTop: 0,
                    marginBottom: [6, 8],
                  }}
                />
              ),
            }}
          />
        )}
        {buttonHref && (
          <Box styles={{ marginTop: [6, 8] }}>
            <ButtonLink variant="red" href={buttonHref} target={buttonTarget}>
              {buttonText}
            </ButtonLink>
          </Box>
        )}
      </Box>
    </Box>
  </BoundedBox>
)
