/**
 * Collection of small helper functions for data management.
 * Each function in this file should contain a description of its usage and purpose.
 *
 * @remarks
 * Many common utility functions are available in the `@walltowall/helpers` package.
 *
 * @see https://github.com/WalltoWall/helpers
 */

/**
 * Given a string, capitalizes the first letter in every word.
 *
 * @param str The string to capitalize.
 * @returns The new capitalized string.
 */
export const titleize = (str: string) =>
  str.replace(
    /\w+/g,
    (a) => a.charAt(0).toUpperCase() + a.substr(1).toLowerCase(),
  )

/**
 * Casts the provided value as an array if it's not already one.
 *
 * @param value - The value to cast.
 *
 * @returns The casted array.
 */
export const castArray = <T>(input: T | T[]): T[] =>
  Array.isArray(input) ? input : [input]

/**
 * Prevents widows when rendering strings where lines can wrap.
 *
 * @param string The string to prevent widows.
 * @param wordCount The minumum number of words to include on the last line.
 *
 * @returns The string with built-in widow prevention.
 */
export const preventWidow = (string: string = '', wordCount = 2): string => {
  const words = string.split(' ')
  // The unicode representation is needed in order for it to render in React
  // correctly. Using `&nbsp;` prints that string literally.
  const nbsp = '\u00A0'

  return [
    ...words.slice(0, -wordCount),
    words.slice(words.length - wordCount).join(nbsp),
  ].join(' ')
}
