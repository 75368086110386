import * as React from 'react'
import { useStaticQuery, graphql } from 'gatsby'
import { useStyles } from 'react-treat'
import { Box } from '@walltowall/calico'
import { undefIfEmpty } from '@walltowall/helpers'

import { PageLayoutLocationsSearchClosuresQuery } from '../../graphqlTypes'

import { Heading } from '../../components/Heading'
import { BoundedBox } from '../../components/BoundedBox'
import { Text } from '../../components/Text'

import * as styleRefs from './Closures.treat'

export const Closures = () => {
  const styles = useStyles(styleRefs)

  const queryData = useStaticQuery<
    PageLayoutLocationsSearchClosuresQuery
  >(graphql`
    query PageLayoutLocationsSearchClosures {
      prismicSettings {
        data {
          closures_heading {
            text
          }
          closures {
            purpose
            date
          }
        }
      }
    }
  `)
  const closures = queryData.prismicSettings?.data?.closures

  return (
    <BoundedBox
      variant="narrow"
      innerMaxWidth="small"
      className={styles.diamondBackground}
      styles={{
        backgroundColor: 'beige100',
        color: 'brown20',
        maxWidth: 'xlarge',
        marginRight: 'auto',
        marginLeft: 'auto',
      }}
    >
      <Heading
        level={2}
        variant="sansD"
        styles={{
          color: 'beige40',
          textAlign: ['left', 'center'],
          marginBottom: [6, 8],
        }}
      >
        {queryData.prismicSettings?.data?.closures_heading?.text}
      </Heading>
      <Box component="ul" styles={{ columns: 2 }}>
        {undefIfEmpty(closures) &&
          closures?.map?.((closure) => (
            <Box
              key={closure?.date}
              component="li"
              styles={{
                breakInside: 'avoid',
                marginBottom: 2,
              }}
            >
              <Text
                variant="serif-14"
                styles={{ color: 'fuschia30', display: 'inlineBlock' }}
              >
                {closure?.date}
              </Text>
              <Text
                variant="sans-16"
                styles={{
                  display: 'inlineBlock',
                  marginRight: 1,
                  marginLeft: 1,
                }}
              >
                &ndash;
              </Text>
              <Text variant="sans-16" styles={{ display: 'inlineBlock' }}>
                {closure?.purpose}
              </Text>
            </Box>
          ))}
      </Box>
    </BoundedBox>
  )
}
