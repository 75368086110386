import React from 'react'
import { useStyles } from 'react-treat'
import { useField } from 'formik'
import { Box, BoxProps } from '@walltowall/calico'
import clsx from 'clsx'

import { PickRequired } from '../types'

import * as styleRefs from './FieldText.treat'

export type FieldTextProps = PickRequired<BoxProps, 'name'>

export const FieldText = ({
  name,
  type = 'text',
  ...props
}: FieldTextProps) => {
  const styles = useStyles(styleRefs)

  const [field, { error, touched }] = useField(name)
  const hasError = touched && error

  return (
    <Box
      component="input"
      type={type}
      className={clsx(styles.input, hasError && styles.errorPlaceholder)}
      {...field}
      {...props}
      styles={{
        display: 'block',
        backgroundColor: hasError ? 'maroon95' : 'white',
        borderColor: hasError ? 'maroon80' : 'beige80',
        borderRadius: '1.15rem',
        borderStyle: 'solid',
        borderWidth: '1px',
        color: 'brown0',
        flexGrow: 1,
        fontFamily: 'sans',
        paddingTop: 2,
        paddingBottom: 2,
        paddingLeft: 4,
        paddingRight: 4,
        outline: 'none',
        transitionDuration: 'normal',
        transitionProperty: 'borderColor',
        transitionTimingFunction: 'easeOut',
        width: 'full',
        maxWidth: 'full',
        ...props.styles,
      }}
      focusStyles={{
        borderColor: 'beige40',
      }}
    />
  )
}
