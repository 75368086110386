import React, { useRef } from 'react'
import { Box } from '@walltowall/calico'
import clsx from 'clsx'
import { useSpring, a } from 'react-spring'

import * as styleRefs from './CardImage.treat'

type AtvImageProps = {
  imgUrl: string
}

export const CardImage = ({ imgUrl }: AtvImageProps) => {
  const [{ xys }, setRotation] = useSpring(() => ({
    xys: [0, 0, 1],
    config: { mass: 5, tension: 350, friction: 40, precision: 0.00001 },
  }))
  const [{ shine }, setShine] = useSpring(() => ({
    shine: 90,
    config: { mass: 5, tension: 350, friction: 40, precision: 0.00001 },
    immediate: true,
  }))
  const ref = useRef<HTMLImageElement | null>(null)

  const onMouseMove = ({
    clientX,
    clientY,
  }: React.MouseEvent<HTMLImageElement, MouseEvent>) => {
    const rect = ref.current!.getBoundingClientRect()

    // The x-coord of the cursor relative to the element.
    const cursorElementX = clientX - rect.x
    // The y-coord of the cursor relative to the element.
    const cursorElementY = clientY - rect.y

    // Constant to reduce the amount of rotation.
    // Higher number => less rotation
    const dampen = 15

    const x = cursorElementX - rect.width / 2
    const y = cursorElementY - rect.height / 2
    const xys = [
      -y / dampen, // rotateX
      x / dampen, // rotateY
      1.07, // scale
    ]

    const centerX = rect.right - rect.width / 2
    const centerY = rect.bottom - rect.height / 2

    const dx = centerX - clientX
    const dy = centerY - clientY

    const radians = Math.atan2(dy, dx)
    const rawAngle = (radians * 180) / Math.PI
    const angle = rawAngle < 0 ? rawAngle + 360 : rawAngle

    setRotation({ xys })
    setShine({ shine: angle, immediate: true })
  }

  const onMouseLeave = () => {
    setRotation({ xys: [0, 0, 1] })
  }

  return (
    <Box
      styles={{
        position: 'relative',
        width: 'full',
        height: 'full',
      }}
    >
      <a.img
        src={imgUrl}
        onMouseMove={onMouseMove}
        onMouseLeave={onMouseLeave}
        ref={ref}
        style={{
          transform: xys.to(
            (x, y, s) =>
              `perspective(1000px) rotateX(${x}deg) rotateY(${y}deg) scale(${s})`,
          ),
        }}
        className={clsx(styleRefs.card, styleRefs.item)}
      />
      <a.div
        className={clsx(styleRefs.shine, styleRefs.item)}
        style={{
          background: shine.to(
            (shine) =>
              `linear-gradient(${shine}deg, rgba(255, 255, 255, 0.2) 0%, rgba(255, 255, 255, 0) 75%)`,
          ),
          transform: xys.to(
            (x, y, s) =>
              `perspective(1000px) rotateX(${x}deg) rotateY(${y}deg) scale(${s})`,
          ),
        }}
      />
    </Box>
  )
}
