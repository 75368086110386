import * as React from 'react'
import { Box } from '@walltowall/calico'
import GatsbyImage from 'gatsby-image/withIEPolyfill'

import { PageLayoutDestinationBarProps } from '../PageLayoutDestinationBar'

import { BoundedBox } from '../../components/BoundedBox'
import { HTMLContent } from '../../components/HTMLContent'
import { ButtonLink } from '../../components/ButtonLink'
import { GradientOverlay } from '../../components/GradientOverlay'

type SpecializedRetirementProps = PageLayoutDestinationBarProps

export const SpecializedRetirement = ({
  bodyHTML,
  buttonText,
  buttonHref,
  buttonTarget,
  imageFluid,
  imageAlt,
  nextSharesBg,
  id,
}: SpecializedRetirementProps) => (
  <BoundedBox
    id={id}
    component="section"
    nextSharesBg={nextSharesBg}
    innerMaxWidth="none"
    styles={{
      backgroundColor: 'beige100',
      color: 'brown20',
      marginRight: 'auto',
      marginLeft: 'auto',
      maxWidth: 'xlarge',
      position: 'relative',
    }}
  >
    <Box
      styles={{
        display: 'flex',
        alignItems: ['stretch', 'center'],
        flexDirection: ['columnReverse', 'row'],
        minHeight: ['none', '22rem'],
      }}
    >
      <Box
        styles={{
          position: ['static', 'absolute'],
          right: 0,
          bottom: 0,
          width: ['auto', '8/12'],
          marginLeft: [-4, 0],
          marginRight: [-4, 0],
          marginBottom: [-10, 0],
          marginTop: [5, 0],
        }}
      >
        {imageFluid && <GatsbyImage fluid={imageFluid} alt={imageAlt} />}
      </Box>
      <Box
        styles={{
          display: ['none', 'block'],
          position: 'absolute',
          right: 0,
          bottom: 0,
          top: 0,
          width: '8/12',
          overflow: 'hidden',
        }}
      >
        <GradientOverlay
          variant="beige100Left"
          styles={{
            width: ['full', '6/12'],
            position: 'absolute',
            top: 0,
            left: 0,
            bottom: 0,
          }}
        />
      </Box>
      <Box
        styles={{
          display: 'flex',
          justifyContent: 'end',
          width: ['full', '6/12'],
          position: 'relative',
        }}
      >
        <Box
          styles={{
            maxWidth: ['none', '30rem'],
            width: 'full',
            display: 'flex',
            flexDirection: 'column',
            alignItems: 'start',
          }}
        >
          {bodyHTML && (
            <HTMLContent
              html={bodyHTML}
              componentOverrides={{
                h2: (Comp) => (props) => (
                  <Comp
                    level={2}
                    variant="sansC"
                    {...props}
                    styles={{
                      color: 'beige40',
                      marginTop: 0,
                      marginBottom: [6, 8],
                    }}
                  />
                ),
                h3: (Comp) => (props) => (
                  <Comp
                    level={3}
                    variant="sansCapsA"
                    {...props}
                    styles={{
                      marginTop: 0,
                      marginBottom: 4,
                    }}
                  />
                ),
                h4: (Comp) => (props) => (
                  <Comp
                    level={4}
                    {...props}
                    styles={{
                      color: 'fuschia30',
                      marginTop: 0,
                      marginBottom: 4,
                    }}
                  />
                ),
                p: (Comp) => (props) => (
                  <Comp
                    {...props}
                    styles={{
                      marginTop: 0,
                      marginBottom: [6, 8],
                    }}
                  />
                ),
              }}
              styles={{ maxWidth: ['none', '30rem'] }}
            />
          )}
          {buttonHref && (
            <Box styles={{ marginTop: [6, 8] }}>
              <ButtonLink variant="red" href={buttonHref} target={buttonTarget}>
                {buttonText}
              </ButtonLink>
            </Box>
          )}
        </Box>
      </Box>
    </Box>
  </BoundedBox>
)
