import * as React from 'react'
import { graphql } from 'gatsby'
import { Box } from '@walltowall/calico'
import { getRichText } from '@walltowall/helpers'
import Expand from 'react-expand-animated'

import { PageLayoutCtaExpandableFragment } from '../graphqlTypes'
import { MapDataToPropsArgs } from '../types'
import { PageTemplateEnhancerProps } from '../templates/page'
import { useUtilStyles } from '../hooks/useUtilStyles'

import { BoundedBox } from '../components/BoundedBox'
import { HTMLContent } from '../components/HTMLContent'
import { StyledHTMLContent } from '../components/StyledHTMLContent'
import { GatsbyImageContainer } from '../components/GatsbyImageContainer'
import { GatsbyImageFaded } from '../components/GatsbyImageFaded'
import { ExpandCircle } from '../components/ExpandCircle'
import { Text } from '../components/Text'
import { ButtonLink } from '../components/ButtonLink'
import { Stack } from '../components/Stack'
import { Divider } from '../components/Divider'

export type PageLayoutCtaExpandableProps = Partial<
  ReturnType<typeof mapDataToProps>
> &
  PageTemplateEnhancerProps

export const PageLayoutCtaExpandable = ({
  children,
  nextSharesBg,
  bodyHTML,
  expandableBodyHTML,
  imageFluid,
  imageAlt,
  footnoteHTML,
  id,
}: PageLayoutCtaExpandableProps) => {
  const { lastNoMargin } = useUtilStyles()

  const [isExpanded, toggleExpanded] = React.useReducer(
    (state) => !state,
    false,
  )

  const hasChildren = React.Children.count(children) > 0

  return (
    <BoundedBox
      id={id}
      component="section"
      nextSharesBg={nextSharesBg}
      innerMaxWidth="medium"
      styles={{
        backgroundColor: 'beige100',
        maxWidth: 'xlarge',
        marginRight: 'auto',
        marginLeft: 'auto',
        color: 'brown20',
        position: 'relative',
      }}
    >
      {imageFluid && (
        <GatsbyImageContainer
          styles={{
            position: 'absolute',
            top: 0,
            right: 0,
            bottom: ['auto', 0],
            width: ['full', '9/12'],
            height: ['19rem', 'auto'],
          }}
        >
          <GatsbyImageFaded
            variant="beige100Left"
            fluid={imageFluid}
            alt={imageAlt}
            styles={{ height: 'full' }}
          />
        </GatsbyImageContainer>
      )}
      <Box
        styles={{
          display: 'flex',
          justifyContent: 'start',
          alignItems: 'center',
          minHeight: ['none', '16rem', '22rem'],
        }}
      >
        <Box
          styles={{
            position: 'relative',
            width: ['full', '6/12'],
            marginTop: ['15rem', 0],
          }}
        >
          <Stack space={[6, 7, 8]}>
            {bodyHTML && (
              <StyledHTMLContent html={bodyHTML} className={lastNoMargin} />
            )}
            {expandableBodyHTML && (
              <>
                <Expand open={isExpanded}>
                  <StyledHTMLContent
                    html={expandableBodyHTML}
                    styles={{ paddingBottom: [6, 7, 8] }}
                  />
                </Expand>

                <Box
                  component="button"
                  className={ExpandCircle.focusCaptureClassName}
                  onClick={toggleExpanded}
                  styles={{
                    display: 'inlineFlex',
                    alignItems: 'center',
                    color: 'fuschia30',
                    transitionProperty: 'color',
                    transitionDuration: 'normal',
                    transitionTimingFunction: 'easeOut',
                  }}
                  hoverStyles={{ color: 'maroon10' }}
                  focusStyles={{ color: 'maroon10' }}
                >
                  <ExpandCircle
                    isExpanded={isExpanded}
                    styles={{ marginRight: 2.5 }}
                  />
                  <Text>{isExpanded ? 'Less' : 'More'} Details</Text>
                </Box>
              </>
            )}

            {hasChildren && (
              <Box
                styles={{ display: ['block', 'flex'], flexDirection: 'row' }}
              >
                {children}
              </Box>
            )}

            {footnoteHTML && (
              <HTMLContent
                html={footnoteHTML}
                className={lastNoMargin}
                componentOverrides={{
                  p: (Comp) => ({ children, styles, ...props }) => (
                    <Comp
                      styles={{ ...styles, marginBottom: [3, 4, 5] }}
                      {...props}
                    >
                      <span style={{ fontSize: '.85em' }}>{children}</span>
                    </Comp>
                  ),
                }}
              />
            )}
          </Stack>
        </Box>
      </Box>
    </BoundedBox>
  )
}

export type PageLayoutCtaExpandableItemProps = {
  descriptionHTML?: string
  buttonText?: string
  buttonHref?: string
  buttonTarget?: string
}

PageLayoutCtaExpandable.Item = ({
  descriptionHTML,
  buttonText,
  buttonHref,
  buttonTarget,
}: PageLayoutCtaExpandableItemProps) => {
  const { hideIfFirstChild } = useUtilStyles()

  return (
    <>
      <Divider
        className={hideIfFirstChild}
        color="beige80"
        styles={{
          width: ['auto', '1px'],
          height: ['1px', 'auto'],
          marginTop: [6, -1],
          marginBottom: [6, -1],
          marginRight: [0, 8],
          marginLeft: [0, 8],
          alignSelf: 'stretch',
        }}
      />
      <Stack space={[5, 6]} align="start" styles={{ flex: 'equal0' }}>
        {descriptionHTML && <HTMLContent html={descriptionHTML} />}
        {buttonHref && (
          <ButtonLink
            variant="red"
            href={buttonHref}
            linkProps={{ target: buttonTarget }}
          >
            {buttonText}
          </ButtonLink>
        )}
      </Stack>
    </>
  )
}

export const mapDataToProps = ({
  data,
}: MapDataToPropsArgs<PageLayoutCtaExpandableFragment>) => ({
  bodyHTML: getRichText(data.primary?.body),
  expandableBodyHTML: getRichText(data.primary?.expandable_body),
  imageFluid: data.primary?.image?.fluid,
  imageAlt: data.primary?.image?.alt,
  footnoteHTML: getRichText(data.primary?.footnote),
  children: data.items?.map?.((item) => (
    <PageLayoutCtaExpandable.Item
      key={item?.button_link?.url}
      descriptionHTML={getRichText(item?.button_description1)}
      buttonText={item?.button_text}
      buttonHref={item?.button_link?.url}
      buttonTarget={item?.button_link?.target}
    />
  )),
})

export const mapDataToContext = () => ({
  bg: Symbol(),
})

export const query = graphql`
  fragment PageLayoutCtaExpandable on PrismicPageLayoutCtaExpandable {
    primary {
      body {
        text
        html
      }
      expandable_body {
        text
        html
      }
      footnote {
        text
        html
      }
      image {
        alt
        fluid(maxWidth: 1000) {
          ...GatsbyPrismicImageFluid
        }
      }
    }
    items {
      button_description1 {
        text
        html
      }
      button_text
      button_link {
        url
        target
      }
    }
  }
`

export default PageLayoutCtaExpandable
