import * as React from 'react'
import { graphql } from 'gatsby'
import { useStyles } from 'react-treat'
import { Box, BoxProps } from '@walltowall/calico'
import { getRichText } from '@walltowall/helpers'
import Expand from 'react-expand-animated'
import clsx from 'clsx'

import { PageLayoutFaqFragment } from '../graphqlTypes'
import { MapDataToPropsArgs } from '../types'
import { PageTemplateEnhancerProps } from '../templates/page'

import { BoundedBox } from '../components/BoundedBox'
import { Heading } from '../components/Heading'
import { Icon } from '../components/Icon'
import { StyledHTMLContent } from '../components/StyledHTMLContent'
import { Text } from '../components/Text'
import { Divider } from '../components/Divider'

import * as styleRefs from './PageLayoutFaq.treat'

type QuestionProps = {
  question?: string
  answerHTML?: string
}

const Question = ({ question, answerHTML }: QuestionProps) => {
  const styles = useStyles(styleRefs)

  const [isOpen, toggleIsOpen] = React.useReducer((state) => !state, false)

  return (
    <Box
      component="li"
      className={styles.hideLastBorder}
      styles={{
        display: 'flex',
        flexDirection: 'column',
        borderStyle: 'solid',
        borderColor: 'beige80',
        borderWidth: 'none',
        borderBottomWidth: '1px',
        paddingTop: [5, 6, 8],
        paddingBottom: [5, 6, 8],
      }}
    >
      <Box
        component="button"
        onClick={toggleIsOpen}
        styles={{
          paddingTop: 1,
          paddingBottom: 1,
          marginTop: -1,
          marginBottom: -1,
        }}
      >
        <Text
          component="p"
          variant="sans-18-20"
          styles={{
            color: 'fuschia30',
          }}
        >
          {question}
        </Text>
      </Box>
      <Expand open={isOpen}>
        <StyledHTMLContent html={answerHTML} styles={{ paddingTop: [5, 6] }} />
      </Expand>
    </Box>
  )
}

type MobileProps = {
  categories: PageLayoutFaqProps['categories']
} & BoxProps

export const Mobile = ({ categories = [], ...props }: MobileProps) => {
  const styles = useStyles(styleRefs)

  const [activeCategoryIndex, setActiveCategoryIndex] = React.useState<
    number | null
  >(null)
  const toggleOrSetCategoryIndex = (index: number) =>
    setActiveCategoryIndex(activeCategoryIndex === index ? null : index)

  const hasMultipleCategories = categories.length > 1

  return hasMultipleCategories ? (
    <Box
      component="ul"
      {...props}
      styles={{ marginTop: -6, marginBottom: -6, ...props.styles }}
    >
      {categories.map((category, i) => {
        const isActiveCategory = activeCategoryIndex === i

        return (
          <Box
            key={category.name}
            component="li"
            className={styles.hideLastBorder}
            styles={{
              display: 'flex',
              flexDirection: 'column',
              borderStyle: 'solid',
              borderColor: 'beige80',
              borderWidth: 'none',
              borderBottomWidth: '1px',
            }}
          >
            <Box
              component="button"
              className={styles.expandButton}
              onClick={() => toggleOrSetCategoryIndex(i)}
              styles={{
                display: 'flex',
                alignItems: 'center',
                justifyContent: 'spaceBetween',
                paddingTop: 6,
                paddingBottom: 6,
              }}
            >
              <Heading
                level={3}
                variant="sansD"
                styles={{
                  color: isActiveCategory ? 'fuschia30' : 'beige40',
                  marginRight: 4,
                  pointerEvents: 'none',
                  transitionDuration: 'normal',
                  transitionProperty: 'color',
                  transitionTimingFunction: 'easeOut',
                }}
              >
                {category.name}
              </Heading>

              <Box
                className={styles.expandIconCircle}
                styles={{
                  display: 'flex',
                  alignItems: 'center',
                  justifyContent: 'center',
                  flexShrink: 0,
                  width: '1.5rem',
                  height: '1.5rem',
                  backgroundColor: 'maroon30',
                  marginRight: 2.5,
                }}
              >
                <Icon
                  name="chevronDown"
                  className={clsx(
                    styles.expandIcon,
                    isActiveCategory && styles.expandIconRotated,
                  )}
                  styles={{
                    width: '0.75rem',
                    color: 'white',
                    transitionDuration: 'normal',
                  }}
                />
              </Box>
            </Box>
            <Expand open={isActiveCategory}>
              <Box
                component="ul"
                styles={{
                  marginTop: -5,
                  marginBottom: -5,
                  paddingBottom: 6,
                }}
              >
                {category.items?.map?.((item) => (
                  <Question
                    key={item?.question}
                    question={item?.question}
                    answerHTML={item?.answerHTML}
                  />
                ))}
              </Box>
            </Expand>
          </Box>
        )
      })}
    </Box>
  ) : (
    <Box
      component="ul"
      {...props}
      styles={{
        marginTop: -5,
        marginBottom: -5,
        ...props.styles,
      }}
    >
      {categories[0]?.items?.map?.((item) => (
        <Question
          key={item?.question}
          question={item?.question}
          answerHTML={item?.answerHTML}
        />
      ))}
    </Box>
  )
}

type DesktopProps = {
  categories: PageLayoutFaqProps['categories']
} & BoxProps

export const Desktop = ({ categories = [], ...props }: DesktopProps) => {
  const [activeCategoryIndex, setActiveCategoryIndex] = React.useState(0)
  const activeCategory = categories[activeCategoryIndex]
  const hasMultipleCategories = categories.length > 1

  return (
    <Box
      {...props}
      styles={{
        display: 'flex',
        ...props.styles,
      }}
    >
      {hasMultipleCategories && (
        <Box
          styles={{
            width: '3/12',
            flexShrink: 0,
            marginRight: 6,
          }}
        >
          <Heading level={2} variant="sansD" styles={{ color: 'beige40' }}>
            Categories
          </Heading>
          <Divider color="beige80" styles={{ marginTop: 6, marginBottom: 6 }} />
          <Box
            component="ul"
            styles={{
              marginTop: -3,
              marginBottom: -3,
            }}
          >
            {categories.map((category, i) => {
              const isActiveCategory = activeCategoryIndex === i

              return (
                <Box
                  key={category.name}
                  component="li"
                  styles={{
                    display: 'flex',
                    flexDirection: 'column',
                  }}
                >
                  <Box
                    component="button"
                    onClick={() => setActiveCategoryIndex(i)}
                    styles={{
                      display: 'flex',
                      alignItems: 'center',
                      paddingTop: 3,
                      paddingBottom: 3,
                      color: isActiveCategory ? 'fuschia30' : 'beige40',
                      transitionProperty: 'color',
                      transitionDuration: 'normal',
                      transitionTimingFunction: 'easeOut',
                    }}
                    hoverStyles={{
                      color: 'fuschia30',
                    }}
                  >
                    <Text
                      component="p"
                      variant="sans-19-22"
                      styles={{
                        marginRight: 3,
                      }}
                    >
                      {category.name}
                    </Text>
                    <Icon
                      name="chevronRightLarge"
                      styles={{
                        width: '0.5rem',
                        opacity: isActiveCategory ? 100 : 0,
                        transitionProperty: 'opacity',
                        transitionDuration: 'normal',
                        transitionTimingFunction: 'easeOut',
                      }}
                    />
                  </Box>
                </Box>
              )
            })}
          </Box>
        </Box>
      )}
      <Box styles={{ flexGrow: 1, width: 'full' }}>
        <Heading level={3} variant="sansD" styles={{ color: 'beige40' }}>
          Questions
        </Heading>
        <Divider color="beige80" styles={{ marginTop: 6, marginBottom: 8 }} />
        <Box
          component="ul"
          styles={{
            marginTop: [-5, -6, -8],
            marginBottom: [-5, -6, -8],
          }}
        >
          {activeCategory.items?.map?.((item) => (
            <Question
              key={item?.question}
              question={item?.question}
              answerHTML={item?.answerHTML}
            />
          ))}
        </Box>
      </Box>
    </Box>
  )
}

export type PageLayoutFaqProps = ReturnType<typeof mapDataToProps> &
  PageTemplateEnhancerProps

export const PageLayoutFaq = ({
  categories = [],
  nextSharesBg,
  id,
}: PageLayoutFaqProps) => (
  <BoundedBox
    id={id}
    component="section"
    nextSharesBg={nextSharesBg}
    innerMaxWidth={categories.length > 1 ? 'medium' : 'small'}
    styles={{
      backgroundColor: 'white',
      maxWidth: 'xlarge',
      marginRight: 'auto',
      marginLeft: 'auto',
      display: 'flex',
      flexDirection: ['column', 'row'],
    }}
  >
    <Mobile categories={categories} styles={{ display: ['block', 'none'] }} />
    <Desktop categories={categories} styles={{ display: ['none', 'flex'] }} />
  </BoundedBox>
)

export const mapDataToProps = ({
  data,
}: MapDataToPropsArgs<PageLayoutFaqFragment>) => ({
  categories: data.primary?.faq_collection?.document?.data?.body?.map?.(
    (category) => ({
      name: category?.primary?.name?.text,
      items: category?.items?.map?.((item) => ({
        question: item?.question,
        answerHTML: getRichText(item?.answer),
      })),
    }),
  ),
})

export const mapDataToContext = () => ({
  bg: 'white',
})

export const query = graphql`
  fragment PageLayoutFaq on PrismicPageLayoutFaq {
    primary {
      faq_collection {
        document {
          ... on PrismicFaqCollection {
            data {
              body {
                ... on PrismicFaqCollectionBodyCategory {
                  primary {
                    name {
                      text
                    }
                  }
                  items {
                    question
                    answer {
                      text
                      html
                    }
                  }
                }
              }
            }
          }
        }
      }
    }
  }
`

export default PageLayoutFaq
