import * as React from 'react'
import { Box, useBoxStyles } from '@walltowall/calico'

import { useFadedDiamondBackground } from '../../hooks/useFadedDiamondBackground'
import { PageLayoutDestinationBarProps } from '../PageLayoutDestinationBar'

import { BoundedBox } from '../../components/BoundedBox'
import { HTMLContent } from '../../components/HTMLContent'
import { ButtonLink } from '../../components/ButtonLink'
import GatsbyImage from 'gatsby-image/withIEPolyfill'

type SpecializedPersonalServicesProps = PageLayoutDestinationBarProps

export const SpecializedPersonalServices = ({
  bodyHTML,
  buttonText,
  buttonHref,
  buttonTarget,
  imageFluid,
  imageAlt,
  nextSharesBg,
  id,
}: SpecializedPersonalServicesProps) => {
  const backgroundClassName = useFadedDiamondBackground('beige100', 'left')

  return (
    <BoundedBox
      id={id}
      component="section"
      nextSharesBg={nextSharesBg}
      innerMaxWidth="none"
      className={backgroundClassName}
      styles={{
        backgroundColor: 'beige100',
        color: 'brown20',
        marginRight: 'auto',
        marginLeft: 'auto',
        maxWidth: 'xlarge',
        position: 'relative',
      }}
    >
      <Box
        styles={{
          display: 'flex',
          alignItems: ['stretch', null, 'center'],
          flexDirection: ['column', 'rowReverse'],
        }}
      >
        <Box
          styles={{
            width: ['auto', '6/12'],
            flexGrow: 1,
            marginRight: [-4, -10, -16],
            marginLeft: [-4, 0],
            marginTop: [-10, -15, -20],
            marginBottom: [8, -15, -20],
          }}
        >
          {imageFluid && (
            <GatsbyImage
              fluid={imageFluid}
              alt={imageAlt}
              className={useBoxStyles({ height: 'full' })}
            />
          )}
        </Box>
        <Box
          styles={{
            display: 'flex',
            justifyContent: 'end',
            width: ['full', '6/12'],
            marginRight: [0, 10, 16],
          }}
        >
          <Box
            styles={{
              maxWidth: ['none', '30rem'],
              width: 'full',
              display: 'flex',
              flexDirection: 'column',
              alignItems: 'start',
            }}
          >
            {bodyHTML && (
              <HTMLContent
                html={bodyHTML}
                componentOverrides={{
                  h2: (Comp) => (props) => (
                    <Comp
                      level={2}
                      variant="sansC"
                      {...props}
                      styles={{
                        color: 'beige40',
                        marginTop: 0,
                        marginBottom: [6, 8],
                      }}
                    />
                  ),
                  h3: (Comp) => (props) => (
                    <Comp
                      level={3}
                      variant="sansCapsA"
                      {...props}
                      styles={{
                        marginTop: 0,
                        marginBottom: 4,
                      }}
                    />
                  ),
                  h4: (Comp) => (props) => (
                    <Comp
                      level={4}
                      {...props}
                      styles={{
                        color: 'fuschia30',
                        marginTop: 0,
                        marginBottom: 4,
                      }}
                    />
                  ),
                  p: (Comp) => (props) => (
                    <Comp
                      {...props}
                      styles={{
                        marginTop: 0,
                        marginBottom: [6, 8],
                      }}
                    />
                  ),
                }}
                styles={{ maxWidth: 'small' }}
              />
            )}
            {buttonHref && (
              <Box styles={{ marginTop: [6, 8] }}>
                <ButtonLink
                  variant="red"
                  href={buttonHref}
                  target={buttonTarget}
                >
                  {buttonText}
                </ButtonLink>
              </Box>
            )}
          </Box>
        </Box>
      </Box>
    </BoundedBox>
  )
}
