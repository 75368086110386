export var ie11Hacks = '_11EPr';
export var gradientBackground = '$_2vBio';
export var shadow = '$ZN6Uc';
export var translateBar = '_1yWP5';
export var translateLogoIcon = '_1xSmF';
export var translateLogoIconMore = '_39UHc';
export var transformMaxWidthMarginLeft = '_2yFRs';
export var transformMaxWidthTransform = '_17ujb';
export var placeholderColor = '$_1yqrw';
export var inputWidth = 'kEwXU';
export var iconTranslate = '_1iRQx';
export var childrenTransitionProperties = 'J6Z_G';
export var primaryNavListItemFocusCapture = '_284aZ';
export var primaryNavListItemIndicator = '_3wikR';
export var primaryNavListItemChildren = '$_1Axbk';
export var primaryNavListChildItemTransitionProperty = '_1PnRs';