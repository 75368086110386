import React from 'react'
import { Box } from '@walltowall/calico'
import VisuallyHidden from '@reach/visually-hidden'

import { useSiteSettings } from '../hooks/useSiteSettings'
import { PageTemplateEnhancerProps } from '../templates/page'

import { BoundedBox } from '../components/BoundedBox'
import { Divider } from '../components/Divider'
import { Heading } from '../components/Heading'
import { Inline } from '../components/Inline'
import { Link, LinkProps } from '../components/Link'
import { Icon, IconProps } from '../components/Icon'
import { ButtonLink } from '../components/ButtonLink'

type IconLinkProps = {
  href: LinkProps['href']
  iconName: IconProps['name']
  label: string
}

const IconLink = ({ href, iconName, label }: IconLinkProps) => (
  <Link href={href}>
    <VisuallyHidden>{label}</VisuallyHidden>
    <Icon name={iconName} styles={{ width: ['2rem', '2.25rem'] }} />
  </Link>
)

export type PageLayoutSocialWayfindingProps = PageTemplateEnhancerProps

export const PageLayoutSocialWayfinding = ({
  nextSharesBg,
  id,
}: PageLayoutSocialWayfindingProps) => {
  const settings = useSiteSettings()

  return (
    <BoundedBox
      id={id}
      component="section"
      nextSharesBg={nextSharesBg}
      styles={{
        backgroundColor: 'white',
        maxWidth: 'xlarge',
        marginRight: 'auto',
        marginLeft: 'auto',
      }}
    >
      <Box
        styles={{
          display: 'flex',
          flexDirection: ['column', 'row'],
          alignItems: ['stretch', 'center'],
          minHeight: ['none', '16rem'],
        }}
      >
        <Box styles={{ flexGrow: 1, flexShrink: 1, flexBasis: 0 }}>
          <Heading
            level={2}
            variant="sansB"
            styles={{
              color: 'beige40',
              textAlign: ['left', 'center'],
              marginBottom: [7, 9],
            }}
          >
            Questions?
          </Heading>
          <Inline variant="list" space={4} align={['start', 'center']}>
            <ButtonLink variant="red" href="/faq/">
              FAQ
            </ButtonLink>
            <ButtonLink variant="red" href="/contact/">
              Contact Us
            </ButtonLink>
          </Inline>
        </Box>
        <Divider
          color="beige80"
          styles={{
            alignSelf: 'stretch',
            width: ['auto', '1px'],
            height: ['1px', 'auto'],
            marginRight: [0, 8],
            marginLeft: [0, 8],
            marginTop: [10, -8],
            marginBottom: [10, -8],
          }}
        />
        <Box styles={{ flexGrow: 1, flexShrink: 1, flexBasis: 0 }}>
          <Heading
            level={2}
            variant="sansB"
            styles={{
              color: 'beige40',
              textAlign: ['left', 'center'],
              marginBottom: [7, 9],
            }}
          >
            Follow Us
          </Heading>
          <Inline variant="list" space={4} align={['start', 'center']}>
            <IconLink
              href={`https://facebook.com/${settings?.facebookHandle}`}
              iconName="facebookSquareGradient"
              label="Facebook"
            />
            <IconLink
              href={`https://instagram.com/${settings?.instagramHandle}`}
              iconName="instagramSquareGradient"
              label="Instagram"
            />
            <IconLink
              href={`https://linkedin.com/company/${settings?.linkedinHandle}`}
              iconName="linkedinSquareGradient"
              label="LinkedIn"
            />
            <IconLink
              href={`https://twitter.com/${settings?.twitterHandle}`}
              iconName="twitterSquareGradient"
              label="Twitter"
            />
          </Inline>
        </Box>
      </Box>
    </BoundedBox>
  )
}

export const mapDataToContext = () => ({
  bg: 'white',
})

export default PageLayoutSocialWayfinding
