import React from 'react'
import { useStyles } from 'react-treat'
import { useField } from 'formik'
import { Box, BoxProps } from '@walltowall/calico'

import { PickRequired } from '../types'

import { Text } from './Text'

import * as styleRefs from './FieldRadio.treat'

export type FieldRadioProps = PickRequired<BoxProps, 'name'>

export const FieldRadio = ({
  name,
  value,
  label,
  ...props
}: FieldRadioProps) => {
  const styles = useStyles(styleRefs)

  const [field] = useField({
    name,
    value,
    type: 'radio',
  })

  return (
    <Box
      component="label"
      styles={{ display: 'inlineFlex', alignItems: 'center' }}
    >
      <Box
        component="input"
        type="radio"
        className={styles.input}
        {...field}
        {...props}
        styles={{
          display: 'block',
          width: '1rem',
          height: '1rem',
          backgroundColor: 'white',
          borderColor: 'beige80',
          borderStyle: 'solid',
          borderWidth: '1px',
          transitionDuration: 'normal',
          transitionProperty: 'borderColor',
          transitionTimingFunction: 'easeOut',
          borderRadius: '50%',
          ...props.styles,
        }}
        focusStyles={{
          borderColor: 'beige40',
        }}
      />
      {label && (
        <Text
          component="span"
          styles={{
            display: 'block',
            fontWeight: 'medium',
            marginLeft: 2,
            color: 'brown20',
          }}
        >
          {label}
        </Text>
      )}
    </Box>
  )
}
