import React from 'react'
import {
  Box,
  BoxProps,
  ResponsiveProp,
  normalizeResponsiveProp,
} from '@walltowall/calico'
import * as RA from 'fp-ts/es6/ReadonlyArray'

const variants = {
  extraNarrow: {
    paddingLeft: [4, 10, 16],
    paddingRight: [4, 10, 16],
    paddingTop: [6, 7, 8],
    paddingBottom: [6, 7, 8],
  },
  narrow: {
    paddingLeft: [4, 10, 16],
    paddingRight: [4, 10, 16],
    paddingTop: [10, 10, 13],
    paddingBottom: [10, 10, 13],
  },
  base: {
    paddingLeft: [4, 10, 16],
    paddingRight: [4, 10, 16],
    paddingTop: [10, 15, 20],
    paddingBottom: [10, 15, 20],
  },
  wide: {
    paddingLeft: [4, 10, 16],
    paddingRight: [4, 10, 16],
    paddingTop: [10, 18, 26],
    paddingBottom: [10, 18, 26],
  },
} as const

type BoundedBoxProps = {
  children?: React.ReactNode
  variant?: keyof typeof variants
  innerMaxWidth?: NonNullable<BoxProps['styles']>['maxWidth']
  nextSharesBg?: ResponsiveProp<boolean>
} & BoxProps

const _BoundedBox = (
  {
    children,
    variant: variantName = 'base',
    innerMaxWidth,
    nextSharesBg = false,
    ...props
  }: BoundedBoxProps,
  ref: React.Ref<HTMLDivElement>,
) => {
  const variant = variants[variantName]

  const resolvedPaddingBottom = RA.zipWith(
    normalizeResponsiveProp(nextSharesBg),
    normalizeResponsiveProp(variant.paddingBottom),
    (a, b) => (a ? 0 : b),
  ) as NonNullable<BoxProps['styles']>['paddingBottom']

  return (
    <Box
      ref={ref}
      {...props}
      styles={{
        paddingLeft: variant.paddingLeft,
        paddingRight: variant.paddingRight,
        paddingTop: variant.paddingTop,
        paddingBottom: resolvedPaddingBottom,
        ...props.styles,
      }}
    >
      <Box
        styles={{
          maxWidth: innerMaxWidth,
          width: 'full',
          marginLeft: 'auto',
          marginRight: 'auto',
          height: 'full',
          display: 'flex',
          flexDirection: 'column',
        }}
      >
        {children}
      </Box>
    </Box>
  )
}

export const BoundedBox = React.forwardRef(_BoundedBox)
