import * as React from 'react'
import { navigate } from 'gatsby'
import { useStyles } from 'react-treat'
import { Box } from '@walltowall/calico'
import { Formik, Form as FormikForm, Field as FormikField } from 'formik'
import { isBrowser } from '@walltowall/helpers'
import VisuallyHidden from '@reach/visually-hidden'
import querystring from 'querystring'

import { Heading } from '../../components/Heading'
import { BoundedBox } from '../../components/BoundedBox'
import { Text } from '../../components/Text'
import { ButtonIcon } from '../../components/ButtonIcon'
import { Icon } from '../../components/Icon'
import { Stack } from '../../components/Stack'

import * as styleRefs from './SearchBar.treat'

export const SearchBar = () => {
  const styles = useStyles(styleRefs)

  const query = isBrowser
    ? (querystring.decode(location.search.slice(1)).query as string)
    : ''

  const navigateWithQuery = (values: { query: string }) => {
    const path = values.query
      ? `/locations/?${querystring.encode({ query: values.query })}`
      : '/locations/'
    navigate(path)
  }

  return (
    <BoundedBox
      variant="wide"
      className={styles.backgroundImage}
      styles={{
        backgroundColor: 'beige40',
        backgroundSize: 'cover',
        backgroundPositionX: 'center',
        backgroundPositionY: 'center',
        color: 'white',
        position: 'relative',
        maxWidth: 'xlarge',
        marginRight: 'auto',
        marginLeft: 'auto',
      }}
    >
      <Stack space={[6, 7]} align={['start', 'center']}>
        <Heading level={2} variant="sansB">
          Locations
        </Heading>
        <Text variant="sans-18-20">Search by Zip Code, City, or Address</Text>
        <Formik initialValues={{ query }} onSubmit={navigateWithQuery}>
          <FormikForm className={styles.formContent}>
            <Box styles={{ display: 'flex' }}>
              <VisuallyHidden>
                <label htmlFor="location-query">Search</label>
              </VisuallyHidden>
              <FormikField
                as={(props: object) => (
                  <Box
                    component="input"
                    id="location-query"
                    placeholder="Search&hellip;"
                    className={styles.input}
                    styles={{
                      backgroundColor: 'white',
                      color: 'brown20',
                      flexGrow: 1,
                      paddingRight: 5,
                      paddingLeft: 5,
                      paddingTop: 3,
                      paddingBottom: 3,
                      fontFamily: 'sans',
                    }}
                    {...props}
                  />
                )}
                name="query"
              />
              <ButtonIcon variant="red" attachDirection="left">
                <VisuallyHidden>Submit locations search</VisuallyHidden>
                <Box className={styles.icon}>
                  <Icon name="search" />
                </Box>
              </ButtonIcon>
            </Box>
          </FormikForm>
        </Formik>
      </Stack>
    </BoundedBox>
  )
}
