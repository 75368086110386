import * as React from 'react'
import { Box } from '@walltowall/calico'
import { InteractiveMap, NavigationControl } from 'react-map-gl'
import { isBrowser } from '@walltowall/helpers'

import { MAPBOX_TOKEN, MAPBOX_STYLE_URL } from '../../constants'
import { HNBLocation } from '../PageLayoutLocationsSearch'

import { Button } from '../../components/Button'
import { Icon } from '../../components/Icon'
import { MapMarker } from './MapMarker'
import { MapPopup } from './MapPopup'

import * as styleRefs from './Map.treat'

export type Viewport = {
  latitude: number
  longitude: number
  zoom: number
}

type MapProps = {
  locations: HNBLocation[]
  searchResultsRef: React.RefObject<HTMLDivElement>
}

export const Map = ({ locations, searchResultsRef }: MapProps) => {
  const [viewport, setViewport] = React.useState<Viewport>({
    latitude: 20.612976478182087,
    longitude: -157.4024233706083,
    zoom: 7,
  })
  const [popupLocation, setPopupLocation] = React.useState<
    HNBLocation | undefined
  >()

  const closePopup = () => Boolean(popupLocation) && setPopupLocation(undefined)

  const scrollToSearchResults = () => {
    if (!isBrowser) return

    const el = searchResultsRef.current
    if (!el) return

    const rect = el.getBoundingClientRect()

    window.scrollTo({
      behavior: 'smooth',
      top: window.pageYOffset + rect.top - window.innerHeight / 4,
    })
  }

  return (
    <Box
      className={styleRefs.mapHeight}
      styles={{
        maxWidth: 'xlarge',
        marginRight: 'auto',
        marginLeft: 'auto',
        backgroundColor: 'white',
        position: 'relative',
      }}
    >
      <InteractiveMap
        {...viewport}
        mapStyle={MAPBOX_STYLE_URL}
        mapboxApiAccessToken={MAPBOX_TOKEN}
        onViewportChange={setViewport}
        width="100%"
        height="100%"
        onNativeClick={closePopup}
        onInteractionStateChange={closePopup}
        scrollZoom={false}
      >
        <Box
          styles={{
            position: 'absolute',
            right: 0,
            top: 0,
            paddingTop: [4, 6],
            paddingRight: [4, 6],
          }}
        >
          <NavigationControl />
        </Box>

        {locations.map((loc, idx) => {
          const latitude = loc?.data?.geopoint?.latitude
          const longitude = loc?.data?.geopoint?.longitude

          const onClick = () => {
            setPopupLocation(loc)

            setViewport((p) => ({
              zoom: p.zoom < 12 ? 12 : p.zoom,
              longitude: longitude!,
              latitude: latitude!,
            }))
          }

          return (
            longitude !== undefined &&
            longitude !== null &&
            latitude !== undefined &&
            latitude !== null && (
              <MapMarker
                key={loc?.id ?? idx}
                latitude={latitude}
                longitude={longitude}
                viewportZoom={viewport.zoom}
                onClick={onClick}
              />
            )
          )
        })}

        {popupLocation && (
          <MapPopup closePopup={closePopup} location={popupLocation} />
        )}
      </InteractiveMap>
      <Box
        className={styleRefs.listButtonPosition}
        styles={{
          position: 'absolute',
          bottom: 0,
          paddingBottom: [4, 6],
        }}
      >
        <Button variant="redSmall" onClick={scrollToSearchResults}>
          <Box styles={{ display: 'flex', alignItems: 'center' }}>
            <Icon
              name="chevronDown"
              styles={{ width: '0.5rem', marginRight: 2, marginLeft: -1 }}
            />
            View list
          </Box>
        </Button>
      </Box>
    </Box>
  )
}
