import * as React from 'react'
import { useStyles } from 'react-treat'
import { Box } from '@walltowall/calico'

import { useSiteSettings } from '../../hooks/useSiteSettings'

import { BoundedBox } from '../../components/BoundedBox'
import { Heading } from '../../components/Heading'
import { ButtonLink } from '../../components/ButtonLink'
import { Button } from '../../components/Button'
import { Text } from '../../components/Text'
import { Link } from '../../components/Link'

import * as styleRefs from './SignInBar.treat'
import * as inputStylerefs from '../../components/FieldText.treat'
import clsx from 'clsx'

type SignInBarProps = {
  showMobileSignInButton?: boolean
}

export const SignInBar = ({
  showMobileSignInButton = true,
}: SignInBarProps) => {
  const styles = useStyles(styleRefs)
  const inputStyles = useStyles(inputStylerefs)
  const siteSettings = useSiteSettings()

  return (
    <BoundedBox
      innerMaxWidth="large"
      className={styles.shadow}
      styles={{
        backgroundColor: 'beige100',
        position: 'relative',
        zIndex: 2,
        paddingTop: [8, 6],
        paddingBottom: [10, 6],
        paddingLeft: [4, 5, 16],
        paddingRight: [4, 5, 16],
      }}
    >
      <Box
        className={styles.diamondBackground}
        styles={{
          position: 'absolute',
          top: 0,
          left: 0,
          right: 0,
          bottom: 0,
          opacity: 15,
          pointerEvents: 'none',
        }}
      />
      {siteSettings.ebankingUrl && (
        <Box
          className={styles.translateY50}
          styles={{
            display: ['block', 'none'],
            position: 'absolute',
            bottom: 0,
            transitionProperty: 'opacity',
            transitionDuration: 'normal',
            transitionTimingFunction: 'easeOut',
            pointerEvents: showMobileSignInButton ? 'auto' : 'none',
            opacity: showMobileSignInButton ? 100 : 0,
          }}
        >
          <ButtonLink
            variant="white"
            href={siteSettings.ebankingUrl}
            className={styles.shadow}
          >
            Sign In
          </ButtonLink>
        </Box>
      )}
      <Box
        styles={{
          display: 'flex',
          alignItems: 'center',
          justifyContent: 'spaceBetween',
        }}
      >
        <Heading
          level={2}
          variant="sansD"
          styles={{ color: 'beige40', position: 'relative' }}
        >
          Welcome
        </Heading>

        <Box
          component="form"
          method="POST"
          action="https://accounts.hawaiinational.bank/login"
          target="_blank"
          autoComplete="off"
          styles={{
            display: ['none', 'block'],
            position: 'relative',
          }}
        >
          <Box component="label" styles={{ display: 'flex' }}>
            <Box
              component="input"
              type="text"
              name="username"
              placeholder="Username"
              className={clsx(inputStyles.input)}
              autoComplete="off"
              required
              style={{ minWidth: '13.75rem' }}
              styles={{
                display: 'block',
                backgroundColor: 'white',
                borderColor: 'beige80',
                borderRadius: '1.15rem',
                borderStyle: 'solid',
                borderWidth: '1px',
                color: 'brown0',
                flexGrow: 1,
                fontFamily: 'sans',
                paddingTop: 2,
                paddingBottom: 2,
                paddingLeft: 4,
                paddingRight: 4,
                outline: 'none',
                transitionDuration: 'normal',
                transitionProperty: 'borderColor',
                transitionTimingFunction: 'easeOut',
                width: 'full',
                maxWidth: 'full',
              }}
              focusStyles={{
                borderColor: 'beige40',
              }}
            />

            <Button
              variant="red"
              styles={{ marginLeft: 3 }}
              style={{
                boxShadow:
                  '0 2px 4px rgba(66,49,50,0.25), 0 1px 0 rgba(66,49,50,0.1)',
              }}
            >
              Sign In
            </Button>
          </Box>

          <Box
            styles={{
              paddingLeft: 4,
              paddingRight: 4,
              marginTop: 2,
              display: 'flex',
              justifyContent: 'spaceBetween',
            }}
          >
            <Text variant="sans-12">
              <Link
                href="https://accounts.hawaiinational.bank/forgot"
                skipExternalCheck
              >
                Forgot Username or Password?
              </Link>
            </Text>

            <Text variant="sans-12">
              <Link
                href="https://accounts.hawaiinational.bank/enroll"
                skipExternalCheck
              >
                New User?
              </Link>
            </Text>
          </Box>
        </Box>
      </Box>
    </BoundedBox>
  )
}
