import React, { useLayoutEffect, useRef } from 'react'
import { Popup } from 'react-map-gl'
import { Box } from '@walltowall/calico'

import { Heading } from '../../components/Heading'
import { Text } from '../../components/Text'
import { ButtonLink } from '../../components/ButtonLink'
import { HNBLocation } from '../PageLayoutLocationsSearch'
import { HTMLContent } from '../../components/HTMLContent'
import { Anchor } from '../../components/Anchor'

import { useKeyPress } from '../../hooks/useKeyPress'

import * as styleRefs from './MapPopup.treat'

type MapPopupProps = {
  closePopup: () => void
  location: HNBLocation
}

export const MapPopup = ({
  closePopup,
  location: rawLocation,
}: MapPopupProps) => {
  const ref = useRef<HTMLDivElement | null>(null)
  useKeyPress('Escape', closePopup)

  const location = rawLocation!.data!

  useLayoutEffect(() => {
    const popupElement = ref.current
    if (!popupElement) return

    popupElement.focus()

    return () => popupElement.blur()
  }, [rawLocation])

  return (
    <Popup
      longitude={location.geopoint!.longitude!}
      latitude={location.geopoint!.latitude!}
      offsetTop={-40}
      closeButton={false}
      className={styleRefs.popup}
    >
      <Box
        tabIndex={-1}
        ref={ref}
        styles={{
          backgroundColor: 'beige90',
        }}
      >
        <Box
          styles={{
            backgroundColor: 'beige80',
            padding: 4,
          }}
        >
          <Heading level={3} variant="sansE">
            {location.title?.text}
          </Heading>
        </Box>

        <Box styles={{ padding: 4, display: 'flex', flexDirection: 'column' }}>
          <Box styles={{ marginBottom: 6 }}>
            {location.address?.html && (
              <>
                <Text styles={{ marginBottom: 2.5 }}>
                  <strong>Address</strong>
                </Text>
                <HTMLContent
                  html={location.address?.html}
                  componentOverrides={{
                    p: () => (props) => (
                      <Text
                        {...props}
                        styles={{ ...props.styles, marginBottom: 2 }}
                      />
                    ),
                  }}
                  styles={{ marginBottom: 6 }}
                />
              </>
            )}

            {location.phone_number && (
              <Box>
                <Text styles={{ marginBottom: 2.5 }}>
                  <strong>Phone</strong>
                </Text>

                <Anchor
                  href={`tel:${location.phone_number}`}
                  aria-label={`Phone number for ${location.title}`}
                  skipExternalCheck
                >
                  <Text>{location.phone_number}</Text>
                </Anchor>
              </Box>
            )}
          </Box>

          <Box
            styles={{
              display: 'flex',
              marginLeft: 'auto',
              marginRight: 'auto',
            }}
          >
            <ButtonLink
              href={`https://google.com/maps?q=${location.address?.text}`}
              variant="redSmall"
              styles={{ marginRight: 3 }}
              aria-label={`Directions to ${location.title?.text}`}
            >
              Directions
            </ButtonLink>

            {location.phone_number && (
              <ButtonLink
                href={`tel:${location.phone_number}`}
                variant="redSmall"
                aria-label={`Phone number for ${location.title?.text}`}
                linkProps={{ skipExternalCheck: true }}
              >
                Call
              </ButtonLink>
            )}
          </Box>
        </Box>
      </Box>
    </Popup>
  )
}
