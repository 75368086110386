import * as React from 'react'
import { graphql } from 'gatsby'
import { Box, BoxProps } from '@walltowall/calico'
import { getRichText } from '@walltowall/helpers'
import { FluidObject } from 'gatsby-image'
import GatsbyImage from 'gatsby-image/withIEPolyfill'
import { useStyles } from 'react-treat'
import { useGesture } from 'react-use-gesture'
import VisuallyHidden from '@reach/visually-hidden'
import { useKeenSlider } from 'keen-slider/react'
import clsx from 'clsx'

import { PageLayoutEntrepreneurs2022Fragment } from '../graphqlTypes'
import { MapDataToPropsArgs } from '../types'
import { PageTemplateEnhancerProps } from '../templates/page'

import { BoundedBox } from '../components/BoundedBox'
import { ButtonLink } from '../components/ButtonLink'
import { HTMLContent } from '../components/HTMLContent'
import { Link } from '../components/Link'
import { Stack } from '../components/Stack'

import * as styleRefs from './PageLayoutEntrepreneurs2022.treat'
import { preventWidow } from '../utils'

import 'keen-slider/keen-slider.min.css'

export type PageLayoutEntrepreneurs2022Props = ReturnType<
  typeof mapDataToProps
> &
  PageTemplateEnhancerProps

const SliderShadow = (props: BoxProps) => {
  const styles = useStyles(styleRefs)

  return (
    <Box {...props} className={clsx(styles.sliderShadow, props.className)} />
  )
}

export const PageLayoutEntrepreneurs2022 = ({
  textHTML,
  buttonHref,
  buttonText,
  children,
  id,
}: PageLayoutEntrepreneurs2022Props) => {
  const slideCount = React.Children.count(children)
  const styles = useStyles(styleRefs)
  const [rSlider] = useKeenSlider({
    slidesPerView: 2.5,
    centered: false,
    spacing: 24,
    breakpoints: {
      '(min-width: 40rem)': { slidesPerView: 3.5 },
      '(min-width: 64rem)': {
        slidesPerView: 5,
        rubberband: slideCount > 5,
        spacing: 32,
      },
    },
  })

  return (
    <BoundedBox
      id={id}
      component="section"
      styles={{
        backgroundColor: 'beige80',
        backgroundPositionX: 'center',
        backgroundPositionY: 'bottom',
        backgroundSize: 'cover',
        color: 'brown20',
        marginLeft: 'auto',
        marginRight: 'auto',
        maxWidth: 'xlarge',
        overflow: 'hidden',
        position: 'relative',
      }}
      className={styles.warriorBackground}
    >
      <Box
        styles={{
          backgroundColor: 'beige80',
          opacity: 75,
          position: 'absolute',
          top: 0,
          right: 0,
          bottom: 0,
          left: 0,
        }}
      />
      <Box
        className={styles.minHeight}
        styles={{
          display: 'flex',
          alignItems: 'center',
          flexDirection: 'column',
        }}
      >
        <Box
          styles={{
            alignSelf: 'center',
            position: 'relative',
            pointerEvents: 'none',
            marginBottom: 10,
            flexShrink: 0,
          }}
        >
          <Stack space={[6, 8]} align="center">
            <HTMLContent
              html={textHTML}
              componentOverrides={{
                h1: (Comp) => ({ children, ...props }) => (
                  <Comp
                    variant="sansC"
                    styles={{ color: 'beige40' }}
                    {...props}
                  >
                    {preventWidow(children, 3)}
                  </Comp>
                ),
                p: (Comp) => (props) => (
                  <Comp variant="sans-18-20" {...props} />
                ),
              }}
              styles={{
                textAlign: 'center',
                maxWidth: '72ch',
                pointerEvents: 'auto',
              }}
            />

            {buttonHref && (
              <ButtonLink
                variant="red"
                href={buttonHref}
                styles={{ pointerEvents: 'auto' }}
              >
                {buttonText}
              </ButtonLink>
            )}
          </Stack>
        </Box>

        {children && (
          <Box styles={{ position: 'relative', alignSelf: 'stretch' }}>
            <SliderShadow style={{ left: 'calc(var(--size) * -1.25)' }} />

            <Box
              className="keen-slider"
              ref={rSlider}
              styles={{
                display: 'flex',
                alignItems: 'start',
                flexGrow: 1,
                marginLeft: [-5, -10, 0],
                marginRight: [-5, -10, 0],
                paddingLeft: [5, 10, 0],
                paddingRight: [5, 10, 0],
              }}
            >
              {children}
            </Box>

            <SliderShadow style={{ right: 'calc(var(--size) * -0.7)' }} />
          </Box>
        )}
      </Box>
    </BoundedBox>
  )
}

type PageLayoutEntrepreneurs2022ItemProps = {
  index?: number
  imageIdleFluid?: FluidObject
  imageActiveFluid?: FluidObject
  name?: string
  href?: string
}

const PageLayoutEntrepreneurs2022Item = ({
  index = -1,
  imageIdleFluid,
  imageActiveFluid,
  name,
  href,
}: PageLayoutEntrepreneurs2022ItemProps) => {
  const styles = useStyles(styleRefs)

  const [isFocused, setIsFocused] = React.useState(false)
  const handleFocus = (isFocused: boolean) =>
    setIsFocused(() => Boolean(isFocused))

  const bind = useGesture({
    onHover: ({ hovering }) => handleFocus(hovering),
    onDrag: ({ down }) => handleFocus(down),
  })

  const isEven = index % 2 === 0

  return href ? (
    <Link
      href={href}
      onBlur={() => handleFocus(false)}
      onFocus={() => handleFocus(true)}
      {...bind()}
      className={clsx(
        styles.itemSizing,
        isEven && styles.itemOffsetEven,
        'keen-slider__slide',
      )}
    >
      <VisuallyHidden>View bio for {name}</VisuallyHidden>
      <Box styles={{ position: 'relative' }}>
        {imageIdleFluid && (
          <GatsbyImage fluid={imageIdleFluid} alt="" objectFit="contain" />
        )}

        {imageActiveFluid && (
          <Box
            styles={{
              position: 'absolute',
              opacity: isFocused ? 100 : 0,
              top: 0,
              left: 0,
              right: 0,
              bottom: 0,
              pointerEvents: 'none',
            }}
          >
            <GatsbyImage fluid={imageActiveFluid} alt="" objectFit="contain" />
          </Box>
        )}
      </Box>
    </Link>
  ) : null
}
PageLayoutEntrepreneurs2022.Item = PageLayoutEntrepreneurs2022Item

export const mapDataToProps = ({
  data,
}: MapDataToPropsArgs<PageLayoutEntrepreneurs2022Fragment>) => ({
  textHTML: getRichText(data.primary?.text),
  buttonText: data.primary?.button_text,
  buttonHref: data.primary?.button_link?.url,
  children: data.items?.map?.((item, idx) => (
    <PageLayoutEntrepreneurs2022.Item
      key={item?.entrepreneur_name?.text}
      imageIdleFluid={item?.entrepreneur_image_idle?.fluid}
      imageActiveFluid={item?.entrepreneur_image_active?.fluid}
      name={item?.entrepreneur_name?.text}
      index={idx}
      href={item?.entrepreneur_link?.url}
    />
  )),
})

export const mapDataToContext = () => ({
  bg: Symbol(),
})

export const query = graphql`
  fragment PageLayoutEntrepreneurs2022 on PrismicPageLayoutEntrepreneurs2022 {
    primary {
      text {
        text
        html
      }
      button_text
      button_link {
        url
        target
      }
    }
    items {
      entrepreneur_image_idle {
        fluid(maxWidth: 200) {
          ...GatsbyPrismicImageFluid
        }
      }
      entrepreneur_image_active {
        fluid(maxWidth: 200) {
          ...GatsbyPrismicImageFluid
        }
      }
      entrepreneur_name {
        text
      }
      entrepreneur_link {
        url
      }
    }
  }
`

export default PageLayoutEntrepreneurs2022
