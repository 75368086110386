import * as React from 'react'
import GatsbyImage from 'gatsby-image/withIEPolyfill'
import { FluidObject, FixedObject } from 'gatsby-image'
import { Box, BoxProps } from '@walltowall/calico'

import { GradientOverlay, GradientOverlayProps } from './GradientOverlay'

export type GatsbyImageFadedProps = {
  variant: GradientOverlayProps['variant']
  alt?: string
} & ({ fluid: FluidObject } | { fixed: FixedObject }) &
  BoxProps

export const GatsbyImageFaded = ({
  variant,
  alt,
  ...props
}: GatsbyImageFadedProps) => {
  // TODO: `props` contains fluid and is spread on <Box />. Find a way to
  // remove it from the object while keeping the types strict about only fluid
  // or fixed being provided.

  return (
    <Box
      {...props}
      styles={{
        position: 'relative',
        overflow: 'hidden',
        ...props.styles,
      }}
    >
      {'fluid' in props ? (
        <GatsbyImage
          fluid={props.fluid}
          alt={alt}
          style={{ minHeight: '100%' }}
        />
      ) : (
        <GatsbyImage
          fixed={props.fixed}
          alt={alt}
          style={{ minHeight: '100%' }}
        />
      )}
      <GradientOverlay
        variant={variant}
        styles={{ position: 'absolute', top: 0, left: 0, bottom: 0, right: 0 }}
      />
    </Box>
  )
}
