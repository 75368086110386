import * as React from 'react'
import { graphql } from 'gatsby'
import { Box } from '@walltowall/calico'
import { getRichText } from '@walltowall/helpers'
import { FluidObject } from 'gatsby-image'
import GatsbyImage from 'gatsby-image/withIEPolyfill'
import { useStyles } from 'react-treat'
import { useGesture } from 'react-use-gesture'
import VisuallyHidden from '@reach/visually-hidden'
import clsx from 'clsx'

import { PageLayoutEntrepreneurs2020Fragment } from '../graphqlTypes'
import { MapDataToPropsArgs } from '../types'
import { PageTemplateEnhancerProps } from '../templates/page'

import { BoundedBox } from '../components/BoundedBox'
import { ButtonLink } from '../components/ButtonLink'
import { HTMLContent } from '../components/HTMLContent'
import { Link } from '../components/Link'
import { Stack } from '../components/Stack'

import * as styleRefs from './PageLayoutEntrepreneurs2020.treat'
import { preventWidow } from '../utils'

export type PageLayoutEntrepreneurs2020Props = ReturnType<
  typeof mapDataToProps
> &
  PageTemplateEnhancerProps

export const PageLayoutEntrepreneurs2020 = ({
  textHTML,
  buttonHref,
  buttonText,
  children,
  id,
}: PageLayoutEntrepreneurs2020Props) => {
  const styles = useStyles(styleRefs)

  return (
    <BoundedBox
      id={id}
      component="section"
      styles={{
        backgroundColor: 'beige80',
        backgroundPositionX: 'center',
        backgroundPositionY: 'bottom',
        backgroundSize: 'cover',
        color: 'brown20',
        marginLeft: 'auto',
        marginRight: 'auto',
        maxWidth: 'xlarge',
        overflow: 'hidden',
        position: 'relative',
      }}
      className={styles.warriorBackground}
    >
      <Box
        styles={{
          backgroundColor: 'beige80',
          opacity: 75,
          position: 'absolute',
          top: 0,
          right: 0,
          bottom: 0,
          left: 0,
        }}
      />
      <Box
        className={styles.minHeight}
        styles={{
          display: 'flex',
          alignItems: 'center',
          flexDirection: ['columnReverse', 'row'],
        }}
      >
        {children && (
          <Box
            styles={{
              display: 'flex',
              alignItems: 'center',
              justifyContent: 'center',
              alignSelf: 'stretch',
              marginLeft: [-5, -10, -16],
              marginRight: [-5, 5, 0],
              position: 'relative',
              flexGrow: 1,
            }}
          >
            {React.Children.map(
              children,
              (child, index) =>
                React.isValidElement(child) &&
                React.cloneElement(child, { index }),
            )}
          </Box>
        )}
        <Box
          styles={{
            alignSelf: 'center',
            width: ['auto', '6/12'],
            position: 'relative',
            pointerEvents: 'none',
            marginBottom: [10, 0],
            flexShrink: 0,
          }}
        >
          <Stack space={[6, 8]} align="center">
            <HTMLContent
              html={textHTML}
              componentOverrides={{
                h1: (Comp) => ({ children, ...props }) => (
                  <Comp
                    variant="sansC"
                    styles={{ color: 'beige40' }}
                    {...props}
                  >
                    {preventWidow(children, 3)}
                  </Comp>
                ),
                p: (Comp) => (props) => (
                  <Comp variant="sans-18-20" {...props} />
                ),
              }}
              styles={{
                textAlign: 'center',
                maxWidth: '50ch',
                pointerEvents: 'auto',
              }}
            />
            {buttonHref && (
              <ButtonLink
                variant="red"
                href={buttonHref}
                styles={{ pointerEvents: 'auto' }}
              >
                {buttonText}
              </ButtonLink>
            )}
          </Stack>
        </Box>
      </Box>
    </BoundedBox>
  )
}

type PageLayoutEntrepreneurs2020ItemProps = {
  index?: number
  imageIdleFluid?: FluidObject
  imageActiveFluid?: FluidObject
  name?: string
  href?: string
}

const PageLayoutEntrepreneurs2020Item = ({
  index = -1,
  imageIdleFluid,
  imageActiveFluid,
  name,
  href,
}: PageLayoutEntrepreneurs2020ItemProps) => {
  const styles = useStyles(styleRefs)

  const [isFocused, setIsFocused] = React.useState(false)
  const handleFocus = (isFocused: boolean) =>
    setIsFocused(() => Boolean(isFocused))

  const bind = useGesture({
    onHover: ({ hovering }) => handleFocus(hovering),
    onDrag: ({ down }) => handleFocus(down),
  })

  const isEven = index % 2 === 0

  return href ? (
    <Link
      href={href}
      onBlur={() => handleFocus(false)}
      onFocus={() => handleFocus(true)}
      {...bind()}
      className={clsx(
        styles.itemSizing,
        isEven ? styles.itemOffsetEven : styles.itemOffsetOdd,
      )}
    >
      <VisuallyHidden>View bio for {name}</VisuallyHidden>
      <Box styles={{ position: 'relative' }}>
        {imageIdleFluid && (
          <GatsbyImage fluid={imageIdleFluid} alt="" objectFit="contain" />
        )}
        {imageActiveFluid && (
          <Box
            styles={{
              position: 'absolute',
              opacity: isFocused ? 100 : 0,
              top: 0,
              left: 0,
              right: 0,
              bottom: 0,
              pointerEvents: 'none',
            }}
          >
            <GatsbyImage fluid={imageActiveFluid} alt="" objectFit="contain" />
          </Box>
        )}
      </Box>
    </Link>
  ) : null
}
PageLayoutEntrepreneurs2020.Item = PageLayoutEntrepreneurs2020Item

export const mapDataToProps = ({
  data,
}: MapDataToPropsArgs<PageLayoutEntrepreneurs2020Fragment>) => ({
  textHTML: getRichText(data.primary?.text),
  buttonText: data.primary?.button_text,
  buttonHref: data.primary?.button_link?.url,
  children: data.items?.map?.((item) => (
    <PageLayoutEntrepreneurs2020.Item
      key={item?.entrepreneur_name?.text}
      imageIdleFluid={item?.entrepreneur_image_idle?.fluid}
      imageActiveFluid={item?.entrepreneur_image_active?.fluid}
      name={item?.entrepreneur_name?.text}
      href={item?.entrepreneur_link?.url}
    />
  )),
})

export const mapDataToContext = () => ({
  bg: Symbol(),
})

export const query = graphql`
  fragment PageLayoutEntrepreneurs2020 on PrismicPageLayoutEntrepreneurs2020 {
    primary {
      text {
        text
        html
      }
      button_text
      button_link {
        url
        target
      }
    }
    items {
      entrepreneur_image_idle {
        fluid(maxWidth: 200) {
          ...GatsbyPrismicImageFluid
        }
      }
      entrepreneur_image_active {
        fluid(maxWidth: 200) {
          ...GatsbyPrismicImageFluid
        }
      }
      entrepreneur_name {
        text
      }
      entrepreneur_link {
        url
      }
    }
  }
`

export default PageLayoutEntrepreneurs2020
