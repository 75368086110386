import * as React from 'react'

interface Args {
  callback: () => void
  interval: number
  enabled?: boolean
}

export const useInterval = ({ callback, interval, enabled }: Args) => {
  const callbackRef = React.useRef(callback)
  const id = React.useRef<NodeJS.Timeout | undefined>()

  React.useEffect(() => {
    callbackRef.current = callback
  })

  React.useEffect(() => {
    if (!enabled) return

    id.current = setInterval(() => {
      callbackRef.current()
    }, interval)

    return () => clearInterval(id.current as NodeJS.Timeout)
  }, [interval, enabled])

  const restartInterval = () => {
    if (id.current) clearInterval(id.current)

    id.current = setInterval(() => {
      callbackRef.current()
    }, interval)
  }

  return restartInterval
}
