import * as React from 'react'
import { graphql } from 'gatsby'
import { useStyles } from 'react-treat'
import { FluidObject } from 'gatsby-image'
import { Box } from '@walltowall/calico'
import { getRichText } from '@walltowall/helpers'
import { camelCase } from 'camel-case'

import { PageLayoutFeaturedProductFragment } from '../graphqlTypes'
import { MapDataToPropsArgs } from '../types'
import { PageTemplateEnhancerProps } from '../templates/page'
import { useUtilStyles } from '../hooks/useUtilStyles'

import { BoundedBox } from '../components/BoundedBox'
import { HTMLContent } from '../components/HTMLContent'
import { StyledHTMLContent } from '../components/StyledHTMLContent'
import { GatsbyImageContainer } from '../components/GatsbyImageContainer'
import { GatsbyImageFaded } from '../components/GatsbyImageFaded'
import { ButtonLink } from '../components/ButtonLink'

import * as styleRefs from './PageLayoutFeaturedProduct.treat'

export type PageLayoutFeaturedProductProps = Partial<
  Omit<ReturnType<typeof mapDataToProps>, 'imageSide'>
> & {
  imageSide: 'left' | 'right'
} & PageTemplateEnhancerProps

export const PageLayoutFeaturedProduct = ({
  children,
  nextSharesBg,
  imageSide = 'right',
  imageFluid,
  imageAlt,
  introHTML,
  textHTML,
  buttonText = 'Learn more',
  buttonHref,
  buttonTarget,
  ctaTextHTML,
  ctaButtonHref,
  ctaButtonText = 'Learn more',
  ctaButtonTarget,
  id,
}: PageLayoutFeaturedProductProps) => {
  const styles = useStyles(styleRefs)

  const imageFadedVariant =
    imageSide === 'left' ? 'beige100Right' : 'beige100Left'
  const hasChildren = React.Children.count(children) > 0

  const { lastNoMargin } = useUtilStyles()

  return (
    <BoundedBox
      id={id}
      component="section"
      nextSharesBg={nextSharesBg}
      innerMaxWidth="medium"
      styles={{
        backgroundColor: 'beige100',
        maxWidth: 'xlarge',
        marginRight: 'auto',
        marginLeft: 'auto',
        color: 'brown20',
        position: 'relative',
      }}
    >
      {imageFluid && (
        <GatsbyImageContainer
          styles={{
            position: 'absolute',
            top: 0,
            left: imageSide === 'left' ? 0 : undefined,
            right: imageSide === 'right' ? 0 : undefined,
            bottom: ['auto', 0],
            width: ['full', '9/12'],
            height: ['19rem', 'auto'],
          }}
        >
          <GatsbyImageFaded
            variant={imageFadedVariant}
            fluid={imageFluid}
            alt={imageAlt}
            styles={{ height: 'full' }}
          />
        </GatsbyImageContainer>
      )}
      <Box
        styles={{
          display: 'flex',
          justifyContent: ['start', imageSide === 'left' ? 'end' : 'start'],
          alignItems: 'center',
          minHeight: ['none', '16rem', '22rem'],
        }}
      >
        <Box
          styles={{
            position: 'relative',
            width: ['full', '6/12'],
            marginTop: ['15rem', 0],
          }}
        >
          {introHTML && (
            <StyledHTMLContent html={introHTML} className={lastNoMargin} />
          )}
          {hasChildren && (
            <Box
              className={lastNoMargin}
              styles={{
                display: ['block', 'flex'],
                flexDirection: 'row',
                marginTop: [8, 9, 10],
              }}
            >
              {children}
            </Box>
          )}
          {textHTML && (
            <StyledHTMLContent
              html={textHTML}
              styles={{ marginTop: [8, 9, 10] }}
            />
          )}
          {buttonHref && (
            <Box styles={{ marginTop: [6, 7], display: 'inlineBlock' }}>
              <ButtonLink
                variant="red"
                href={buttonHref}
                linkProps={{ target: buttonTarget }}
              >
                {buttonText}
              </ButtonLink>
            </Box>
          )}
          {ctaTextHTML && (
            <Box
              className={styles.ctaBackgroundGradient}
              styles={{
                color: 'white',
                padding: [4, 5],
                marginRight: [-4, 0],
                marginLeft: [-4, 0],
                marginTop: [8, 9, 10],
                marginBottom: [-10, -15, -20],
                maxWidth: ['none', '14rem'],
              }}
            >
              <HTMLContent
                html={ctaTextHTML}
                componentOverrides={{
                  h3: (Comp) => (props) => (
                    <Comp
                      variant="sansE"
                      {...props}
                      styles={{ marginBottom: 4 }}
                    />
                  ),
                  p: (Comp) => (props) => <Comp variant="sans-12" {...props} />,
                }}
              />
              {ctaButtonHref && (
                <Box styles={{ marginTop: 4 }}>
                  <ButtonLink
                    variant="whiteSmall"
                    href={ctaButtonHref}
                    linkProps={{ target: ctaButtonTarget }}
                  >
                    {ctaButtonText}
                  </ButtonLink>
                </Box>
              )}
            </Box>
          )}
        </Box>
      </Box>
    </BoundedBox>
  )
}

export type PageLayoutFeaturedProductItemProps = {
  textHTML?: string
  footnoteHTML?: string
}

PageLayoutFeaturedProduct.Item = ({
  textHTML,
}: PageLayoutFeaturedProductItemProps) => {
  const { lastNoMargin } = useUtilStyles()

  return (
    <Box
      className={lastNoMargin}
      styles={{
        flexGrow: 1,
        flexShrink: 1,
        flexBasis: 0,
        marginRight: [0, 8],
        marginBottom: [7, 0],
      }}
    >
      {textHTML && <StyledHTMLContent html={textHTML} />}
    </Box>
  )
}

export const mapDataToProps = ({
  data,
}: MapDataToPropsArgs<PageLayoutFeaturedProductFragment>) => ({
  imageSide: data.primary?.image_side
    ? camelCase(data.primary?.image_side)
    : undefined,
  imageFluid: data.primary?.image?.fluid as FluidObject,
  imageAlt: data.primary?.image?.alt,
  introHTML: getRichText(data.primary?.intro),
  textHTML: getRichText(data.primary?.text),
  buttonText: data.primary?.button_text,
  buttonHref: data.primary?.button_link?.url,
  buttonTarget: data.primary?.button_link?.target,
  ctaTextHTML: getRichText(data.primary?.call_to_action_text),
  ctaButtonText: data.primary?.call_to_action_button_text,
  ctaButtonHref: data.primary?.call_to_action_button_link?.url,
  ctaButtonTarget: data.primary?.call_to_action_button_link?.target,
  children: data.items?.map((item) => (
    <PageLayoutFeaturedProduct.Item
      key={item?.highlight_text?.text}
      textHTML={getRichText(item?.highlight_text)}
    />
  )) as React.ReactNode,
})

export const mapDataToContext = () => ({
  bg: Symbol(),
})

export const query = graphql`
  fragment PageLayoutFeaturedProduct on PrismicPageLayoutFeaturedProduct {
    primary {
      image_side
      image {
        alt
        fluid(maxWidth: 1000) {
          ...GatsbyPrismicImageFluid
        }
      }
      intro {
        html
        text
      }
      text {
        html
        text
      }
      button_text
      button_link {
        url
        target
      }
      call_to_action_text {
        text
        html
      }
      call_to_action_button_text
      call_to_action_button_link {
        url
        target
      }
    }
    items {
      highlight_text {
        text
        html
      }
    }
  }
`

export default PageLayoutFeaturedProduct
