import * as React from 'react'
import { graphql } from 'gatsby'
import { useStyles } from 'react-treat'
import { AspectRatio } from '@walltowall/siamese'
import { Box } from '@walltowall/calico'
import { getRichText } from '@walltowall/helpers'
import { camelCase } from 'camel-case'
import clsx from 'clsx'

import { PageLayoutCardGalleryFragment } from '../graphqlTypes'
import { MapDataToPropsArgs, MapDataToContextArgs } from '../types'
import { PageTemplateEnhancerProps } from '../templates/page'
import { useUtilStyles } from '../hooks/useUtilStyles'

import { BoundedBox } from '../components/BoundedBox'
import { StyledHTMLContent } from '../components/StyledHTMLContent'
import { ButtonLink } from '../components/ButtonLink'
import { CardImage } from '../components/CardImage'
import { Divider } from '../components/Divider'

import * as styleRefs from './PageLayoutCardGallery.treat'

const variants = {
  normal: {
    backgroundColor: 'white',
    className: undefined,
  },
  alt: {
    backgroundColor: 'beige100',
    className: 'diamondBackground',
  },
} as const

export type PageLayoutCardGalleryProps = Partial<
  Omit<ReturnType<typeof mapDataToProps>, 'imageSide'>
> & {
  variant?: keyof typeof variants
} & PageTemplateEnhancerProps

export const PageLayoutCardGallery = ({
  children,
  nextSharesBg,
  variant: variantName = 'normal',
  textHTML,
  id,
}: PageLayoutCardGalleryProps) => {
  const styles = useStyles(styleRefs)

  const variant = variants[variantName]
  const hasChildren = React.Children.count(children) > 0

  return (
    <BoundedBox
      component="section"
      id={id}
      nextSharesBg={nextSharesBg}
      innerMaxWidth="large"
      className={clsx(variant.className && styles[variant.className])}
      styles={{
        backgroundColor: variant.backgroundColor,
        maxWidth: 'xlarge',
        marginRight: 'auto',
        marginLeft: 'auto',
        color: 'brown20',
        position: 'relative',
      }}
    >
      {textHTML && (
        <StyledHTMLContent
          html={textHTML}
          styles={{
            textAlign: ['left', 'center'],
            marginBottom: [8, 12],
          }}
        />
      )}
      {hasChildren && <Box>{children}</Box>}
    </BoundedBox>
  )
}

export type PageLayoutCardGalleryItemProps = {
  imageUrl?: string
  imageAlt?: string
  textHTML?: string
  buttonText?: string
  buttonHref?: string
  buttonTarget?: string
}

PageLayoutCardGallery.Card = ({
  imageUrl,
  textHTML,
  buttonText = 'Learn more',
  buttonHref,
  buttonTarget,
}: PageLayoutCardGalleryItemProps) => {
  const styles = useStyles(styleRefs)
  const { hideIfFirstChild } = useUtilStyles()

  return (
    <>
      <Divider
        color="beige80"
        className={hideIfFirstChild}
        styles={{
          alignSelf: 'stretch',
          marginTop: [8, 12],
          marginBottom: [8, 12],
        }}
      />
      <Box
        styles={{
          display: 'flex',
          flexDirection: ['column', 'row'],
          maxWidth: 'medium',
          marginRight: 'auto',
          marginLeft: 'auto',
        }}
      >
        <Box
          styles={{
            marginBottom: [8, 0],
            marginRight: [0, 8],
            paddingRight: [6, 0],
            paddingLeft: [6, 0],
            width: ['full', '6/12'],
          }}
        >
          {imageUrl && (
            <Box
              className={clsx(
                styles.cardSkeuomorphism,
                styles.cardImageMaxWidth,
              )}
              styles={{
                marginRight: 'auto',
                marginLeft: 'auto',
              }}
            >
              <AspectRatio x={1013} y={638}>
                <CardImage imgUrl={imageUrl} />
              </AspectRatio>
            </Box>
          )}
        </Box>
        <Box
          styles={{
            width: ['full', '6/12'],
            paddingTop: [0, 2],
            display: 'flex',
            flexDirection: 'column',
          }}
        >
          {textHTML && <StyledHTMLContent html={textHTML} />}
          {buttonHref && (
            <Box styles={{ marginTop: [6, 7], alignSelf: 'start' }}>
              <ButtonLink
                variant="red"
                href={buttonHref}
                linkProps={{ target: buttonTarget }}
              >
                {buttonText}
              </ButtonLink>
            </Box>
          )}
        </Box>
      </Box>
    </>
  )
}

export const mapDataToProps = ({
  data,
}: MapDataToPropsArgs<PageLayoutCardGalleryFragment>) => ({
  variant: data.primary?.styling ? camelCase(data.primary?.styling) : undefined,
  textHTML: getRichText(data.primary?.text),
  children: data.items?.map((item) => (
    <PageLayoutCardGallery.Card
      key={item?.text?.text}
      imageUrl={item?.image?.url}
      imageAlt={item?.image?.alt}
      textHTML={getRichText(item?.text)}
      buttonText={item?.button_text}
      buttonHref={item?.button_link?.url}
      buttonTarget={item?.button_link?.target}
    />
  )) as React.ReactNode,
})

export const mapDataToContext = ({
  data,
}: MapDataToContextArgs<PageLayoutCardGalleryFragment>) => {
  const variantName: keyof typeof variants = data.primary?.styling
    ? (camelCase(data.primary?.styling) as keyof typeof variants)
    : 'normal'
  const variant = variants[variantName]

  return {
    bg: variant.backgroundColor,
  }
}

export const query = graphql`
  fragment PageLayoutCardGallery on PrismicPageLayoutCardGallery {
    primary {
      styling
      text {
        text
        html
      }
    }
    items {
      image {
        alt
        url
      }
      text {
        text
        html
      }
      button_text
      button_link {
        url
        target
      }
    }
  }
`

export default PageLayoutCardGallery
