import * as React from 'react'
import { useStyles } from 'react-treat'
import { Box, useBoxStyles } from '@walltowall/calico'
import { usePersistentDismissable } from 'react-persistent-dismissable'
import md5 from 'tiny-hashes/md5'

import { useTemporaryNotification } from '../../hooks/useTemporaryNotification'

import { HTMLContent } from '../../components/HTMLContent'
import { Link } from '../../components/Link'
import { Button } from '../../components/Button'

import * as styleRefs from './TemporaryNotification.treat'
import { BoundedBox } from '../../components/BoundedBox'
import clsx from 'clsx'

export const TemporaryNotification = () => {
  const styles = useStyles(styleRefs)
  const anchorClassNames = useBoxStyles({ textDecoration: 'underline' })

  const temporaryNotification = useTemporaryNotification()
  const { current: key } = React.useRef(md5(temporaryNotification.textHTML))
  const [isDismissed, dismiss] = usePersistentDismissable(key, true)

  return (
    <BoundedBox
      innerMaxWidth="large"
      className={styles.transitionPropertyTransformMaxHeight}
      styles={{
        paddingTop: 0,
        paddingBottom: 0,
        paddingLeft: 0,
        paddingRight: 0,
        transitionDuration: 'normal',
        transitionTimingFunction: 'easeInOut',
        maxHeight: [isDismissed ? 0 : '30rem', 'none'],
        overflow: ['hidden', 'visible'],
      }}
    >
      <Box styles={{ position: 'relative' }}>
        <Box
          className={clsx(
            styles.gradientBackground,
            styles.shadow,
            styles.transitionPropertyTransformOpacity,
            isDismissed && styles.transformUp,
          )}
          styles={{
            color: 'white',
            paddingLeft: [4, 6],
            paddingRight: [4, 6],
            paddingTop: [10, 6],
            paddingBottom: 6,
            position: ['static', 'absolute'],
            zIndex: 1,
            right: 0,
            top: 0,
            width: ['auto', '16rem'],
            opacity: [100, isDismissed ? 0 : 100],
          }}
        >
          <HTMLContent
            html={temporaryNotification.textHTML}
            componentOverrides={{
              a: () => (props) => (
                <Link {...props} className={anchorClassNames} />
              ),
            }}
            styles={{ marginBottom: 5 }}
          />
          <Button variant="whiteSmall" onClick={dismiss}>
            Dismiss
          </Button>
        </Box>
      </Box>
    </BoundedBox>
  )
}
