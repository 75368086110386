import * as React from 'react'
import { graphql } from 'gatsby'
import { Box, BoxProps } from '@walltowall/calico'
import VisuallyHidden from '@reach/visually-hidden'
import { getRichText } from '@walltowall/helpers'
import GatsbyImage from 'gatsby-image/withIEPolyfill'

import { PageLayoutHometownHeroesFragment } from '../graphqlTypes'
import { MapDataToPropsArgs } from '../types'
import { PageTemplateEnhancerProps } from '../templates/page'

import { Text } from '../components/Text'
import { SVG } from '../components/SVG'
import { HTMLContent } from '../components/HTMLContent'
import { BoundedBox } from '../components/BoundedBox'
import { ButtonLink } from '../components/ButtonLink'
import { GatsbyImageContainer } from '../components/GatsbyImageContainer'
import { ReactComponent as AssetLogoHometownHeroesSVG } from '../assets/logo-hometown-heroes.svg'

type SubheadingProps = {
  children?: string
} & BoxProps

const Subheading = ({ children, ...props }: SubheadingProps) => {
  const words = children?.split?.(' ')

  return (
    <Box
      component="section"
      {...props}
      styles={{ paddingLeft: 4.5, ...props.styles }}
    >
      <VisuallyHidden>{children}</VisuallyHidden>
      {words &&
        words.map((word) => (
          <Box
            key={word}
            component="span"
            aria-hidden={true}
            styles={{
              display: 'inlineBlock',
              backgroundColor: 'yellow60',
              color: 'brown0',
              paddingTop: 2,
              paddingBottom: 2,
              paddingRight: 3,
              paddingLeft: 3,
              marginLeft: -4.5,
            }}
          >
            <Text
              variant="sans-12"
              component="span"
              styles={{
                display: 'block',
                position: 'relative',
                textTransform: 'uppercase',
                letterSpacing: 'm',
                fontWeight: 'bold',
              }}
            >
              {word}
            </Text>
          </Box>
        ))}
    </Box>
  )
}

export type PageLayoutHometownHeroesProps = ReturnType<typeof mapDataToProps> &
  PageTemplateEnhancerProps

export const PageLayoutHometownHeroes = ({
  subheading,
  textHTML,
  buttonHref,
  buttonText,
  buttonTarget,
  imageFluid,
  imageAlt,
  mobileImageFluid,
  mobileImageAlt,
  id,
}: PageLayoutHometownHeroesProps) => (
  <Box
    id={id}
    component="section"
    styles={{
      backgroundColor: 'white',
      color: 'brown20',
      display: 'flex',
      flexDirection: ['column', 'row'],
      marginLeft: 'auto',
      marginRight: 'auto',
      maxWidth: 'xlarge',
    }}
  >
    {imageFluid && (
      <GatsbyImageContainer
        styles={{ display: ['none', 'block'], width: '6/12' }}
      >
        <GatsbyImage fluid={imageFluid} alt={imageAlt} objectPosition="right" />
      </GatsbyImageContainer>
    )}
    <BoundedBox styles={{ alignSelf: 'center', width: ['auto', '6/12'] }}>
      <Box
        styles={{
          display: 'flex',
          flexDirection: 'column',
          alignItems: 'center',
        }}
      >
        <Subheading styles={{ marginBottom: 5 }}>{subheading}</Subheading>
        <Box styles={{ width: '16rem', marginBottom: 7 }}>
          <SVG svg={AssetLogoHometownHeroesSVG} x={942.83} y={370.83} />
        </Box>
        {textHTML && (
          <HTMLContent
            html={textHTML}
            styles={{ textAlign: 'center', maxWidth: '50ch' }}
          />
        )}
        {buttonHref && (
          <ButtonLink
            variant="red"
            href={buttonHref}
            linkProps={{ target: buttonTarget }}
            styles={{ marginTop: 7 }}
          >
            {buttonText}
          </ButtonLink>
        )}
      </Box>
    </BoundedBox>
    {mobileImageFluid && (
      <Box styles={{ display: ['block', 'none'] }}>
        <GatsbyImage fluid={mobileImageFluid} alt={mobileImageAlt} />
      </Box>
    )}
  </Box>
)

export const mapDataToProps = ({
  data,
}: MapDataToPropsArgs<PageLayoutHometownHeroesFragment>) => ({
  subheading: data.primary?.subheading?.text,
  textHTML: getRichText(data.primary?.text),
  buttonText: data.primary?.button_text,
  buttonHref: data.primary?.button_link?.url,
  buttonTarget: data.primary?.button_link?.target,
  imageFluid: data.primary?.image?.fluid,
  imageAlt: data.primary?.image?.alt,
  mobileImageFluid: data.primary?.image?.thumbnails?.mobile?.fluid,
  mobileImageAlt: data.primary?.image?.thumbnails?.mobile?.alt,
})

export const mapDataToContext = () => ({
  bg: Symbol(),
})

export const query = graphql`
  fragment PageLayoutHometownHeroes on PrismicPageLayoutHometownHeroes {
    primary {
      subheading {
        text
      }
      text {
        text
        html
      }
      button_text
      button_link {
        url
        target
      }
      image {
        alt
        fluid(maxWidth: 800) {
          ...GatsbyPrismicImageFluid_noBase64
        }
        thumbnails {
          mobile {
            alt
            fluid(maxWidth: 800) {
              ...GatsbyPrismicImageFluid_noBase64
            }
          }
        }
      }
    }
  }
`

export default PageLayoutHometownHeroes
