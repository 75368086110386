import * as React from 'react'
import { graphql } from 'gatsby'
import { useStyles } from 'react-treat'
import { Box } from '@walltowall/calico'
import { getRichText } from '@walltowall/helpers'

import { PageLayoutButtonCardsFragment } from '../graphqlTypes'
import { MapDataToPropsArgs } from '../types'
import { PageTemplateEnhancerProps } from '../templates/page'

import { ButtonLink } from '../components/ButtonLink'
import { HTMLContent } from '../components/HTMLContent'
import { BoundedBox } from '../components/BoundedBox'

import * as styleRefs from './PageLayoutButtonCards.treat'
import { useModal } from '../hooks/useModal'

export type PageLayoutButtonCardsProps = ReturnType<typeof mapDataToProps> &
  PageTemplateEnhancerProps

export const PageLayoutButtonCards = ({
  children,
  nextSharesBg,
  id,
}: PageLayoutButtonCardsProps) => (
  <BoundedBox
    id={id}
    component="section"
    nextSharesBg={nextSharesBg}
    styles={{
      backgroundColor: 'white',
      maxWidth: 'xlarge',
      marginRight: 'auto',
      marginLeft: 'auto',
    }}
  >
    <Box
      styles={{
        display: 'flex',
        flexDirection: ['column', 'row'],
        flexWrap: 'wrap',
        marginTop: [-12, -6, 0],
      }}
    >
      {children}
    </Box>
  </BoundedBox>
)

export type PageLayoutButtonCardsCardProps = {
  textHTML?: string
  buttonHref?: string
  buttonTarget?: string
  buttonText?: string
}

PageLayoutButtonCards.Card = ({
  textHTML,
  buttonHref,
  buttonTarget,
  buttonText = 'Learn more',
}: PageLayoutButtonCardsCardProps) => {
  const styles = useStyles(styleRefs)
  const { openModal } = useModal()

  const isLostCardBtn = buttonHref?.startsWith('https://#reportModal')

  return (
    <Box
      className={styles.cardBorder}
      styles={{
        display: 'flex',
        flexDirection: 'column',
        alignItems: 'center',
        width: ['full', '6/12', 'auto'],
        paddingTop: [6, 0, 6],
        paddingBottom: [0, 0, 6],
        paddingLeft: [0, 4],
        paddingRight: [0, 4],
        marginTop: [6, 6, 0],
      }}
    >
      {textHTML && (
        <HTMLContent
          html={textHTML}
          componentOverrides={{
            h2: (Comp) => (props) => (
              <Comp variant="sansD" {...props} styles={{ color: 'beige40' }} />
            ),
            li: (Comp) => (props) => (
              <Comp
                {...props}
                className={styles.maxWidthBullets}
                styles={{ textAlign: 'left' }}
              />
            ),
          }}
          styles={{
            textAlign: 'center',
            marginBottom: [4, 5],
          }}
        />
      )}
      {buttonHref && (
        <ButtonLink
          variant="red"
          href={buttonHref}
          target={buttonTarget}
          linkProps={{ skipExternalCheck: isLostCardBtn }}
          onClick={() => {
            console.log({ isLostCardBtn, buttonHref })
            if (isLostCardBtn) {
              openModal('LOST_CARD')
            }
          }}
        >
          {buttonText}
        </ButtonLink>
      )}
    </Box>
  )
}

export const mapDataToProps = ({
  data,
}: MapDataToPropsArgs<PageLayoutButtonCardsFragment>) => ({
  children: data.items?.map((item) => (
    <PageLayoutButtonCards.Card
      key={item?.title_and_description?.text}
      textHTML={getRichText(item?.title_and_description)}
      buttonHref={item?.button_link?.url}
      buttonTarget={item?.button_link?.target}
      buttonText={item?.button_text}
    />
  )) as React.ReactNode,
})

export const mapDataToContext = () => ({
  bg: 'white',
})

export const query = graphql`
  fragment PageLayoutButtonCards on PrismicPageLayoutButtonCards {
    items {
      title_and_description {
        html
        text
      }
      button_link {
        url
        target
      }
      button_text
    }
  }
`

export default PageLayoutButtonCards
