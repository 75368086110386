import * as React from 'react'
import clsx from 'clsx'
import { graphql } from 'gatsby'
import { getRichText } from '@walltowall/helpers'
import { Box, useBoxStyles } from '@walltowall/calico'
import { useStyles } from 'react-treat'

import { MapDataToPropsArgs } from '../types'
import { PageTemplateEnhancerProps } from '../templates/page'
import { PageLayoutEntrepreneurs2023Fragment } from '../graphqlTypes'

import { BoundedBox } from '../components/BoundedBox'
import { ButtonLink } from '../components/ButtonLink'
import { HTMLContent } from '../components/HTMLContent'
import { Link } from '../components/Link'
import { Stack } from '../components/Stack'

import * as styleRefs from './PageLayoutEntrepreneurs2023.treat'
import { AspectRatio } from '@walltowall/siamese'
import { OutlineWarrior } from '../components/OutlineWarrior'

import assetEntrepreneur1 from '../assets/entrepreneur-1-2024.webp'
import assetEntrepreneur2 from '../assets/entrepreneur-2-2024.webp'
import assetEntrepreneur3 from '../assets/entrepreneur-3.webp'
import assetEntrepreneur4 from '../assets/entrepreneur-4-2024.webp'

const entrepreneurThemes = {
  blue: {
    color: 'blue90',
    bg: 'blue10',
  },
  yellow: {
    color: 'yellow80',
    bg: 'yellow50',
  },
  green: {
    color: 'green80',
    bg: 'green30',
  },
  purple: {
    color: 'purple90',
    bg: 'purple30',
  },
} as const

const Entrepreneur = (props: {
  name: string
  imageSrc: string
  theme: keyof typeof entrepreneurThemes
  href: string
  portraitOverrides?: string
}) => {
  const styles = useStyles(styleRefs)

  const theme = entrepreneurThemes[props.theme]

  return (
    <AspectRatio
      x={1}
      y={1}
      className={clsx(
        styles.aspectRatio,
        useBoxStyles({
          backgroundColor: theme.bg,
          overflow: 'hidden',
        }),
      )}
    >
      <Box
        component={Link}
        href={props.href}
        styles={{
          position: 'relative',
          width: 'full',
          display: 'flex',
          flexDirection: 'column',
        }}
        className={styles.entrepreneur}
      >
        <OutlineWarrior
          width={3074}
          className={clsx(
            useBoxStyles({ color: theme.color, position: 'absolute' }),
            styles.outlines[props.theme],
          )}
        />

        <img
          src={props.imageSrc}
          loading="lazy"
          alt={props.name}
          className={clsx(styles.portrait, props.portraitOverrides)}
        />
      </Box>
    </AspectRatio>
  )
}

export type PageLayoutEntrepreneurs2023Props = ReturnType<
  typeof mapDataToProps
> &
  PageTemplateEnhancerProps

export const PageLayoutEntrepreneurs2023 = ({
  textHTML,
  buttonHref,
  buttonText,
  id,
}: PageLayoutEntrepreneurs2023Props) => {
  const styles = useStyles(styleRefs)

  return (
    <BoundedBox
      id={id}
      component="section"
      styles={{
        backgroundColor: 'white',
        color: 'brown20',
        marginLeft: 'auto',
        marginRight: 'auto',
        maxWidth: 'xlarge',
        overflow: 'hidden',
        position: 'relative',
        paddingBottom: 0,
      }}
    >
      <Stack space={[6, 8]} align="center">
        <HTMLContent
          html={textHTML}
          componentOverrides={{
            h1: (Comp) => ({ children, ...props }) => (
              <Comp
                variant="sansA"
                styles={{ color: 'beige40' }}
                style={{ textWrap: 'balance' }}
                {...props}
              >
                {children}
              </Comp>
            ),
            p: (Comp) => (props) => (
              <Comp
                variant="sans-18-20"
                style={{ maxWidth: '64ch' }}
                {...props}
              />
            ),
          }}
          styles={{
            display: 'flex',
            flexDirection: 'column',
            alignItems: 'center',
            textAlign: 'center',
            pointerEvents: 'auto',
          }}
        />

        {buttonHref && (
          <ButtonLink
            variant="red"
            href={buttonHref}
            styles={{ pointerEvents: 'auto' }}
          >
            {buttonText}
          </ButtonLink>
        )}
      </Stack>

      <Box
        styles={{
          display: 'grid',
          gridTemplateColumns: [2, 4],
          marginTop: [8, 12],
          marginLeft: [-4, -10, -16],
          marginRight: [-4, -10, -16],
        }}
      >
        <Entrepreneur
          name="Bob Harmon"
          imageSrc={assetEntrepreneur1}
          theme="blue"
          href="https://entrepreneurs.hawaiinational.com/profile-eggshell/"
          portraitOverrides={styles.portraitOverrides.bob}
        />
        <Entrepreneur
          name="Kevin Balog"
          imageSrc={assetEntrepreneur2}
          theme="yellow"
          href="https://entrepreneurs.hawaiinational.com/profile-kenworth/"
          portraitOverrides={styles.portraitOverrides.kevin}
        />
        <Entrepreneur
          name="Liana Benn"
          imageSrc={assetEntrepreneur3}
          theme="green"
          href="https://entrepreneurs.hawaiinational.com/profile-royalkitchen/"
        />
        <Entrepreneur
          name="Sig Zane"
          imageSrc={assetEntrepreneur4}
          theme="purple"
          href="https://entrepreneurs.hawaiinational.com/profile-sigzane/"
          portraitOverrides={styles.portraitOverrides.sig}
        />
      </Box>
    </BoundedBox>
  )
}

export const mapDataToProps = ({
  data,
}: MapDataToPropsArgs<PageLayoutEntrepreneurs2023Fragment>) => ({
  textHTML: getRichText(data.primary?.text),
  buttonText: data.primary?.button_text,
  buttonHref: data.primary?.button_link?.url,
})

export const mapDataToContext = () => ({
  bg: Symbol(),
})

export const query = graphql`
  fragment PageLayoutEntrepreneurs2023 on PrismicPageLayoutEntrepreneurs2023 {
    primary {
      text {
        text
        html
      }
      button_text
      button_link {
        url
        target
      }
    }
  }
`

export default PageLayoutEntrepreneurs2023
