import * as React from 'react'
import { graphql } from 'gatsby'
import { getRichText } from '@walltowall/helpers'
import { FluidObject } from 'gatsby-image'
import { camelCase } from 'camel-case'

import { PageLayoutDestinationBarFragment } from '../graphqlTypes'
import { MapDataToPropsArgs } from '../types'
import { PageTemplateEnhancerProps } from '../templates/page'
import { PickPartial } from '../types'

import { Basic } from './PageLayoutDestinationBar/Basic'
import { BasicTextHeavy } from './PageLayoutDestinationBar/BasicTextHeavy'
import { Image } from './PageLayoutDestinationBar/Image'
import { PhotoPromo } from './PageLayoutDestinationBar/PhotoPromo'
import { SpecializedCards } from './PageLayoutDestinationBar/SpecializedCards'
import { SpecializedPersonalServices } from './PageLayoutDestinationBar/SpecializedPersonalServices'
import { SpecializedPersonalConstructionLoans } from './PageLayoutDestinationBar/SpecializedPersonalConstructionLoans'
import { SpecializedRetirement } from './PageLayoutDestinationBar/SpecializedRetirement'
import { SpecializedSeedCards } from './PageLayoutDestinationBar/SpecializedSeedCards'
import { SpecializedSeedLogo } from './PageLayoutDestinationBar/SpecializedSeedLogo'

export type PageLayoutDestinationBarProps = Omit<
  PickPartial<
    ReturnType<typeof mapDataToProps>,
    'buttonText' | 'buttonTarget' | 'imageAlt'
  >,
  'type'
> & {
  type: keyof typeof variants
} & PageTemplateEnhancerProps

const variants = {
  basicRight: (props: PageLayoutDestinationBarProps) => (
    <Basic {...props} side="right" />
  ),
  basicLeft: (props: PageLayoutDestinationBarProps) => (
    <Basic {...props} side="left" />
  ),
  basicTextHeavyRight: (props: PageLayoutDestinationBarProps) => (
    <BasicTextHeavy {...props} side="right" />
  ),
  basicTextHeavyLeft: (props: PageLayoutDestinationBarProps) => (
    <BasicTextHeavy {...props} side="left" />
  ),
  imageRight: (props: PageLayoutDestinationBarProps) => (
    <Image {...props} side="right" />
  ),
  imageLeft: (props: PageLayoutDestinationBarProps) => (
    <Image {...props} side="left" />
  ),
  photoPromo: (props: PageLayoutDestinationBarProps) => (
    <PhotoPromo {...props} variant="brown10" />
  ),
  photoPromoAlt: (props: PageLayoutDestinationBarProps) => (
    <PhotoPromo {...props} variant="beige100" />
  ),
  photoPromoWOverlap: (props: PageLayoutDestinationBarProps) => (
    <PhotoPromo {...props} variant="brown10Overlap" />
  ),
  photoPromoWOverlapAlt: (props: PageLayoutDestinationBarProps) => (
    <PhotoPromo {...props} variant="beige100Overlap" />
  ),
  specializedPersonalCards: (props: PageLayoutDestinationBarProps) => (
    <SpecializedCards {...props} side="right" />
  ),
  specializedPersonalServices: SpecializedPersonalServices,
  specializedPersonalConstructionLoans: SpecializedPersonalConstructionLoans,
  specializedBusinessCards: (props: PageLayoutDestinationBarProps) => (
    <SpecializedCards {...props} side="left" />
  ),
  specializedRetirement: SpecializedRetirement,
  specializedSeedCards: SpecializedSeedCards,
  specializedSeedLogo: SpecializedSeedLogo,
}

export const PageLayoutDestinationBar = ({
  type,
  ...props
}: PageLayoutDestinationBarProps) => {
  const Comp = variants[type]

  return <Comp type={type} {...props} />
}

export const mapDataToProps = ({
  data,
}: MapDataToPropsArgs<PageLayoutDestinationBarFragment>) => ({
  type: camelCase(data.primary?.type!),
  bodyHTML: getRichText(data.primary?.body),
  buttonText: data.primary?.button_text,
  buttonHref: data.primary?.button_link?.url,
  buttonTarget: data.primary?.button_link?.target,
  imageFluid: data.primary?.image?.fluid as FluidObject,
  imageAlt: data.primary?.image?.alt,
})

export const mapDataToContext = () => ({
  bg: Symbol(),
})

export const query = graphql`
  fragment PageLayoutDestinationBar on PrismicPageLayoutDestinationBar {
    primary {
      type
      body {
        text
        html
      }
      button_text
      button_link {
        url
        target
      }
      image {
        alt
        fluid(maxWidth: 800) {
          ...GatsbyPrismicImageFluid
        }
      }
    }
  }
`

export default PageLayoutDestinationBar
