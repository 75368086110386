import * as React from 'react'
import { graphql } from 'gatsby'
import { useStyles } from 'react-treat'
import { Box } from '@walltowall/calico'
import { getRichText } from '@walltowall/helpers'
import clsx from 'clsx'

import { PageLayoutCallToActionFragment } from '../graphqlTypes'
import { MapDataToPropsArgs } from '../types'
import { PageTemplateEnhancerProps } from '../templates/page'

import { BoundedBox } from '../components/BoundedBox'
import { HTMLContent } from '../components/HTMLContent'
import { Heading } from '../components/Heading'
import { Text } from '../components/Text'
import { ButtonLink } from '../components/ButtonLink'
import { Divider } from '../components/Divider'

import * as styleRefs from './PageLayoutCallToAction.treat'

export type PageLayoutCallToActionProps = Partial<
  ReturnType<typeof mapDataToProps>
> &
  PageTemplateEnhancerProps

export const PageLayoutCallToAction = ({
  textHTML,
  children,
  nextSharesBg,
  id,
}: PageLayoutCallToActionProps) => {
  const childrenCount = React.Children.count(children)

  return (
    <BoundedBox
      id={id}
      component="section"
      nextSharesBg={nextSharesBg}
      innerMaxWidth="medium"
      styles={{
        backgroundColor: 'beige100',
        maxWidth: 'xlarge',
        marginRight: 'auto',
        marginLeft: 'auto',
        color: 'brown20',
      }}
    >
      {textHTML && (
        <HTMLContent
          html={textHTML}
          componentOverrides={{
            h3: () => (props) => (
              <Heading
                level={2}
                variant="sansC"
                {...props}
                styles={{ marginBottom: [4, 6] }}
              />
            ),
            p: () => (props) => <Text variant="sans-18-20" {...props} />,
          }}
          styles={{
            marginBottom: [6, 7],
            textAlign: ['left', 'center'],
          }}
        />
      )}
      <Box
        component="ul"
        styles={{
          display: 'flex',
          flexDirection: ['column', 'row'],
          justifyContent: ['start', 'center'],
          paddingTop: [0, childrenCount > 1 ? 6 : 0],
          paddingBottom: [0, childrenCount > 1 ? 6 : 0],
          marginBottom: [0, childrenCount > 1 ? -5 : 0],
        }}
      >
        {React.Children.map(
          children,
          (child, i) =>
            React.isValidElement(child) &&
            React.cloneElement(child, {
              index: i,
              totalCount: React.Children.count(children),
            }),
        )}
      </Box>
    </BoundedBox>
  )
}

const itemVariants = {
  first: {
    textAlign: ['left', 'right'],
    alignItems: ['start', 'end'],
  },
  default: {
    textAlign: ['left', 'center'],
    alignItems: ['start', 'center'],
  },
  last: {
    textAlign: 'left',
    alignItems: 'start',
  },
} as const

export type PageLayoutCallToActionItemProps = {
  index?: number
  totalCount?: number
  textHTML?: string
  buttonHref?: string
  buttonTarget?: string
  buttonText?: string
}

PageLayoutCallToAction.Item = ({
  index = 0,
  totalCount = 0,
  textHTML,
  buttonHref,
  buttonText = 'Learn more',
  buttonTarget,
}: PageLayoutCallToActionItemProps) => {
  const styles = useStyles(styleRefs)

  const variantName: keyof typeof itemVariants =
    index === 0 && index !== totalCount - 1
      ? 'first'
      : index === totalCount - 1 && index !== 0
      ? 'last'
      : 'default'
  const variant = itemVariants[variantName]

  return (
    <>
      {index > 0 && (
        <Divider
          color="beige80"
          styles={{
            width: ['auto', '1px'],
            height: ['1px', 'auto'],
            marginTop: [6, -6],
            marginBottom: [6, -6],
            marginRight: [0, 8],
            marginLeft: [0, 8],
            alignSelf: 'stretch',
          }}
        />
      )}
      <Box
        styles={{
          alignItems: variant.alignItems,
          display: 'flex',
          flexDirection: 'column',
          width: 'full',
          textAlign: variant.textAlign,
          maxWidth: '20rem',
        }}
      >
        {textHTML && (
          <HTMLContent
            html={textHTML}
            componentOverrides={{
              p: () => (props) => <Text variant="sans-18-20" {...props} />,
            }}
            className={clsx(totalCount > 1 && styles.ctaTextMaxWidth)}
            styles={{
              marginBottom: [5, 6],
            }}
          />
        )}
        {buttonHref && (
          <ButtonLink
            variant="red"
            href={buttonHref}
            linkProps={{ target: buttonTarget }}
          >
            {buttonText}
          </ButtonLink>
        )}
      </Box>
    </>
  )
}

export const mapDataToProps = ({
  data,
}: MapDataToPropsArgs<PageLayoutCallToActionFragment>) => ({
  textHTML: getRichText(data.primary?.text),
  children: data.items?.map((item) => (
    <PageLayoutCallToAction.Item
      key={item?.text?.text}
      textHTML={getRichText(item?.text)}
      buttonHref={item?.button_link?.url}
      buttonTarget={item?.button_link?.target}
      buttonText={item?.button_text}
    />
  )) as React.ReactNode,
})

export const mapDataToContext = () => ({
  bg: 'beige100',
})

export const query = graphql`
  fragment PageLayoutCallToAction on PrismicPageLayoutCallToAction {
    primary {
      text {
        text
        html
      }
    }
    items {
      text {
        text
        html
      }
      button_link {
        url
        target
      }
      button_text
    }
  }
`

export default PageLayoutCallToAction
