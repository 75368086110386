import * as React from 'react'
import { useStyles } from 'react-treat'
import { Box } from '@walltowall/calico'
import GatsbyImage from 'gatsby-image/withIEPolyfill'

import { PageLayoutDestinationBarProps } from '../PageLayoutDestinationBar'

import { BoundedBox } from '../../components/BoundedBox'
import { HTMLContent } from '../../components/HTMLContent'
import { ButtonLink } from '../../components/ButtonLink'

import * as styleRefs from './SpecializedSeedLogo.treat'

type SpecializedSeedLogoProps = PageLayoutDestinationBarProps

export const SpecializedSeedLogo = ({
  bodyHTML,
  buttonText,
  buttonHref,
  buttonTarget,
  imageFluid,
  imageAlt,
  nextSharesBg,
  id,
}: SpecializedSeedLogoProps) => {
  const styles = useStyles(styleRefs)

  return (
    <BoundedBox
      id={id}
      component="section"
      nextSharesBg={nextSharesBg}
      innerMaxWidth="medium"
      className={styles.backgroundGradient}
      styles={{
        backgroundColor: 'beige100',
        color: 'brown20',
        marginRight: 'auto',
        marginLeft: 'auto',
        maxWidth: 'xlarge',
        position: 'relative',
      }}
    >
      <Box
        styles={{
          display: 'flex',
          alignItems: ['stretch', 'center'],
          flexDirection: ['column', 'row'],
          minHeight: ['none', '22rem'],
          position: 'relative',
        }}
      >
        <Box
          styles={{
            width: ['full', '6/12'],
            marginRight: ['auto', 10, 16],
            marginLeft: ['auto', 0],
            marginBottom: [8, 0],
            maxWidth: ['19rem', 'none'],
          }}
        >
          <Box
            styles={{
              width: ['auto', '11/12'],
              marginLeft: 'auto',
              marginRight: 'auto',
            }}
          >
            <GatsbyImage fluid={imageFluid} alt={imageAlt} />
          </Box>
        </Box>
        <Box styles={{ width: ['auto', '6/12'] }}>
          <Box
            styles={{
              width: 'full',
              display: 'flex',
              flexDirection: 'column',
              alignItems: 'start',
            }}
          >
            {bodyHTML && (
              <HTMLContent
                html={bodyHTML}
                componentOverrides={{
                  h2: (Comp) => (props) => (
                    <Comp
                      level={2}
                      variant="sansC"
                      {...props}
                      styles={{
                        color: 'beige40',
                        marginTop: 0,
                        marginBottom: [6, 8],
                      }}
                    />
                  ),
                  h3: (Comp) => (props) => (
                    <Comp
                      level={3}
                      variant="sansCapsA"
                      {...props}
                      styles={{
                        marginTop: 0,
                        marginBottom: 4,
                      }}
                    />
                  ),
                  h4: (Comp) => (props) => (
                    <Comp
                      level={4}
                      {...props}
                      styles={{
                        color: 'fuschia30',
                        marginTop: 0,
                        marginBottom: 4,
                      }}
                    />
                  ),
                  p: (Comp) => (props) => (
                    <Comp
                      {...props}
                      styles={{
                        marginTop: 0,
                        marginBottom: [6, 8],
                      }}
                    />
                  ),
                }}
                styles={{ maxWidth: 'small' }}
              />
            )}
            {buttonHref && (
              <Box styles={{ marginTop: [6, 8] }}>
                <ButtonLink
                  variant="red"
                  href={buttonHref}
                  target={buttonTarget}
                >
                  {buttonText}
                </ButtonLink>
              </Box>
            )}
          </Box>
        </Box>
      </Box>
    </BoundedBox>
  )
}
